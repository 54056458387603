import authAxios from "../common/authAxios";
import { ApplicationApproveDtoRequest } from "../interfaces/Applications/request/ApplicationApproveDtoRequest";
import { ApplicationInfoDtoRequest } from "../interfaces/Applications/request/ApplicationInfoDtoRequest";
import { StudentApplicationDtoRequest } from "../interfaces/Applications/request/StudentApplicationDtoRequest";

import { ApplicationDtoResponse } from "../interfaces/Applications/response/ApplicationDtoResponse";
import { ApplicationInfoDtoResponse } from "../interfaces/Applications/response/ApplicationInfoDtoResponse";
import { ApplicationInfoTypesDtoResponse } from "../interfaces/Applications/response/ApplicationInfoTypesDtoResponse";
import { ApplicationStepInfoDtoResponse } from "../interfaces/Applications/response/ApplicationStepInfoDtoResponse";
import { ApplicationStepsDtoResponse } from "../interfaces/Applications/response/ApplicationStepsDtoResponse";
import { ApplicationReasonDto, ApplicationTypeDtoResponse } from "../interfaces/Applications/response/ApplicationTypeDtoResponse";
import { DictionaryCatalogDtoResponse } from "../interfaces/DictionaryCatalogDtoResponse";
import { PrivilegeDtoResponse } from "../interfaces/Student/PrivilegeDtoResponse";
import { StudentGroupsDtoResponse } from "../interfaces/Student/StudentGroupsDtoResponse";
import { CacheService } from "./CacheService";

export class ApplicationService {
    static getApplications() {
        return authAxios.get<ApplicationDtoResponse[]>(
            `/astanait-contingent-module/api/v1/applications`
        );
    }

    static getApplicationsByFilter(params: any, page: number) {
        for (const key of Object.keys(params)) {
            if (!params[key]) {
                delete params[key];
            }
        }

        const cacheKey = `get-all-applications:${JSON.stringify(params)}`;

        // Check if the cache exists
        const cachedData = CacheService.getCache(cacheKey);
        if (cachedData) {
            return Promise.resolve(cachedData as any);
        }


        return CacheService.getWithCache(
            cacheKey,
            () => authAxios.get<ApplicationDtoResponse[]>(`/astanait-contingent-module/api/v1/applications/all-by-filter?page=${page}`, { params }),
            60,
            params
        );
    }

    static getApplicationById(id?: number) {
        return authAxios.get<ApplicationDtoResponse>(
            `/astanait-contingent-module/api/v1/applications/${id}`
        );
    }

    static getAllSteps(applicationTypeId?: number) {
        return authAxios.get<ApplicationStepsDtoResponse[]>(
            `/astanait-contingent-module/api/v1/applications/all-steps/${applicationTypeId}`
        );
    }

    static getApplicationSteps(id?: number) {
        return authAxios.get<ApplicationStepInfoDtoResponse[]>(
            `/astanait-contingent-module/api/v1/applications/steps/${id}`
        );
    }

    static getApplicationInfo(id?: number) {
        return authAxios.get<ApplicationInfoDtoResponse[]>(
            `/astanait-contingent-module/api/v1/applications/info/${id}`
        );
    }

    static getApplicationTypes() {
        return authAxios.get<ApplicationTypeDtoResponse[]>(
            `/astanait-contingent-module/api/v1/applications/types`
        );
    }

    static getApplicationTypesUsed() {
        return authAxios.get<ApplicationTypeDtoResponse[]>(
            `/astanait-contingent-module/api/v1/applications/types/used`
        );
    }

    static getApplicationStatuses() {
        return authAxios.get<DictionaryCatalogDtoResponse[]>(
            `/astanait-contingent-module/api/v1/applications/statuses`
        );
    }

    static getApplicatinInfoTypes(applicationTypeId?: number) {
        return authAxios.get<ApplicationInfoTypesDtoResponse[]>(
            `/astanait-contingent-module/api/v1/applications/info-types/${applicationTypeId}`
        );
    }

    static getApplicationReasonsByType(applicationTypeId?: number) {
        return authAxios.get<ApplicationReasonDto[]>(
            `/astanait-contingent-module/api/v1/applications/reasons/${applicationTypeId}`,
            {
                headers: {
                    showNotification: false
                }
            }
        );
    }

    static approveApplication(approveDtoRequest: ApplicationApproveDtoRequest, id: number) {
        return authAxios.post(
            `/astanait-contingent-module/api/v1/applications/approve/${id}`,
            approveDtoRequest
        );
    }

    static createApplication(studentApplicationDtoRequest: StudentApplicationDtoRequest) {
        return authAxios.post(
            `/astanait-contingent-module/api/v1/applications/create`,
            studentApplicationDtoRequest
        );
    }

    static createOrder(applicationId: number, formData: FormData) {
        return authAxios.post(
            `/astanait-contingent-module/api/v1/order/application/${applicationId}/create`, formData
        );
    }

    static updateApplication(applicationInfoDtoRequestList: ApplicationInfoDtoRequest[], id: number, fileId: number) {
        return authAxios.post(
            `/astanait-contingent-module/api/v1/applications/update/${id}?fileId=${fileId}`,
            applicationInfoDtoRequestList
        );
    }

    static updateApplicationInfo(applicationInfoDtoRequestList: ApplicationInfoDtoRequest[], id: number) {
        return authAxios.post(
            `/astanait-contingent-module/api/v1/applications/info/update/${id}`,
            applicationInfoDtoRequestList
        );
    }

    static updateApplicationReason(id: number, reason: string) {
        return authAxios.put(
            `/astanait-contingent-module/api/v1/applications/update/reason/${id}?reason=${reason}`,
        );
    }

    static getStudentGroupsForApplication() {
        return authAxios.get<StudentGroupsDtoResponse[]>(`/astanait-student-module/api/v1/group/get-groups-for-application`);
    }
    static getApplicationCount() {
        return authAxios.get<number>(
            `/astanait-contingent-module/api/v1/applications/application-count-for-sign`,
            { headers: { showNotification: false } }
        );
    }
    static getPrivilege() {
        return authAxios.get<PrivilegeDtoResponse[]>(
            `/astanait-contingent-module/api/v1/dorm/privilege-types`,

        );

    }
    static getApplicationsForDorm(params: any, page: number) {
        for (const key of Object.keys(params)) {
            if (!params[key]) {
                delete params[key];
            }
        }
        const cacheKey = `dorm:${JSON.stringify(params)}`;

        // Check if the cache exists
        const cachedData = CacheService.getCache(cacheKey);
        if (cachedData) {
            return Promise.resolve(cachedData as any);
        }
        return CacheService.getWithCache(
            cacheKey,
            () => authAxios.get<ApplicationDtoResponse[]>(
                `/astanait-contingent-module/api/v1/applications/dorm?page=${page}`
                , { params }),
            60,
            params
        );

    }
    static getAllApplicationForOrder(params: any, page: number) {

        const cacheKey = `all-for-order:${JSON.stringify(params)}`;

        // Check if the cache exists
        const cachedData = CacheService.getCache(cacheKey);
        if (cachedData) {
            return Promise.resolve(cachedData as any);
        }
        return CacheService.getWithCache(
            cacheKey,
            () => authAxios.get<ApplicationDtoResponse[]>(
                `/astanait-contingent-module/api/v1/applications/all-for-order?page=${page}`
                , { params }),
            60,
            params
        );
    }
    static getApplicationForSign(params: any, page: number) {
        for (const key of Object.keys(params)) {
            if (!params[key]) {
                delete params[key];
            }
        }
        const cacheKey = `application-for-sign:${JSON.stringify(params)}`;

        // Check if the cache exists
        const cachedData = CacheService.getCache(cacheKey);
        if (cachedData) {
            return Promise.resolve(cachedData as any);
        }
        return CacheService.getWithCache(
            cacheKey,
            () => authAxios.get<ApplicationDtoResponse[]>(
                `/astanait-contingent-module/api/v1/applications/application-for-sign?page=${page}`
                , { params }),
            60,
            params
        );
    }
}