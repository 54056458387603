import React, { useEffect, useState } from 'react';
import { Button, Card, DatePicker, Form, Input, Typography } from 'antd';
import PageWrapper from '../../../ui/PageWrapper';
import { errorNotification } from '../../../helpers/errorNotification';
import { DormApplicationService } from './DormApplicationsService';
import { successNotification } from '../../../helpers/successNotification';
import moment from 'moment';

const { RangePicker } = DatePicker;



const DormApplicationsSettings = () => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [settingId, setSettingId] = useState<number | null>(null);

    useEffect(() => {
        const fetchSettings = async () => {
            try {
                setLoading(true);
                const settings = await DormApplicationService.getSelectionSetting();
                setSettingId(settings.id);
                form.setFieldsValue({
                    placesGirl: settings.placesGirl,
                    placeBoys: settings.placeBoys,
                    dateRange: [settings.startDate, settings.endDate].map(date => date ? moment(date) : null),
                });
            } catch (error) {
                errorNotification('Failed to load settings');
            } finally {
                setLoading(false);
            }
        };
        fetchSettings();
    }, [form]);

    const handleSave = async (values: any) => {
        if (!settingId) {
            errorNotification('ID is missing');
            return;
        }
        try {
            setLoading(true);
            const { dateRange, ...restValues } = values;
            const selectionSettingDto = {
                ...restValues,
                startDate: dateRange[0]?.format('YYYY-MM-DD'),
                endDate: dateRange[1]?.format('YYYY-MM-DD'),
            };
            await DormApplicationService.changeSelectionSetting(settingId, selectionSettingDto);
            successNotification('Settings updated successfully');
        } catch (error) {
            errorNotification('Failed to update settings');
        } finally {
            setLoading(false);
        }
    };


    return (
        <PageWrapper>
            <Typography.Title level={1}>Dormitory Applications Settings</Typography.Title>
            <Card
                style={{ display: 'flex', justifyContent: 'center', maxWidth: 500 }}>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleSave}
                // style={{  }}
                >
                    <Form.Item
                        label="Number of Places for Girls"
                        name="placesGirl"
                        rules={[{ required: true, message: 'Please input the number of places for girls' }]}
                    >
                        <Input type="number" />
                    </Form.Item>
                    <Form.Item
                        label="Number of Places for Boys"
                        name="placeBoys"
                        rules={[{ required: true, message: 'Please input the number of places for boys' }]}
                    >
                        <Input type="number" />
                    </Form.Item>
                    <Form.Item
                        label="Selection Period"
                        name="dateRange"
                        rules={[{ required: true, message: 'Please select the date range' }]}
                    >
                        <RangePicker />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            Save Settings
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </PageWrapper>
    );
};

export default DormApplicationsSettings;
