import authAxios from "../../../common/authAxios";
import { errorNotification } from "../../../helpers/errorNotification";
import { successNotification } from "../../../helpers/successNotification";
import { GraduationThesisSettingDetailDtoResponse } from "./GraduationThesisSettingDetailDtoResponse";
import { GraduationThesisSettingsDtoResponse } from "./GraduationThesisSettingsDtoResponse";

export class GraduationThesisSettingsService {
    private static baseUrl = '/astanait-diploma-work-module/api/v1/graduation-thesis-setting';

    static async getByPrincipalDepartment() {
        return authAxios.get(`${this.baseUrl}/office-principal-department`)
            .then((response) => response.data)
            .catch((error) => {
                errorNotification('Error fetching ', error.response?.status);
            });
    }

    static async createSetting(data: GraduationThesisSettingsDtoResponse, defenseYear: number) {
        return authAxios.post(`${this.baseUrl}?defenseYear=${defenseYear}`, data)
            .then(() => successNotification('Setting created successfully'))
            .catch((error) => {
                errorNotification('Error creating setting', error.response?.status);
            });
    }

    static async updateSetting(id: number, data: GraduationThesisSettingsDtoResponse, defenseYear: number) {
        return authAxios.put(`${this.baseUrl}/${id}?defenseYear=${defenseYear}`, data)
            .then(() => successNotification('Setting updated successfully'))
            .catch((error) => {
                errorNotification('Error updating setting', error.response?.status);
            });
    }

    static async getDetailsBySettingId(settingId: number) {
        return authAxios.get(`${this.baseUrl}/${settingId}/details`)
            .then((response) => response.data)
            .catch((error) => {
                errorNotification('Error fetching graduation thesis setting details by degree', error.response?.status);
            });
    }

    static async getAllTeachersByDepartmentId(departmentId: number) {
        return authAxios.get(`/astanait-teacher-module/api/v1/teacher/get-teachers-by-department?departmentId=${departmentId}`)
            .then((response) => response.data)
            .catch((error) => {
                errorNotification('Error fetching teachers by department', error.response?.status);
            });
    }

    static async createGraduationThesisSettingDetail(settingId: number,data: GraduationThesisSettingDetailDtoResponse) {
        return authAxios.post(`${this.baseUrl}/${settingId}/details/`, data)
            .then(() => successNotification('Graduation thesis setting detail created successfully'))
            .catch((error) => {
                errorNotification('Error creating graduation setting thesis detail setting', error.response?.status);
            });
    }


    static async updateGraduationThesisSettingDetail(id: number, data: GraduationThesisSettingDetailDtoResponse) {
        return authAxios.put(`${this.baseUrl}/details/${id}`, data)
            .then(() => successNotification('Graduation thesis setting detail updated successfully'))
            .catch((error) => {
                errorNotification('Error updating teacher setting suggestion setting', error.response?.status);
            });
    }

    static async deleteGraduationThesisSettingDetail(id: number) {
        return authAxios.delete(`${this.baseUrl}/details/${id}`)
            .then(() => successNotification('Graduation thesis setting detail deleted successfully'))
            .catch((error) => {
                errorNotification('Error deleting graduation thesis setting detail suggestion', error.response?.status);
            });
    }
    
    static async approveSetting(id: number, data: { approved: boolean; comment: string }) {
        return authAxios.put(`${this.baseUrl}/${id}/approve`, data)
            .then(() => successNotification(data.approved ? 'Setting approved successfully' : 'Setting rejected successfully'))
            .catch((error) => {
                errorNotification('Error approving/rejecting setting', error.response?.status);
            });
    }
    
}
