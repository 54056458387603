import React from 'react'
import Page from '../../../../ui/Page'
import useFetchData from '../../../../hooks/useFetchData'
import { SyllabusDraftDto } from '../../../../interfaces/Syllabus/SyllabusDraftDto'
import { SyllabusService } from '../../../../services/SyllabusService'
import { Button, Table } from 'antd'
import { useHistory } from 'react-router-dom'

const SyllabusDraftListPage = () => {
    const { data: draftDataList } = useFetchData<SyllabusDraftDto[]>(SyllabusService.getSyllabusDraftList, true);
    const history = useHistory();

    const columns = [
        {
            title: "№",
            dataIndex: "key",
            key: "key",
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Created Date",
            dataIndex: "createdDate",
            key: "createdDate",
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
        },
    ];


    return (
        <Page
            title='Syllabus Drafts'
        >
            <Table
                bordered
                dataSource={draftDataList?.map((item, index) => ({
                    ...item,
                    key: index + 1,
                    name: item.syllabus.discipline.titleRu + "/" + item.syllabus.discipline.titleKz + "/" + item.syllabus.discipline.titleEn,
                    createdDate: new Date(item.syllabus.createdAt).toLocaleDateString(),
                    action: <Button onClick={() => history.push(`/syllabus-draft/${item.id}`)}>Edit</Button>
                }))}
                columns={columns}
            />
        </Page>
    )
}

export default SyllabusDraftListPage