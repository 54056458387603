import { useState, ReactNode, useEffect } from 'react';
import { ReceptionAppointmentService } from '../../../services/ReceptionAppointmentService';
import { AvailableAppointmentDatesResponse } from '../../../interfaces/deansOffice/AvailableAppointmentDatesResponse';
import { successNotification } from '../../../helpers/successNotification';
import { ReceptionAppointmentDtoResponse } from '../../../interfaces/deansOffice/ReceptionAppointmentDtoResponse';
import { Button, Popconfirm, Space } from 'antd';
import { DeleteTwoTone } from '@ant-design/icons';
import { ReceptionPlacesDtoResponse } from '../../../interfaces/deansOffice/ReceptionPlacesDtoResponse';
const useReceptionAppointment = () => {


    const [selectedFiltersOption, setSelectedFiltersOption] = useState<number | undefined>();
    const [selectedPlace, setSelectedPlace] = useState<number | undefined>();
    const [selectedDate, setSelectedDate] = useState<Date | undefined>();
    const [places, setPlaces] = useState<any[]>([]);
    const [referrals, setReferrals] = useState<any[]>([]);
    const [availableDate, setAvailableDate] = useState<any[]>([]);
    const [availableDateTimes, setAvailableDateTimes] = useState<any[]>([]);
    const [selectedDateTimes, setSelectedDateTimes] = useState<any>();
    const [comment, setComment] = useState<string | undefined>();
    const [studentAppointmentList, setStudentAppointmentList] = useState<ReceptionAppointmentDtoResponse[]>([]);
    const [shouldRerender, setShouldRerender] = useState(false);

    useEffect(() => {

        ReceptionAppointmentService.getAppointmentsByStudent()
            .then((response) => {
                setStudentAppointmentList(response.data)
            })
        ReceptionAppointmentService.getPlaces().then((response) => {
            const formattedPlaces = response.data.map((place) => ({
                value: place.id, // ID для идентификации
                label: place.name, // Имя для отображения
            }));
            setPlaces(formattedPlaces); // Установить преобразованные данные

        });

    }, [selectedPlace, shouldRerender]);

    const saveData = () => {
        const date = new Date(selectedDateTimes).toISOString().replace('Z', '');
        ReceptionAppointmentService.createReceptionAppointment({
            referralId: selectedFiltersOption,
            appointmentDatetime: selectedDateTimes,
            studentQuestion: comment,

        })
            .then(() => {
                successNotification('вы успешно записались на прием');
                setShouldRerender(!shouldRerender)

            });
    };
    const onDeleteAppointment = (appointmentId: number) => {
        ReceptionAppointmentService.deleteAppointment(appointmentId)
            .then(() => setShouldRerender(!shouldRerender))
            .catch((error: any) => console.error(error));
    };
    const handleSelectPlace = (value: number) => {
        setSelectedDate(undefined);
        setSelectedDateTimes(undefined);
        setSelectedFiltersOption(undefined);
        setSelectedPlace(value);
        ReceptionAppointmentService.getReferral(value).then((response) => {
            const formattedReferrals = response.data.map((referral) => ({
                value: referral.type.id,
                label: referral.type.name,
            }));
            setReferrals(formattedReferrals);
        });
    };
    const handleSelectOption = (value: number) => {
        setSelectedDate(undefined);
        setSelectedDateTimes(undefined);
        setSelectedFiltersOption(value);
        ReceptionAppointmentService.getAvailableDates(value).then((response) => {
            const formattedDate = response.data.map((date) => ({
                value: date,
                label: date,
            }));
            setAvailableDate(formattedDate);
        });

    };

    const handleSelectDate = (value: Date) => {
        setSelectedDate(value);
        setSelectedDateTimes(undefined);
        ReceptionAppointmentService.getAvailableDateTimes(selectedFiltersOption, value)
            .then((response) => {
                const formattedDateTime = response.data.map((dateString) => ({
                    value: dateString,
                    label: `${new Date(dateString).getHours()}:${new Date(dateString).getMinutes() < 10 ? '0' : ''}${new Date(dateString).getMinutes()}`
                }));
                setAvailableDateTimes(formattedDateTime);

            })
    };

    const handleSelectDateTime = (value: any) => {
        setSelectedDateTimes(value);
    };

    const handleChangeComment = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setComment(e.target.value);
    };

    const columns: any[] = [
        {
            title: 'Appointment place',
            dataIndex: 'place',
            key: 'place',
            width: '25%',
        },
        {
            title: 'Appointment type',
            dataIndex: 'type',
            key: 'type',
            width: '25%',
        },
        {
            title: 'Time',
            dataIndex: 'time',
            key: 'time',
            width: '10%',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
        },

        {
            title: 'My Question',
            dataIndex: 'studentQuestion',
            key: 'studentQuestion',
        },
        {
            title: 'Action',
            dataIndex: 'actions',
            key: 'actions',
            width: 120,
            render: (text: any, record: any) => (
                <Popconfirm
                    placement="topLeft"
                    title={'Delete an appointment?'}
                    onConfirm={() => onDeleteAppointment(record.key)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Space size="middle">
                        <Button >
                            <DeleteTwoTone twoToneColor="#ff0000" />
                        </Button>
                    </Space>
                </Popconfirm>
            ),
        },
    ];
    const dataSource = studentAppointmentList.map(data => {

        // Создаем объект Date из строки времени
        const date = new Date(data.appointmentDatetime);

        // Получаем часы и минуты
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        // Форматируем время в нужный формат 
        const formattedTime = `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}`;

        return {
            key: data.id,
            place: data.referral.place.name,
            type: data.referral.type.name,
            time: formattedTime,
            date: year + '-' + month + '-' + day,
            student: data.student.nameEn + " " + data.student.surnameEn,
            group: data.student.group.title,
            studentQuestion: data.studentQuestion
        };
    });
    return {

        handleSelect: {
            handleSelectOption,
            handleSelectDate,
            handleSelectPlace,
            handleSelectDateTime,
            handleChangeComment
        },
        selectedFiltersOption,
        places,
        referrals,
        selectedDate,
        selectedPlace,
        availableDateTimes,
        selectedDateTimes,
        saveData,
        onDeleteAppointment,
        availableDate,
        comment,
        studentAppointmentList,
        columns,
        dataSource
    };
};

export default useReceptionAppointment;


