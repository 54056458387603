
import { Typography, Table, Button, Pagination, Modal, Form, Select, Input, Space, Popconfirm } from 'antd';
import PageWrapper from '../../../../ui/PageWrapper';
import { Link } from 'react-router-dom';
import { DeleteTwoTone, EditOutlined, EditTwoTone, PlusOutlined } from '@ant-design/icons';
import { AcademicMobilityCompetitionService } from '../../../../services/AcademicMobilityCompetitionService';
import { useEffect, useState } from 'react';
import { ApprovalSheetDto, ForeignDisciplinesDtoRequest, LearningAgreementDisciplinesDto } from '../../../../interfaces/AcademicMobilityCompetitionDto';
import useDictionary from '../../../../hooks/useDictionary';
import { successNotification } from '../../../../helpers/successNotification';
import AppromentTable from '../AppromentTable';
const { Title } = Typography;

interface Props {
    id: number
}
const AituDisciplinePage = ({ id }: Props) => {
    const [learningAgreement, setLearningAgreement] = useState<LearningAgreementDisciplinesDto>();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpenForeign, setIsModalOpenForeign] = useState(false);
    const [isModalOpenRecredit, setIsModalOpenRecredit] = useState(false);
    const [selectedTerm, setSelectedTerm] = useState<number | undefined>(undefined);
    const [selectedYear, setSelectedYear] = useState<number | undefined>(undefined);
    const [selectedDiscipline, setSelectedDiscipline] = useState<number | undefined>(undefined);
    const [disciplinesList, setDisciplinesList] = useState<any[]>([]);
    const { dictionary } = useDictionary({});
    const [aituDisciplineForm] = Form.useForm();
    const [foreignDisciplineForm] = Form.useForm();
    const [recreditForm] = Form.useForm();
    const [shouldRerender, setShouldRerender] = useState(false);
    const [editingDiscipline, setEditingDiscipline] = useState<any>(null);
    const [editingAituDiscipline, setEditingAituDiscipline] = useState<any>(null);
    const [editingRecredit, setEditingRecredit] = useState<any>(null);
    const [approvalSheet, setApprovalSheet] = useState<ApprovalSheetDto[]>([]);

    const handleEditAituDiscipline = (record: any) => {
        setEditingAituDiscipline(record);
        setSelectedDiscipline(record.curriculumId); // Устанавливаем ID дисциплины
        aituDisciplineForm.setFieldsValue({
            year: record.academicYear,
            term: record.term,
            discipline: { value: record.curriculumId, label: record.discipline }, // Название и ID
            format: record.form,
        });
        setIsModalOpenForeign(true);
    };

    const handleUpdateAituDiscipline = (data: { discipline: number; format: string }) => {
        AcademicMobilityCompetitionService.updateAituDiscipline(editingAituDiscipline.key, {
            curriculumId: selectedDiscipline,
            format: data.format,
        })
            .then(() => {
                successNotification("AITU Discipline successfully updated!");
                loadLearningAgreement();
                setIsModalOpenForeign(false);
                setEditingAituDiscipline(null);
                setSelectedDiscipline(undefined);
                setSelectedYear(undefined);
            })
            .catch((error) => console.error(error));
    };
    const handleEditRecredit = (record: any) => {
        setEditingRecredit(record);
        recreditForm.setFieldsValue({
            foreignDisciplineId: record.foreignDisciplineId,
            aituDisciplineId: record.aituDisciplineId,

        });
        setIsModalOpenRecredit(true);
    }

    const handleUpdateRecredit = (data: { foreignDisciplineId: number; aituDisciplineId: number }) => {
        AcademicMobilityCompetitionService.updateRecredit(editingRecredit.key, data)
            .then(() => {
                successNotification("AITU Discipline successfully updated!");
                loadLearningAgreement();
                setIsModalOpenRecredit(false);
                setEditingRecredit(null);

            })
            .catch((error) => console.error(error));
    };



    const handleEditDiscipline = (record: any) => {
        setEditingDiscipline(record);
        setIsModalOpen(true);
        foreignDisciplineForm.setFieldsValue({
            name: record.name,
            nameEn: record.nameEn,
            nameRu: record.nameRu,
            term: record.term,
            code: record.code,
            format: record.form,
            ectsCredits: record.ects,
            originalCredits: record.originalCredits


        });
    };

    const handleUpdateDiscipline = (data: ForeignDisciplinesDtoRequest) => {
        const processedData = {
            ...data,
            ectsCredits: Number(data.ectsCredits),
            originalCredits: Number(data.originalCredits),
        };
        AcademicMobilityCompetitionService.updateForeignDiscipline(editingDiscipline.key, processedData)
            .then(() => {
                successNotification("Discipline successfully updated!");
                loadLearningAgreement();
                setIsModalOpen(false);
                setEditingDiscipline(null);
            })
            .catch((error) => console.error(error));
    };

    const loadLearningAgreement = () => {
        AcademicMobilityCompetitionService.getLearningAgreement(id)
            .then((response) => {
                setLearningAgreement(response.data);
            })
            .catch((error) => console.error(error))
    };
    const getApprovalSheet = () => {
        AcademicMobilityCompetitionService.getLearningAgreementApprovalSheet(id)
            .then((response) => {
                setApprovalSheet(response.data);
            })
            .catch((error) => console.error(error))
    };

    useEffect(() => {
        loadLearningAgreement();
        getApprovalSheet();
    }, [shouldRerender, id]);

    const onYearSelect = async (year: number) => {
        setSelectedYear(year);
        setSelectedTerm(undefined);
        setSelectedDiscipline(undefined);
        aituDisciplineForm.resetFields(['term', 'discipline']);
    };

    const onTermSelect = async (term: number) => {
        setSelectedTerm(term);
        setSelectedDiscipline(undefined);
        aituDisciplineForm.resetFields(['discipline']);
        if (selectedYear) {
            AcademicMobilityCompetitionService.getAituDisciplines(selectedYear, term).then(({ data }) => {
                let res = data.map((item) => {
                    return {
                        id: item.id,
                        name: item.discipline.titleEn,
                    };
                });
                setDisciplinesList(res);
            });
        }
    };
    const onDeleteDiscipline = (disciplineId: number, source: string) => {
        const deleteMethod =
            source === "foreign"
                ? AcademicMobilityCompetitionService.deleteForeignDiscipline
                : source === "aitu" ? AcademicMobilityCompetitionService.deleteAituDiscipline
                    : AcademicMobilityCompetitionService.deleteRecreditDiscipline

        deleteMethod(disciplineId)
            .then(() => setShouldRerender(!shouldRerender))
            .catch((error) => console.error(error));
    };

    const column = [
        {
            title: 'Code',
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: 'Discipline',
            dataIndex: 'discipline',
            key: 'discipline',

        },
        {
            title: 'Term',
            dataIndex: 'term',
            key: 'term',
        },
        {
            title: 'Form',
            dataIndex: 'form',
            key: 'form',
        },
        {
            title: 'ECTS',
            dataIndex: 'ects',
            key: 'ects'
        },
        {
            title: 'Действия',
            dataIndex: 'actions',
            key: 'actions',
            width: 120,
            render: (text: any, record: any) => (
                <Space size="middle">
                    <Popconfirm
                        placement="topLeft"
                        title={'Delete a discipline?'}
                        onConfirm={() => onDeleteDiscipline(record.key, record.source)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button>
                            <DeleteTwoTone twoToneColor="#ff0000" />
                        </Button>
                    </Popconfirm>

                    <Button onClick={() => record.source == 'foreign' ? handleEditDiscipline(record) : handleEditAituDiscipline(record)}>
                        <EditTwoTone />
                    </Button>
                </Space>
            ),
        },

    ]
    const columnForeign = [
        {
            title: 'Code',
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',

        },
        {
            title: 'Name EN',
            dataIndex: 'nameEn',
            key: 'nameEn',

        },
        {
            title: 'Name RU',
            dataIndex: 'nameRu',
            key: 'nameRu',

        },
        {
            title: 'Term',
            dataIndex: 'term',
            key: 'term',
        },
        {
            title: 'Form',
            dataIndex: 'form',
            key: 'form',
        },
        {
            title: 'ECTS credit',
            dataIndex: 'ects',
            key: 'ects'
        },
        {
            title: 'Original Credit',
            dataIndex: 'originalCredits',
            key: 'originalCredits'
        },
        {
            title: 'Действия',
            dataIndex: 'actions',
            key: 'actions',
            width: 120,
            render: (text: any, record: any) => (
                <Space size="middle">
                    <Popconfirm
                        placement="topLeft"
                        title={'Delete a discipline?'}
                        onConfirm={() => onDeleteDiscipline(record.key, record.source)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button>
                            <DeleteTwoTone twoToneColor="#ff0000" />
                        </Button>
                    </Popconfirm>

                    <Button onClick={() => record.source == 'foreign' ? handleEditDiscipline(record) : handleEditAituDiscipline(record)}>
                        <EditTwoTone />
                    </Button>
                </Space>
            ),
        },

    ]
    const columnRecredit = [
        {
            title: 'Foreign Discipline',
            dataIndex: 'foreignDiscipline',
            key: 'foreignDiscipline',
        },
        {
            title: 'ECTS',
            dataIndex: 'ects',
            key: 'ects',

        },
        {
            title: 'AITU Discipline',
            dataIndex: 'aituDiscipline',
            key: 'aituDiscipline',
        },
        {
            title: 'ECTS AITU',
            dataIndex: 'ectsAitu',
            key: 'ectsAitu',
        },
        {
            title: 'Действия',
            dataIndex: 'actions',
            key: 'actions',
            width: 120,
            render: (text: any, record: any) => (
                <Space size="middle">
                    <Popconfirm
                        placement="topLeft"
                        title={'Delete a discipline?'}
                        onConfirm={() => onDeleteDiscipline(record.key, record.source)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button>
                            <DeleteTwoTone twoToneColor="#ff0000" />
                        </Button>
                    </Popconfirm>

                    <Button onClick={() => handleEditRecredit(record)}>
                        <EditTwoTone />
                    </Button>
                </Space>
            ),
        },

    ]

    const dataSource = learningAgreement?.foreignDisciplines.map((foreignDiscipline) => {
        return {
            key: foreignDiscipline.id,
            code: foreignDiscipline.code,
            term: foreignDiscipline.term,
            form: foreignDiscipline.format,
            ects: foreignDiscipline.ectsCredits,
            name: foreignDiscipline.name,
            nameEn: foreignDiscipline.nameEn,
            nameRu: foreignDiscipline.nameRu,
            originalCredits: foreignDiscipline.originalCredits,
            source: "foreign"
        };
    });

    const dataSourceRecredit = learningAgreement?.recreditPlanList.map((recreditPlan) => {
        return {
            key: recreditPlan.id,
            foreignDiscipline: recreditPlan.foreignDiscipline.name,
            foreignDisciplineId: recreditPlan.foreignDiscipline.id,
            ects: recreditPlan.foreignDiscipline.ectsCredits,
            aituDiscipline: recreditPlan.aituDiscipline.curriculum.discipline.titleEn,
            aituDisciplineId: recreditPlan.aituDiscipline.id,
            ectsAitu: recreditPlan.aituDiscipline.curriculum.discipline.volumeCredits,
            source: "recredit"
        };
    });
    const course = learningAgreement?.student.courseGrade;

    const dataSourceAitu = learningAgreement?.aituDisciplines?.map((aituDisciplines) => {
        return {
            key: aituDisciplines.id,
            code: aituDisciplines.curriculum.discipline.code,
            term: aituDisciplines.curriculum.numberOfTrimester,
            form: aituDisciplines.format,
            ects: aituDisciplines.curriculum.discipline.volumeCredits,
            discipline: aituDisciplines.curriculum.discipline.titleEn,
            source: "aitu",
            academicYear: Number(course) - aituDisciplines.curriculum.year + new Date().getFullYear(),
            curriculumId: aituDisciplines.curriculum.id

        };

    });

    const handleAituDiscipline = (data: { discipline: number; format: string }) => {
        AcademicMobilityCompetitionService.createAituDiscipline(id, {
            curriculumId: data.discipline,
            format: data.format,
        })
            .then(() => {
                successNotification("Aitu Discipline successfully added!");
                loadLearningAgreement();
                setIsModalOpenForeign(false);
            })
            .catch((error) => console.error(error));
    };

    const handleForeignDiscipline = (data: ForeignDisciplinesDtoRequest) => {
        const processedData = {
            ...data,
            ectsCredits: Number(data.ectsCredits),
            originalCredits: Number(data.originalCredits),
        };
        AcademicMobilityCompetitionService.createForeignDiscipline(id, processedData)
            .then(() => {
                successNotification("Foreign Discipline successfully added!");
                loadLearningAgreement();
                setIsModalOpen(false);
            })
            .catch((error) => console.error(error));
    };

    const handleRecreditPlanSubmit = (data: { foreignDisciplineId: number; aituDisciplineId: number }) => {
        AcademicMobilityCompetitionService.createRecredit(id, data)
            .then(() => {
                successNotification("Recredit Plan successfully added!");
                loadLearningAgreement();
            })
            .catch((error) => console.error(error));

        setIsModalOpenRecredit(false);
    };

    return (
        <PageWrapper>
            <section>
                <header >

                    <Title level={4} >
                        Foreign Disciplines
                    </Title>
                    <>
                        <Button onClick={() => setIsModalOpen(true)} type='primary' icon={<EditOutlined />} style={{ marginRight: 20, marginBottom: 20 }}>Add Foreign Disciplines</Button>
                        <Modal
                            open={isModalOpen}
                            title={editingDiscipline ? "Edit Discipline" : "Add Discipline"}
                            onCancel={() => {
                                foreignDisciplineForm.resetFields(); // Сбрасываем поля формы
                                setIsModalOpen(false); // Закрываем модальное окно
                                setEditingDiscipline(null); // Очищаем редактируемую дисциплину
                            }}
                            onOk={() => foreignDisciplineForm.submit()}
                        >
                            <Form
                                form={foreignDisciplineForm}
                                onFinish={editingDiscipline ? handleUpdateDiscipline : handleForeignDiscipline}
                                initialValues={editingDiscipline || {}}
                            >
                                <Form.Item
                                    label="Name"
                                    name="name"
                                    rules={[{ required: true, message: 'Please input name!' }]}
                                >
                                    <Input placeholder="Write name" />
                                </Form.Item>
                                <Form.Item
                                    label="Name En"
                                    name="nameEn"
                                    rules={[{ required: true, message: 'Please input nameEn!' }]}
                                >
                                    <Input placeholder="Write nameEn" />
                                </Form.Item>
                                <Form.Item
                                    label="Name Ru"
                                    name="nameRu"
                                    rules={[{ required: true, message: 'Please input nameRu!' }]}
                                >
                                    <Input placeholder="Write nameRu" />
                                </Form.Item>
                                <Form.Item
                                    label="Code"
                                    name="code"
                                    rules={[{ required: true, message: 'Please input code!' }]}
                                >
                                    <Input placeholder="Write code" />
                                </Form.Item>
                                <Form.Item
                                    label="Term"
                                    name="term"
                                    rules={[{ required: true, message: 'Please input term!' }]}
                                >
                                    <Input placeholder="Write term" />
                                </Form.Item>
                                <Form.Item
                                    label="ECTS Credits"
                                    name="ectsCredits"
                                    rules={[{ required: true, message: 'Please input ects Credits!' }]}
                                >
                                    <Input placeholder="Write ects Credits" />
                                </Form.Item>
                                <Form.Item
                                    label="Original Credits"
                                    name="originalCredits"
                                    rules={[{ required: true, message: 'Please input original Credits!' }]}
                                >
                                    <Input placeholder="Write original Credits" />
                                </Form.Item>
                                <Form.Item
                                    label="Format"
                                    name="format"
                                    rules={[{ required: true, message: 'Please input format!' }]}
                                >
                                    <Input placeholder="Write input" />
                                </Form.Item>
                            </Form>
                        </Modal></>
                </header>
                <Table
                    dataSource={dataSource}
                    columns={columnForeign}
                />
                <Title level={4} >
                    AITU Disciplines
                </Title>
                <>
                    <Button onClick={() => setIsModalOpenForeign(true)} type='primary' icon={<EditOutlined />} style={{ marginRight: 20, marginBottom: 20 }}>Add Aitu Disciplines</Button>
                    <Modal
                        open={isModalOpenForeign}
                        title={editingAituDiscipline ? "Edit AITU Discipline" : "Add AITU Discipline"}
                        onCancel={() => {
                            aituDisciplineForm.resetFields(); // Сбрасываем поля формы
                            setIsModalOpenForeign(false); // Закрываем модальное окно
                            setEditingAituDiscipline(null); // Очищаем редактируемую дисциплину
                            setSelectedDiscipline(undefined); // Сбрасываем выбранную дисциплину
                        }}
                        onOk={() => aituDisciplineForm.submit()}
                    >
                        <Form
                            form={aituDisciplineForm}
                            onFinish={editingAituDiscipline ? handleUpdateAituDiscipline : handleAituDiscipline}
                            initialValues={editingAituDiscipline || {}}
                        >
                            <Form.Item
                                label="Academic year"
                                name="year"
                                rules={[{ required: true, message: 'Please choose year!' }]}>
                                <Select
                                    placeholder="Choose year"
                                    value={selectedYear}
                                    onChange={(value) => onYearSelect(value)}
                                >
                                    {dictionary.academicYearArray.map((degree) => (
                                        <Select.Option key={degree.value} value={degree.value}>
                                            {degree.academicYear}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Term"
                                name="term"
                                rules={[{ required: true, message: 'Please choose term!' }]}>
                                <Select
                                    placeholder="Choose term"
                                    value={selectedTerm}
                                    onChange={(value) => onTermSelect(value)}
                                    disabled={!selectedYear}
                                >
                                    <Select.Option key={1} value={1}>1</Select.Option>
                                    <Select.Option key={2} value={2}>2</Select.Option>
                                    <Select.Option key={3} value={3}>3</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Discipline"
                                name="discipline"
                                rules={[{ required: true, message: 'Please choose discipline!' }]}
                            >
                                <Select
                                    placeholder="Choose discipline"
                                    value={selectedDiscipline}
                                    onChange={(value) => setSelectedDiscipline(value)}
                                    style={{ width: 400 }}
                                    disabled={!selectedTerm}
                                >
                                    {disciplinesList.map((option) => (
                                        <Select.Option key={option.id} value={option.id}>
                                            {`${option.name}`}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Format"
                                name="format"
                                rules={[{ required: true, message: 'Please input format!' }]}
                            >
                                <Input placeholder="Write format" />
                            </Form.Item>
                        </Form>
                    </Modal>
                </>
                <Table
                    dataSource={dataSourceAitu}
                    columns={column}
                />
                <Title level={4} >
                    Recredit plan
                </Title>
                <>
                    <Button onClick={() => setIsModalOpenRecredit(true)} type='primary' icon={<EditOutlined />} style={{ marginRight: 20, marginBottom: 20 }}>
                        Add Recredit Plan
                    </Button>
                    <Modal
                        open={isModalOpenRecredit}
                        title={editingRecredit ? "Edit Recredit Plan" : "Add Recredit Plan"}
                        onCancel={() => {
                            recreditForm.resetFields(); // Сбрасываем поля формы
                            setIsModalOpenRecredit(false); // Закрываем модальное окно
                            setEditingRecredit(null); // Очищаем редактируемую запись
                        }}
                        onOk={() => recreditForm.submit()}
                    >
                        <Form
                            form={recreditForm}
                            onFinish={editingRecredit ? handleUpdateRecredit : handleRecreditPlanSubmit}
                            initialValues={editingRecredit || {}}
                        >
                            <Form.Item
                                label="Foreign Discipline"
                                name="foreignDisciplineId"
                                rules={[{ required: true, message: 'Please choose Foreign Discipline!' }]}
                            >
                                <Select
                                    placeholder="Choose Foreign Discipline"
                                    style={{ width: 300 }}
                                >
                                    {dataSource?.map((option) => (
                                        <Select.Option key={option.key} value={option.key}>
                                            {option.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="AITU Discipline"
                                name="aituDisciplineId"
                                rules={[{ required: true, message: 'Please choose AITU Discipline!' }]}
                            >
                                <Select
                                    placeholder="Choose AITU Discipline"
                                    style={{ width: 300 }}
                                >
                                    {dataSourceAitu?.map((option) => (
                                        <Select.Option key={option.key} value={option.key}>
                                            {option.discipline}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Form>
                    </Modal>
                </>
                <Table
                    dataSource={dataSourceRecredit}
                    columns={columnRecredit}
                />
                <Title level={4} >
                    Approval sheet
                </Title>
                <AppromentTable dataSource={approvalSheet} />
            </section>
        </PageWrapper>
    )
}

export default AituDisciplinePage;