
import { Typography, Table, Button, Modal, Form, Input } from 'antd';
import PageWrapper from '../../../../ui/PageWrapper';
import { useEffect } from 'react';
import useCollationPage from './useCollationPage';
import AppromentTable from '../AppromentTable';
const { Title } = Typography;

interface Props {
    id: number
}
const CollationPage = ({ id }: Props) => {


    const { collationStatement, column, dataSource, form, shouldRerender, downloadStatement, getCollationStatementGrade, isModalOpen,
        setIsModalOpen, updateOriginalGrade, getCollationApprovalSheet, collationApprovalSheet } = useCollationPage({ id: id });

    useEffect(() => {
        getCollationStatementGrade(id);
        getCollationApprovalSheet(id);
    }, [shouldRerender]);


    return (
        <PageWrapper>
            <section>
                <header >
                    <Title level={4} >
                        Collation statement
                    </Title>
                    <Button onClick={() => downloadStatement(collationStatement.originalTranscript)} type='primary' style={{ marginRight: 20, marginBottom: 20 }} >Download original transcript</Button>
                    <Button onClick={() => downloadStatement(collationStatement.engTranscript)} type='primary' style={{ marginRight: 20, marginBottom: 20 }} >Download english transcript</Button>
                </header>
                <Table
                    dataSource={dataSource}
                    columns={column}
                />
                <Title level={4} >
                    Approval sheet
                </Title>
                <AppromentTable dataSource={collationApprovalSheet} />
                <Modal
                    open={isModalOpen}
                    title={"Edit Original Grade"}
                    onCancel={() => {
                        form.resetFields();
                        setIsModalOpen(false);
                    }}
                    onOk={() => form.submit()}
                >
                    <Form
                        form={form}
                        onFinish={updateOriginalGrade}
                    >
                        <Form.Item
                            label="Original Grade"
                            name="originalGrade"

                            rules={[{ required: true, message: 'Please input Original Grade!' }]}
                        >
                            <Input placeholder="Write Original Grade" />
                        </Form.Item>
                    </Form>
                </Modal>
            </section>
        </PageWrapper>
    )
}

export default CollationPage;