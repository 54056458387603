import React, { useEffect, useState } from 'react'
import Page from '../../../../ui/Page'
import { Button, Descriptions, Form, Input, Modal, Select, Space, Table } from 'antd'
import { DormApplicationService } from '../DormApplicationsService';
import { errorNotification } from '../../../../helpers/errorNotification';
import useModal from '../../../../ui/Modal/useModal';
import { DormApplicationDto } from '../../../../interfaces/DormApplicationDto';
import { successNotification } from '../../../../helpers/successNotification';
import { DownloadOutlined } from '@ant-design/icons';

const DormContracts = () => {
    const [applications, setApplications] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 20,
        total: 0,
    });
    const [selectedApplication, setSelectedApplication] = useState<DormApplicationDto>();
    const [selectedStatus, setSelectedStatus] = useState<boolean>();
    const { showModal, isModalOpen, handleCancel } = useModal();
    const { showModal: showApproveModal, isModalOpen: isApproveModalOpen, handleCancel: handleApproveCancel } = useModal();
    const [form] = Form.useForm();

    useEffect(() => {
        fetchApplications(pagination.current - 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination.current]);

    const fetchApplications = async (page: number) => {
        try {
            setLoading(true);
            const data = await DormApplicationService.getContracts({ page });
            setApplications(data.content);
            setPagination({
                ...pagination,
                total: data.totalElements,
            });
        } catch (error) {
            errorNotification(String(error));
        } finally {
            setLoading(false);
        }
    };

    const handleViewRoom = (record: any) => {
        setSelectedApplication(record);
        showModal();
    }

    const handleApproveShow = (record: any) => {
        setSelectedApplication(record);
        form.setFieldsValue({ status: record.approved, comment: record.comment });
        showApproveModal();
    }

    const changeStatus = async (id: number, approved: boolean, comment: string) => {
        DormApplicationService.changeContractStatus(id, { approved, comment })
            .then(() => {
                successNotification('Status changed successfully');
                fetchApplications(pagination.current - 1);
                handleApproveCancel();
            });
    }

    const handleCreateContract = async (userId: number) => {
        DormApplicationService.createContract(userId)
            .then(() => {
                successNotification('Contract created successfully');
                fetchApplications(pagination.current - 1);
            });
    }

    const handleDownloadPaymentCheck = async (id: number) => {
        DormApplicationService.downloadPaymentCheck(id)
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'payment_check.pdf');
                document.body.appendChild(link);
                link.click();

                successNotification('Payment check downloaded successfully');
                fetchApplications(pagination.current - 1);
            });
    }

    const columns = [
        {
            title: 'Full Name',
            dataIndex: 'fullName',
            key: 'fullName',
            render: (text: string, record: any) => record.application.user.surnameInLatin + " " + record.application.user.nameInLatin,
            width: '15%',
        },
        {
            title: 'Email',
            dataIndex: ['application', 'user', 'email'],
            key: 'email',
            width: '15%',
        },
        {
            title: 'Phone Number',
            dataIndex: ['application', 'user', 'phoneNumber'],
            key: 'phoneNumber',
            width: '10%',
        },
        {
            title: 'Privelege',
            dataIndex: ['application', 'privilege', 'id'],
            key: 'privelege',
            width: '5%',
        },
        {
            title: 'Selected Room',
            dataIndex: 'room',
            key: 'room',
            width: '5%',
            render: (text: string, record: any) => {
                return (
                    <Button onClick={() => handleViewRoom(record)}>{'View room information'}</Button>
                );
            }
        },
        {
            title: 'Dormitory payment check',
            dataIndex: 'paymentCheck',
            key: 'paymentCheck',
            width: '5%',
            render: (text: string, record: any) => {
                return (
                    <Button type='primary' icon={<DownloadOutlined />} onClick={() => handleDownloadPaymentCheck(record.paymentCheckId)} disabled={record.paymentCheckId === null}>
                        Download
                    </Button>
                )
            }
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text: string, record: any) => {
                return !record.podpishiDocHash && !record.podpishiDocNum ? (
                    <Space>
                        <Button
                            type='primary'
                            onClick={() => handleApproveShow(record)}
                        >
                            Approve/Decline
                        </Button>

                        <Button
                            type='primary'
                            disabled={record.approved !== true}
                            onClick={() => handleCreateContract(record.application.user.id)}
                        >
                            Create contract
                        </Button>

                        <Button
                            type='primary'
                            icon={<DownloadOutlined />}
                            onClick={() => { }}
                        >
                            Download template
                        </Button>
                    </Space>
                ) : (
                    <Space>
                        <Button type='primary' onClick={() => window.open(`https://api.podpishi.kz/qr.php?id1=${record.podpishiDocNum}&id2=${record.podpishiDocHash}`, '_blank')}>
                            See contract details
                        </Button>
                    </Space>
                );
            },
        }
    ];

    const handleDormGenderChange = (value: number) => {
        switch (value) {
            case 1:
                return "Женский";
            case 2:
                return "Мужской";
            case 3:
                return "Смешанный";
        }
    }

    return (
        <Page title='Dormitory contracts'>
            <Table
                dataSource={applications}
                columns={columns}
                loading={loading}
                pagination={{
                    current: pagination.current,
                    pageSize: pagination.pageSize,
                    total: pagination.total,
                    showSizeChanger: false,
                }}
                rowKey={"id"}
                scroll={{ x: 1300 }}
            />
            <Modal
                title="Approve/Decline"
                open={isApproveModalOpen}
                onCancel={handleApproveCancel}
                destroyOnClose
                onOk={form.submit}
                okText="Submit"
            >
                <Form layout='vertical' form={form} onFinish={(e) => { selectedApplication && changeStatus(selectedApplication.id, e.status, e.comment) }}>
                    <Form.Item label="Status" name="status" rules={[{ required: true, message: 'Please select status' }]}>
                        <Select placeholder="Select action" onChange={(value) => setSelectedStatus(value)}>
                            <Select.Option value={true}>Approve</Select.Option>
                            <Select.Option value={false}>Decline</Select.Option>
                        </Select>
                    </Form.Item>
                    {selectedStatus === false && <Form.Item label="Comment" name="comment" rules={[{ required: selectedStatus === false ? true : false, message: 'Please enter comment' }]}>
                        <Input.TextArea rows={4} />
                    </Form.Item>}
                </Form>
            </Modal>

            <Modal
                title="Room information"
                open={isModalOpen}
                onCancel={handleCancel}
                destroyOnClose
                footer={null}
            >
                {selectedApplication && (
                    <Space direction='vertical'>
                        <Descriptions title="Dormitory information">
                            <Descriptions.Item label="Dormitory name">{selectedApplication.dormitory.name}</Descriptions.Item>
                            <Descriptions.Item label="Address">{selectedApplication.dormitory.address}</Descriptions.Item>
                            <Descriptions.Item label="Phone">{selectedApplication.dormitory.phone}</Descriptions.Item>
                            <Descriptions.Item label="Floor count">{selectedApplication.dormitory.floorCount}</Descriptions.Item>
                            <Descriptions.Item label="Room count">{selectedApplication.dormitory.roomCount}</Descriptions.Item>
                            <Descriptions.Item label="Gender">{handleDormGenderChange(selectedApplication.dormitory.genderType)}</Descriptions.Item>
                        </Descriptions>
                        <Descriptions title="Room information">
                            <Descriptions.Item label="Room number">{selectedApplication.dormitoryRoom.number}</Descriptions.Item>
                            <Descriptions.Item label="Flat number">{selectedApplication.dormitoryRoom.flat_number}</Descriptions.Item>
                            <Descriptions.Item label="Floor">{selectedApplication.dormitoryRoom.floor}</Descriptions.Item>
                            <Descriptions.Item label="Beds">{selectedApplication.dormitoryRoom.beds}</Descriptions.Item>
                        </Descriptions>
                    </Space>
                )}
            </Modal>
        </Page>
    )
}

export default DormContracts