import React from 'react'
import Page from '../../../ui/Page'
import UniversalTable from '../TeachersPage/UniversalTeachersTable'
import { Button, DatePicker, Form, Input, Select, Space, Upload, UploadProps } from 'antd'
import { TeacherOrderDtoRequest } from '../../../interfaces/Teacher/Order/TeacherOrderDtoRequest'
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons'
import { RcFile } from 'antd/lib/upload'
import useFetchData from '../../../hooks/useFetchData'
import { TeacherOrderTypeDtoResponse } from '../../../interfaces/Teacher/Order/TeacherOrderTypeDtoResponse'
import { TeacherService } from '../../../services/TeacherService'
import { TeacherOrderCategoryDtoResponse } from '../../../interfaces/Teacher/Order/TeacherOrderCategoryDtoResponse'
import moment from 'moment'
import { successNotification } from '../../../helpers/successNotification'
import { TeacherOrderDtoResponse } from '../../../interfaces/Teacher/Order/TeacherOrderDtoResponse'
import { FileService } from '../../../services/file/FileService'
import TeacherSelectTable from '../TeachersPage/TeacherSelectTable'
import { useTeacherDetails } from '../TeachersPage/useTeacherDetails'
import { errorNotification } from '../../../helpers/errorNotification'

const TeacherOrderPage = () => {
    const { data: types } = useFetchData<TeacherOrderTypeDtoResponse[]>(TeacherService.getTeacherOrderTypes, true);
    const { data: orders, fetchData: fetchOrders } = useFetchData<TeacherOrderDtoResponse[]>(TeacherService.getTeacherOrdersByFilter, true);

    const [selectedTeacherId, setSelectedTeacherId] = React.useState<number[]>([]);
    const {
        searchValue,
        setSearchValue,
        teachersList,
        loading,
        handleSearchTeachers,
    } = useTeacherDetails();

    const [categories, setCategories] = React.useState<TeacherOrderCategoryDtoResponse[]>([]);
    const [form] = Form.useForm();

    const handleSelectType = (value: number) => {
        TeacherService.getTeacherOrderCategories(form.getFieldValue("typeId"))
            .then(({ data }) => setCategories(data))
    }

    React.useEffect(() => {
        form.setFieldsValue({
            teacherId: selectedTeacherId[0],
        });

        if (selectedTeacherId[0]) {

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTeacherId, form]);

    const uploadProps: UploadProps = {
        maxCount: 1,
        beforeUpload: (file) => {
            return false;
        },
        onChange: (e) => {
            form.setFieldsValue({ "file": e.fileList[0].originFileObj as RcFile });
        },
    }
    const addRecord = async (record: TeacherOrderDtoRequest) => {
        if (selectedTeacherId[0])
            TeacherService.createOrUpdateTeacherOrder(record)
                .then(() => successNotification('Order has been added!'))
                .finally(() => fetchOrders());
        else
            errorNotification('Choose teacher first!');
    };

    const editModalLogic = async (record: TeacherOrderDtoResponse) => {
        teachersList.list = [];

        await setSearchValue(record.teacher.surnameKz + " " + record.teacher.nameKz + " " + record.teacher.patronymicKz);
        await TeacherService.getTeacherOrderCategories(form.getFieldValue("typeId"))
            .then(({ data }) => setCategories(data))
        await form.setFieldsValue({ "teacherId": record.teacher.id })
    }

    const editRecord = (record: TeacherOrderDtoRequest) => {
        TeacherService.createOrUpdateTeacherOrder(record)
            .then(() => successNotification('Order has been updated!'))
            .finally(() => fetchOrders());
    };

    const deleteRecord = (record: TeacherOrderDtoRequest) => {
        TeacherService.deleteTeacherOrder(record.id)
            .then(() => successNotification('Order has been deleted!'))
            .finally(() => fetchOrders());
    };

    const handleDownload = (record: any) => {
        FileService.getFileById(record.fileId, "Order" as string);
    };

    return (
        <Page title='Orders' subtitle='Page for creating orders for teachers'>
            <UniversalTable
                columns={
                    [
                        {
                            title: "#",
                            dataIndex: "key",
                            key: "key",
                        },
                        {
                            title: 'Название',
                            dataIndex: 'name',
                            key: 'name',
                        },
                        {
                            title: 'Дата',
                            dataIndex: 'issueDate',
                            key: 'issueDate',
                        },
                        {
                            title: 'Номер',
                            dataIndex: 'number',
                            key: 'number',
                        },
                        {
                            title: 'Тип',
                            dataIndex: 'type',
                            key: 'type',
                        },
                        {
                            title: 'Файл',
                            key: 'download',
                            render: (_: string, record: any) => (
                                <Space>
                                    <Button icon={<DownloadOutlined />} onClick={() => handleDownload(record)}>Download</Button>
                                </Space>
                            ),
                        }
                    ]
                }
                dataSource={orders?.map((order, index) => ({ ...order, key: index + 1, id: order.id, type: order.type.nameRu, typeId: order.type.id, categoryId: order.category.id })) || []}
                addRecord={(e: TeacherOrderDtoRequest) => addRecord(e)}
                editRecord={(e: TeacherOrderDtoRequest) => editRecord(e)}
                deleteRecord={(e: TeacherOrderDtoRequest) => deleteRecord(e)}
                editModalLogic={editModalLogic}
                form={form}
                customForm={
                    <Form form={form} layout='vertical'>
                        <Form.Item
                            label="Тип приказа"
                            name="typeId"
                            rules={[{ required: true, message: 'Выберите тип приказа' }]}
                            style={{ marginBottom: '10px' }}>
                            <Select onSelect={handleSelectType}>
                                {types?.map((type) => (
                                    <Select.Option key={type.id} value={type.id}>
                                        {`${type.nameEn}/${type.nameRu}/${type.nameKz}`}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        {form.getFieldValue("typeId") && (
                            <Form.Item
                                label="Категория приказа"
                                name="categoryId"
                                rules={[{ required: true, message: 'Выберите категорию приказа' }]}
                                style={{ marginBottom: '10px' }}
                            >
                                <Select>
                                    {categories?.map((category) => (
                                        <Select.Option key={category.id} value={category.id}>
                                            {`${category.nameEn}/${category.nameRu}/${category.nameKz}`}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        )}
                        <Form.Item
                            label="Номер приказа"
                            name="number"
                            rules={[{ required: true, message: 'номер приказа' }]}
                            style={{ marginBottom: '10px' }}
                        >
                            <Input placeholder="Введите номер приказа" />
                        </Form.Item>
                        <Form.Item
                            label="Название приказа"
                            name="name"
                            rules={[{ required: true, message: 'Введите название приказа' }]}
                            style={{ marginBottom: '10px' }}
                        >
                            <Input placeholder="Введите название приказа" />
                        </Form.Item>
                        <Form.Item
                            label="Дата приказа"
                            name="issueDate"
                            rules={[{ required: true, message: 'Выберите дату создания приказа' }]}
                            style={{ marginBottom: '10px' }}
                            getValueFromEvent={(onChange) => moment(onChange).format('YYYY-MM-DD')}
                            getValueProps={(i) => ({ value: i ? moment(i) : null })}
                        >
                            <DatePicker placeholder="Выберите дату" style={{ width: '50%' }} />
                        </Form.Item>
                        <Form.Item
                            label="Дата движения"
                            name="contingentDate"
                            rules={[{ required: true, message: 'Выберите дату движения' }]}
                            style={{ marginBottom: '10px' }}
                            getValueFromEvent={(onChange) => moment(onChange).format('YYYY-MM-DD')}
                            getValueProps={(i) => ({ value: i ? moment(i) : null })}
                        >
                            <DatePicker placeholder="Выберите дату" style={{ width: '50%' }} />
                        </Form.Item>
                        <Form.Item
                            label="Преподаватель"
                            name="teacherId"
                            rules={[{ required: form.getFieldValue("teacherId") ? false : true, message: 'Выберите учителя' }]}>
                            <Space direction='vertical' size={"large"}>
                                <Input.Search
                                    placeholder="Введите ФИО Преподавателя"
                                    value={searchValue}
                                    onChange={(e) => setSearchValue(e.target.value)}
                                    onSearch={(value) => handleSearchTeachers(value)}
                                    enterButton
                                    style={{ width: 400 }}
                                />
                                {teachersList.list?.length > 0 && (
                                    <TeacherSelectTable
                                        loading={loading}
                                        data={teachersList.list}
                                        short
                                        selectedTeacherId={selectedTeacherId}
                                        setSelectedTeacherId={setSelectedTeacherId}
                                    />
                                )}
                            </Space>
                        </Form.Item>
                        <Form.Item name={"file"} label="Файл приказа" required valuePropName="file">
                            <Upload {...uploadProps} accept={'.pdf'}>
                                <Button icon={<UploadOutlined />}>Загрузите файл приказа</Button>
                            </Upload>
                        </Form.Item>
                    </Form>
                }
                isPublic={false}
            />
        </Page>
    )
}

export default TeacherOrderPage