import React, { useEffect, useState } from 'react';
import { Button, Card, Table, Typography } from 'antd';
import PageWrapper from '../../../ui/PageWrapper';
import { EyeOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { TeacherGraduationThesisService } from './TeacherGraduationThesisService';



const TeacherGraduationThesisDetails = () => {
    const [settings, setSettings] = useState<[]>([]);
    const [loading, setLoading] = useState(true);

    const history = useHistory();



    const viewTeacherGraduationThesis = (settingId: number) => {
        history.push(`/teacher-graduation-thesis/suggestions/settingId/${settingId}`);
    };

    useEffect(() => {
        const fetchSettings = async () => {
            try {
                const data = await TeacherGraduationThesisService.getDetailsByTeacherPrincipal();
                setSettings(data);
                setLoading(false);
            } catch (error) {
                console.error(error);
            }
        };
        fetchSettings();
    }, []);



    const columns = [
        // {
        //     title: 'id',
        //     dataIndex: ['setting', 'id'],
        //     key: 'id',
        // },
        {
            title: 'Defense Year',
            dataIndex: ['setting', 'defenseYear'],
            key: 'defenseYear',
            align: 'center' as const,
        },

        {
            title: 'Deadline Start',
            dataIndex: ['setting', 'deadlineStartAt'],
            key: 'deadlineStartAt',
            align: 'center' as const,
            render: (text: string) => moment(text).format('DD.MM.YYYY HH:mm:ss'),
        },
        {
            title: 'Deadline End',
            dataIndex: ['setting', 'deadlineEndAt'],
            key: 'deadlineEndAt',
            align: 'center' as const,
            render: (text: string) => moment(text).format('DD.MM.YYYY HH:mm:ss'),
        },
        {
            title: 'Actions',
            key: 'actions',
            align: 'center' as const,
            render: (record: any) => {
                const now = moment();
                const isButtonDisabled =
                    now.isBefore(moment(record.setting.deadlineStartAt)) ||
                    now.isAfter(moment(record.setting.deadlineEndAt));

                return (
                    <Button
                        icon={<EyeOutlined />}
                        onClick={() => viewTeacherGraduationThesis(record.id)}
                        disabled={isButtonDisabled}
                    >
                        View
                    </Button>
                );
            },
        },
    ];

    return (
        <PageWrapper>
            <Typography.Title level={1}>Graduation Thesis Details</Typography.Title>
            <Card>
                <Table
                    columns={columns}
                    dataSource={settings}
                    loading={loading}
                    rowKey="id"
                    style={{ marginTop: 30 }}
                />
            </Card>


        </PageWrapper>
    );
};


export default TeacherGraduationThesisDetails;
