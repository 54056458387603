import authAxios from "../common/authAxios";
import { AbiturCatalogDtoResponse } from "../interfaces/AbiturCatalogDtoResponse";
import { AcademicMobilityCompetitionDtoRequest, AcademicMobilityCompetitionDtoResponse, AcademicMobilityCompetitionStatusDto, ApprovalSheetDto, CollationStatementGradeDto, ForeignDisciplinesDtoRequest, LearningAgreementDisciplinesDto, StudentAcademicMobilityCompetitionDto, StudentAcademicMobilityCompetitionInfoDto, StudentAcademicMobilityCompetitionInfoDtoRequest, StudentAcademicMobilityCompetitionInfoTypeDto, StudentCompetitionLearningAgreementInfoDto } from "../interfaces/AcademicMobilityCompetitionDto";
import { ApplicationApproveDtoRequest } from "../interfaces/Applications/request/ApplicationApproveDtoRequest";
import { CollationStatementApproveDto, LearningAgreementApproveDto, StudentCollationStatementDto } from "../interfaces/LearningAgreementDto";

export class AcademicMobilityCompetitionService {
    static getStatuses() {
        return authAxios.get<AcademicMobilityCompetitionStatusDto[]>(
            `/astanait-office-module/api/v1/academic-mobility/competition/statuses`
        );
    }

    static getAllCompetitions() {
        return authAxios.get<AcademicMobilityCompetitionDtoResponse[]>(
            `/astanait-office-module/api/v1/academic-mobility/competition`
        );
    }

    static getCompetitionById(id: number) {
        return authAxios.get<AcademicMobilityCompetitionDtoResponse>(
            `/astanait-office-module/api/v1/academic-mobility/competition/${id}`
        );
    }

    static createCompetition(competition: AcademicMobilityCompetitionDtoRequest) {
        return authAxios.post(
            `/astanait-office-module/api/v1/academic-mobility/competition`,
            competition
        );
    }

    static updateCompetition(competition: AcademicMobilityCompetitionDtoRequest) {
        return authAxios.put(
            `/astanait-office-module/api/v1/academic-mobility/competition/${competition.id}`,
            competition
        );
    }

    static deleteCompetition(id: number) {
        return authAxios.delete(
            `/astanait-office-module/api/v1/academic-mobility/competition/${id}`
        );
    }

    static getStudentCompetitionStatuses() {
        return authAxios.get<AcademicMobilityCompetitionStatusDto[]>(
            `/astanait-office-module/api/v1/academic-mobility/student-competition/statuses`
        );
    }

    static getStudentCompetitions() {
        return authAxios.get<StudentAcademicMobilityCompetitionDto[]>(
            `/astanait-office-module/api/v1/academic-mobility/student-competition`
        );
    }

    static getStudentCompetitionById(id: number) {
        return authAxios.get<StudentAcademicMobilityCompetitionDto>(
            `/astanait-office-module/api/v1/academic-mobility/student-competition/${id}`
        );
    }

    static getStudentCompetitionsByCompetitionIdAndStatus(competitionId: number, statusId: number) {
        return authAxios.get<StudentAcademicMobilityCompetitionDto[]>(
            `/astanait-office-module/api/v1/academic-mobility/student-competition/competition/${competitionId}/status/${statusId}`
        );
    }

    static getStudentCompetitionInfoById(id: number) {
        return authAxios.get<StudentAcademicMobilityCompetitionInfoDto[]>(
            `/astanait-office-module/api/v1/academic-mobility/student-competition/info/${id}`
        );
    }

    static getStudentCompetitionInfoTypes() {
        return authAxios.get<StudentAcademicMobilityCompetitionInfoTypeDto[]>(
            `/astanait-office-module/api/v1/academic-mobility/student-competition/info/types`
        );
    }

    static getAvailableStatuses(studentCompetitionId: number) {
        return authAxios.get<AcademicMobilityCompetitionStatusDto[]>(
            `/astanait-office-module/api/v1/academic-mobility/student-competition/${studentCompetitionId}/available-statuses`
        );
    }

    static createStudentCompetition(studentCompetition: StudentAcademicMobilityCompetitionInfoDtoRequest[], competitionId?: number) {
        return authAxios.post(
            `/astanait-office-module/api/v1/academic-mobility/student-competition/${competitionId}`, studentCompetition,
            {
                headers: {
                    showNotification: false
                },
            }
        );
    }

    static updateStudentCompetitionInfo(id: number, value: string) {
        return authAxios.put(
            `/astanait-office-module/api/v1/academic-mobility/student-competition/info/${id}`, value,
            {
                headers: {
                    'Content-Type': 'text/plain',
                    showNotification: false
                },
            }
        );

    }

    static updateStudentCompetitionStatus(studentCompetitionId: number, request: ApplicationApproveDtoRequest) {
        return authAxios.put(
            `/astanait-office-module/api/v1/academic-mobility/student-competition/${studentCompetitionId}/status`, request
        );
    }

    static getLanguageLevels() {
        return authAxios.get<AbiturCatalogDtoResponse[]>(
            `/astanait-office-module/api/v1/dict/language-levels`
        );
    }
    static getLearningAgreement(learningAgreementId: number) {
        return authAxios.get<LearningAgreementDisciplinesDto>(
            `/astanait-office-module/api/v1/learning-agreement/${learningAgreementId}`
        );
    }
    static getStudentCompetition(studentCompetitionId: number) {
        return authAxios.get<StudentCompetitionLearningAgreementInfoDto>(
            `/astanait-office-module/api/v1/learning-agreement/student-competition/${studentCompetitionId}`
        );
    }
    static getAituDisciplines(educationYear: number | undefined, term: number) {
        return authAxios.get<any[]>(
            `/astanait-office-module/api/v1/academic-department/curriculum/student-curriculum?educationYear=${educationYear}&term=${term}`
        );
    }
    static createAituDiscipline(learningAgreementId: number | undefined, request: any) {
        return authAxios.post(
            `/astanait-office-module/api/v1/learning-agreement/aitu-discipline/?learningAgreementId=${learningAgreementId}`, request
        );
    }
    static createForeignDiscipline(learningAgreementId: number | undefined, request: ForeignDisciplinesDtoRequest) {
        return authAxios.post(
            `/astanait-office-module/api/v1/learning-agreement/foreign-discipline/?learningAgreementId=${learningAgreementId}`, request
        );
    }
    static createRecredit(learningAgreementId: number | undefined, request: any) {
        return authAxios.post(
            `/astanait-office-module/api/v1/learning-agreement/recredit/?learningAgreementId=${learningAgreementId}`, request
        );
    }
    static deleteAituDiscipline(id: number) {
        return authAxios.delete(
            `/astanait-office-module/api/v1/learning-agreement/aitu-discipline/${id}`
        );
    }
    static deleteForeignDiscipline(id: number) {
        return authAxios.delete(
            `/astanait-office-module/api/v1/learning-agreement/foreign-discipline/${id}`
        );
    }
    static deleteRecreditDiscipline(id: number) {
        return authAxios.delete(
            `/astanait-office-module/api/v1/learning-agreement/recredit/${id}`
        );
    }
    static updateAituDiscipline(learningAgreementId: number, request: any) {
        return authAxios.put(
            `/astanait-office-module/api/v1/learning-agreement/aitu-discipline/?id=${learningAgreementId}`, request
        );
    }
    static updateRecredit(learningAgreementId: number, request: any) {
        return authAxios.put(
            `/astanait-office-module/api/v1/learning-agreement/recredit/?id=${learningAgreementId}`, request
        );
    }
    static updateForeignDiscipline(learningAgreementId: number, request: ForeignDisciplinesDtoRequest) {
        return authAxios.put(
            `/astanait-office-module/api/v1/learning-agreement/foreign-discipline/?id=${learningAgreementId}`, request
        );
    }
    static getAllLearningAgreements(studentCompetitionId: number) {
        return authAxios.get<any[]>(
            `/astanait-office-module/api/v1/learning-agreement/student-competition/${studentCompetitionId}`
        );
    }
    static addAgreement(studentCompetitionId: number | undefined, name: string) {
        return authAxios.post(
            `/astanait-office-module/api/v1/learning-agreement/student-competition/${studentCompetitionId}?name=${name}`
        );
    }
    static updateAgreement(learningAgreementId: number | undefined, name: string) {
        return authAxios.put(
            `/astanait-office-module/api/v1/learning-agreement/${learningAgreementId}?name=${name}`
        );
    }
    static sendForApproval(learningAgreementId: number | undefined,) {
        return authAxios.put(
            `/astanait-office-module/api/v1/learning-agreement/${learningAgreementId}/send-for-approval`
        );
    }
    static deleteAgreement(learningAgreementId: number | undefined,) {
        return authAxios.delete(
            `/astanait-office-module/api/v1/learning-agreement/${learningAgreementId}`
        );
    }
    static getCollationStatement(studentCompetitionId: number | undefined) {
        return authAxios.get<StudentCollationStatementDto>(
            `/astanait-office-module/api/v1/collation-statement/student-competition/${studentCompetitionId}`
        );
    }
    static getCollationStatementById(statementId: number | undefined) {
        return authAxios.get<any>(
            `/astanait-office-module/api/v1/collation-statement/${statementId}`
        );
    }
    static getCollationStatementGrade(collationStatementId: number) {
        return authAxios.get<CollationStatementGradeDto[]>(
            `/astanait-office-module/api/v1/collation-statement/${collationStatementId}/grade`
        );
    }
    static updateOriginalGrade(gradeId: number | undefined, grade: any) {
        return authAxios.put(
            `/astanait-office-module/api/v1/collation-statement/grade/${gradeId}/original-grade?originalGrade=${grade}`
        );
    }
    static updateFiles(studentCompetitionId: number | undefined, formData: FormData) {
        return authAxios.put(`astanait-office-module/api/v1/collation-statement/${studentCompetitionId}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }
    static createFiles(studentCompetitionId: number | undefined, formData: FormData) {
        return authAxios.post(`astanait-office-module/api/v1/collation-statement?studentCompetitionId=${studentCompetitionId}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }
    static getCollationApprovalSheet(collationStatementId: number) {
        return authAxios.get<ApprovalSheetDto[]>(
            `/astanait-office-module/api/v1/collation-statement/${collationStatementId}/approve`
        );
    }
    static getLearningAgreementApprovalSheet(learningAgreementId: number) {
        return authAxios.get<ApprovalSheetDto[]>(
            `/astanait-office-module/api/v1/learning-agreement/${learningAgreementId}/approve`
        );
    }

    //Office
    static getLearningAgreementForApproval() {
        return authAxios.get<LearningAgreementApproveDto[]>(
            `/astanait-office-module/api/v1/learning-agreement/current-for-approve`
        );
    }

    static learningAgreementApprove(learningAgreementId: number, request: {
        comment: string,
        approved: boolean
    }) {
        return authAxios.put(
            `/astanait-office-module/api/v1/learning-agreement/approve/${learningAgreementId}`, request
        );
    }

    static getLearningAgreementByApprovalById(approveId: number) {
        return authAxios.get<LearningAgreementApproveDto>(
            `/astanait-office-module/api/v1/learning-agreement/approve/${approveId}`
        );
    }

    static getCollationStatementForApproval() {
        return authAxios.get<CollationStatementApproveDto[]>(
            `/astanait-office-module/api/v1/collation-statement/current-for-approve`
        );
    }

    static collationStatementApprove(collationStatementId: number, request: {
        comment: string,
        approved: boolean
    }) {
        return authAxios.put(
            `/astanait-office-module/api/v1/collation-statement/approve/${collationStatementId}`, request
        );
    }

    static getCollationStatementByApprovalById(approveId: number) {
        return authAxios.get<CollationStatementApproveDto>(
            `/astanait-office-module/api/v1/collation-statement/approve/${approveId}`
        );
    }

    static updateAituGrade(gradeId: number, aituGrade: number) {
        return authAxios.put(
            `/astanait-office-module/api/v1/collation-statement/grade/${gradeId}/aitu-grade?aituGrade=${aituGrade}`
        );
    }

}