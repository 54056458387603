import PageWrapper from '../../../ui/PageWrapper';
import { Typography, Button, Space, Select, Input, Table } from 'antd';
import SelectOptionsFilter from './SelectOptionsFilter';
import useReceptionAppointment from './useReceptionAppointment';


const { Title } = Typography;

const ReceptionAppointment = () => {
    const {
        selectedFiltersOption,
        saveData,
        handleSelect,
        selectedDate,
        places,
        referrals,
        availableDate,
        selectedDateTimes,
        availableDateTimes,
        comment,
        columns,
        dataSource,
        selectedPlace,
    } = useReceptionAppointment();

    const PUBLIC_URL = process.env.PUBLIC_URL;
    // const options = availableDate.map(date => ({ value: date, label: date }));
    // const dateTimes = availableDateTimes.map(dateString => {
    //     const date = new Date(dateString);
    //     const hours = date.getHours();
    //     const minutes = date.getMinutes();
    //     return { value: dateString, label: `${hours}:${minutes < 10 ? '0' : ''}${minutes}` };
    // });

    return (
        <PageWrapper>
            <section>
                <header >
                    <Title level={3} >
                        Make an appointment
                    </Title>
                </header>
                <Space direction='vertical' style={{ width: "100%" }}>
                    <Space size={20} direction="vertical" style={{ marginBottom: '20px', width: "100%" }}>
                        <Select
                            placeholder="Место посещения"
                            value={selectedPlace}
                            onSelect={(value) => handleSelect.handleSelectPlace(value)}
                            options={places}
                            style={{ width: 400 }}
                        ></Select>
                        {selectedPlace &&
                            <Select
                                placeholder="Цель посещения"
                                value={selectedFiltersOption}
                                onSelect={(value) => handleSelect.handleSelectOption(value)}
                                style={{ width: 400 }}
                                options={referrals}
                            ></Select>
                        }
                        {selectedFiltersOption && (
                            <div>
                                <Select
                                    placeholder="Дата"
                                    value={selectedDate}
                                    onSelect={(value) => handleSelect.handleSelectDate(value)}
                                    options={availableDate}
                                    style={{ width: 400 }}
                                ></Select>
                            </div>
                        )}

                        {selectedDate && (
                            <>
                                <div>
                                    <Select
                                        placeholder="Time"
                                        value={selectedDateTimes}
                                        onSelect={(value) => handleSelect.handleSelectDateTime(value)}
                                        options={availableDateTimes}
                                        style={{ width: 400 }}
                                    >
                                    </Select>
                                </div>

                                <div >
                                    <Input.TextArea
                                        placeholder="По какому вопросу"
                                        value={comment}
                                        onChange={handleSelect.handleChangeComment}
                                        style={{ width: 600 }} />
                                </div>

                                {selectedFiltersOption === 2 && (
                                    <>
                                        <p style={{ margin: 0 }}>Заполните заявление по шаблону и принесите в деканат </p>
                                        <a style={{ margin: 0 }}
                                            href={`${PUBLIC_URL}/assets/applicationForPass.docx`} download>Скачать шаблон заявления</a>
                                    </>
                                )}
                                {selectedDateTimes && (
                                    <Space>
                                        <Button type={'primary'} onClick={saveData}>
                                            Make an appointment
                                        </Button>
                                    </Space>
                                )}
                            </>
                        )}

                        <Typography.Title level={3} style={{ marginTop: 50 }} >My appointments</Typography.Title>
                        <Table
                            columns={columns}
                            dataSource={dataSource} />
                    </Space>
                </Space>
            </section>
        </PageWrapper >
    );
};

export default ReceptionAppointment;
