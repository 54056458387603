import { useState, ReactNode, useEffect } from 'react';
import { ReceptionAppointmentService } from '../../../../services/ReceptionAppointmentService';
import { AvailableAppointmentDatesResponse } from '../../../../interfaces/deansOffice/AvailableAppointmentDatesResponse';
import { ReceptionAppointmentDtoResponse } from '../../../../interfaces/deansOffice/ReceptionAppointmentDtoResponse';

const useReceptionAppointmentList = () => {

    const [places, setPlaces] = useState<any[]>([]);
    const [referrals, setReferrals] = useState<any[]>([]);
    const [selectedPlace, setSelectedPlace] = useState<number | undefined>();
    const [selectedFiltersOption, setSelectedFiltersOption] = useState<number | undefined>();
    const [selectedDate, setSelectedDate] = useState<Date | undefined>();
    const [availableDate, setAvailableDate] = useState<any[]>([]);
    const [appointmentData, setAppointmentData] = useState<ReceptionAppointmentDtoResponse[]>([]);



    useEffect(() => {
        ReceptionAppointmentService.getPlacesForOffice().then((response) => {
            const formattedPlaces = response.data.map((place) => ({
                value: place.id, // ID для идентификации
                label: place.name, // Имя для отображения
            }));
            setPlaces(formattedPlaces); // Установить преобразованные данные

        });
        setSelectedDate(undefined);
    }, [selectedPlace]);

    const handleSelectPlace = (value: number) => {
        setSelectedPlace(value);
        setSelectedFiltersOption(undefined);
        setSelectedDate(undefined);
        ReceptionAppointmentService.getReferralForOffice(value).then((response) => {
            const formattedReferrals = response.data.map((referral) => ({
                value: referral.type.id,
                label: referral.type.name,
            }));
            setReferrals(formattedReferrals);
        });
    };

    const handleSelectOption = (value: number) => {
        setSelectedFiltersOption(value);
        setSelectedDate(undefined);
        ReceptionAppointmentService.getAvailableDates(value).then((response) => {
            const formattedDate = response.data.map((date) => ({
                value: date,
                label: date,
            }));
            setAvailableDate(formattedDate);
        });
    };

    const handleSelectDate = (value: Date) => {
        setSelectedDate(value);
        ReceptionAppointmentService.getAllAppointments(selectedFiltersOption, value)
            .then((response) => {
                setAppointmentData(response.data)
            })
    };



    return {

        handleSelect: {
            handleSelectOption,
            handleSelectDate,
            handleSelectPlace
        },

        selectedFiltersOption,
        selectedDate,
        availableDate,
        appointmentData,
        selectedPlace,
        referrals,
        places

    };
};

export default useReceptionAppointmentList;


