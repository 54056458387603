import { ApplicationService } from '../../../services/ApplicationService';
import { ApplicationDtoResponse } from '../../../interfaces/Applications/response/ApplicationDtoResponse';
import useFetchData from '../../../hooks/useFetchData';
import React, { useEffect, useState } from 'react';
import { ApplicationApproveDtoRequest } from '../../../interfaces/Applications/request/ApplicationApproveDtoRequest';
import { ApplicationInfoTypesDtoResponse } from '../../../interfaces/Applications/response/ApplicationInfoTypesDtoResponse';
import { StudentApplicationDtoRequest } from '../../../interfaces/Applications/request/StudentApplicationDtoRequest';
import { successNotification } from '../../../helpers/successNotification';
import { ApplicationReasonDto, ApplicationTypeDtoResponse } from '../../../interfaces/Applications/response/ApplicationTypeDtoResponse';
import { ApplicationInfoDtoRequest } from '../../../interfaces/Applications/request/ApplicationInfoDtoRequest';
import { ApplicationInfoDtoResponse } from '../../../interfaces/Applications/response/ApplicationInfoDtoResponse';
import { ApplicationStepInfoDtoResponse } from '../../../interfaces/Applications/response/ApplicationStepInfoDtoResponse';
import { FileService } from '../../../services/file/FileService';
import { RcFile } from 'antd/lib/upload';
import { OrderStudentDtoRequest } from '../../../interfaces/Order/OrderStudentDtoRequest';
import grantPermission from '../../../helpers/grantPermission';
import { notification } from 'antd';

interface Props {
    fetchAllApplications?: boolean;
    fetchApplicationId?: boolean;
    fetchApplicationInfo?: boolean;
    fetchApplicationStepInfo?: boolean;
    fetchApplicationCount?: boolean;
    params?: any;
    id?: number;

}

const useApplications = ({ fetchAllApplications = false, fetchApplicationId = false, fetchApplicationInfo = false, fetchApplicationStepInfo = false, fetchApplicationCount = false, id, params }: Props) => {
    const callbackApplicationInfo = React.useCallback(() => ApplicationService.getApplicationInfo(id), [id]);
    const callbackApplicationStepInfo = React.useCallback(() => ApplicationService.getApplicationSteps(id), [id]);
    const callbackApplicationById = React.useCallback(() => ApplicationService.getApplicationById(id), [id]);
    const callbackApplicationCount = React.useCallback(() => ApplicationService.getApplicationCount(), []);

    const { data: applications, isLoading: isLoadingApplications, fetchData: fetchApplications } = useFetchData<ApplicationDtoResponse[]>(ApplicationService.getApplications, fetchAllApplications);
    const { data: application, fetchData: getApplicationById } = useFetchData<ApplicationDtoResponse>(callbackApplicationById, fetchApplicationId);
    const { data: applicationInfo, fetchData: getApplicationInfo } = useFetchData<ApplicationInfoDtoResponse[]>(callbackApplicationInfo, fetchApplicationInfo);
    const { data: applicationStepInfo, fetchData: getApplicationStepInfo } = useFetchData<ApplicationStepInfoDtoResponse[]>(callbackApplicationStepInfo, fetchApplicationStepInfo);
    const { data: applicationCount, fetchData: getApplicationCount } = useFetchData<number>(callbackApplicationCount, grantPermission('OFFICE', 'baseRole') && fetchApplicationCount);

    const [applicationsData, setApplicationsData] = React.useState<ApplicationDtoResponse[]>([]);
    const [applicationInfoTypes, setApplicationInfoTypes] = React.useState<ApplicationInfoTypesDtoResponse[]>([]);
    const [applicationReasons, setApplicationReasons] = React.useState<ApplicationReasonDto[]>([]);

    const [selectedLanguage, setSelectedLanguage] = React.useState<number | undefined>();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [totalPages, setTotalPages] = React.useState(1);
    const [statusId, setStatusId] = useState(null);
    const [typeId, setTypeId] = useState(null);
    const [iin, setIin] = useState('');
    const [fullname, setFullname] = useState('');
    const [course, setCourse] = useState(null);
    const [degree, setDegree] = useState(null);
    const [startDate, setStartDate] = useState<string | null>('');
    const [endDate, setEndDate] = useState<string | null>('');
    const [order, setOrder] = useState(2);
    const [sign, setSign] = useState(false);
    const [allApplications, setAllApplications] = useState(false);

    const languages = [{
        id: 1,
        name: "Русский / Russian / Орысша"
    },
    {
        id: 2,
        name: "Казахский / Kazakh / Қазақша"
    },
    {
        id: 3,
        name: "Английский / English / Ағылшын"

    }]

    const handleLanguage = (langId: number): string => {
        switch (langId) {
            case 1:
                return "Русский / Russian / Орысша";
            case 2:
                return "Казахский / Kazakh / Қазақша";
            case 3:
                return "Английский / English / Ағылшын";
            default:
                return "Не определен / Not defined / Анықталмаған";
        }
    }

    const handleSelectLanguage = (value: number) => {
        setSelectedLanguage(value);
    };

    useEffect(() => {
        if (grantPermission('OFFICE', 'baseRole')) {
            setIsLoading(true);
            if (order !== 2) {
                handleFilterByOrder({
                    orderCreated: order,
                    iin: iin,
                    fullname: fullname,
                })
            }
            if (sign === true) {
                handleFilterBySign({ iin: iin, fullname: fullname, })
            }
            else if (allApplications === true) {
                handleFilter({
                    statusId: statusId,
                    typeId: typeId,
                    iin: iin,
                    fullname: fullname,
                    course: course,
                    degree: degree,
                    startDate: startDate,
                    endDate: endDate,
                },)
            }
        }
    }, [currentPage, sign]);

    useEffect(() => {
        if (grantPermission('STUDENTS', 'baseRole')) {
            application && ApplicationService.getApplicationReasonsByType(application?.typeId.id)
                .then((res) => setApplicationReasons(res.data));
        }
    }, [application]);

    const handleFilter = (params: any) => {
        const zeroBasedPage = currentPage - 1;
        setIsLoading(true);
        ApplicationService.getApplicationsByFilter(params, zeroBasedPage).then((res) => {
            setApplicationsData(res.data.data)
            setTotalPages(res.data.totalPages);
        }).finally(() => { setIsLoading(false) });
    }

    const handleFilterByOrder = (params: any) => {
        setApplicationsData([])
        const zeroBasedPage = currentPage - 1;
        setIsLoading(true);
        setOrder(params.orderCreated)
        ApplicationService.getAllApplicationForOrder(params, zeroBasedPage).then((res) => {
            setApplicationsData(res.data.data)
            setTotalPages(res.data.totalPages);
            if (res.data.totalPages === 0) {
                notification.error({ message: "Applications not found!" });
            }
        })
            .finally(() => { setIsLoading(false) });
    }

    const handleFilterBySign = (params: any) => {
        setApplicationsData([])
        setIsLoading(true);
        setSign(true)
        const zeroBasedPage = currentPage - 1;
        ApplicationService.getApplicationForSign(params, zeroBasedPage).then((res) => {
            setApplicationsData(res.data.data)
            setTotalPages(res.data.totalPages);
        })
            .finally(() => { setIsLoading(false) });
    }

    const handleSelectApplicationType = (applicationTypeId: number) => {
        ApplicationService.getApplicatinInfoTypes(applicationTypeId)
            .then((res) => setApplicationInfoTypes(res.data));

        ApplicationService.getApplicationReasonsByType(applicationTypeId)
            .then((res) => setApplicationReasons(res.data));
    }

    const handleApprove = (approveDtoRequest: ApplicationApproveDtoRequest, id: number) => {
        return ApplicationService.approveApplication(approveDtoRequest, id).then(() => successNotification("Application successfully approved!"));
    }

    const handleCreate = (studentApplicationDtoRequest: StudentApplicationDtoRequest) => {
        return ApplicationService.createApplication(studentApplicationDtoRequest)
            .then(() => successNotification("Application successfully created!"))
            .catch((error) => {
                if (error.response?.data?.message === "An application of this type already exists") {
                    notification.warning({ message: "Заявка данного типа уже существует!" });
                }
            });
    }

    const handleUpdate = (applicationInfoDtoRequestList: ApplicationInfoDtoRequest[], id: number, fileId: number) => {
        return ApplicationService.updateApplication(applicationInfoDtoRequestList, id, fileId)
            .then(() => successNotification("Application successfully updated!"))
            .finally(() => {
                getApplicationById();
                getApplicationInfo();
                getApplicationStepInfo();
            });
    }

    const handleUpdateInfo = (applicationInfoDtoRequestList: ApplicationInfoDtoRequest[], id: number) => {
        return ApplicationService.updateApplicationInfo(applicationInfoDtoRequestList, id)
            .then(() => successNotification("Application successfully updated!"))
            .finally(() => {
                getApplicationById();
                getApplicationInfo();
                getApplicationStepInfo();
            });
    }

    const handleDownload = (id: number, applicationType: ApplicationTypeDtoResponse) => {
        return FileService.getFileById(id, `${applicationType.nameRu}/${applicationType.nameEn}/${applicationType.name}`)
            .then((res) => successNotification("File successfully downloaded!"));
    }
    const handleDownloadTestApplication = (id: number, applicationType: ApplicationTypeDtoResponse) => {
        return FileService.getApplicationFileTestById(id, `${applicationType.nameRu}/${applicationType.nameEn}/${applicationType.name}`)
            .then((res) => successNotification("File successfully downloaded!"));
    }

    const handleOrder = (applicationId: number, file: RcFile, orderStudentDtoRequest: OrderStudentDtoRequest) => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("orderStudentDtoRequest", new Blob([JSON.stringify(orderStudentDtoRequest)], { type: 'application/json' }));

        return ApplicationService.createOrder(applicationId, formData)
    }

    const handleUpdateReason = (applicationId: number, reason: string) => {
        return ApplicationService.updateApplicationReason(applicationId, reason)
            .then(() => successNotification("Reason successfully updated!"));
    }

    return {
        data: {
            applications,
            applicationsData,
            applicationInfoTypes,
            applicationReasons,
            application,
            applicationInfo,
            applicationStepInfo,
            applicationCount,
            isLoadingApplications,
            isLoading,
            languages,
            selectedLanguage,
            currentPage,
            totalPages,
            statusId,
            typeId,
            iin,
            fullname,
            course,
            degree,
            startDate,
            endDate,
            order
        },
        methods: {
            setCurrentPage,
            handleLanguage,
            handleSelectLanguage,
            handleFilter,
            handleFilterByOrder,
            handleFilterBySign,
            handleUpdateReason,
            handleApprove,
            handleCreate,
            handleUpdate,
            handleUpdateInfo,
            handleSelectApplicationType,
            handleDownload,
            handleOrder,
            fetchApplications,
            getApplicationById,
            getApplicationInfo,
            getApplicationStepInfo,
            getApplicationCount,
            handleDownloadTestApplication,
            setStatusId,
            setFullname,
            setIin,
            setTypeId,
            setOrder,
            setSign,
            setCourse,
            setDegree,
            setStartDate,
            setEndDate,
            setAllApplications
        }
    }
}

export default useApplications;