import { useCallback } from "react";
import useFetchData from "../../../../../hooks/useFetchData";
import { aituDisciplinesDto, ApprovalSheetDto, foreignDisciplinesDto, LearningAgreementDisciplinesDto, recreditPlanDto, StudentAcademicMobilityCompetitionDto } from '../../../../../interfaces/AcademicMobilityCompetitionDto';
import { AcademicMobilityCompetitionService } from '../../../../../services/AcademicMobilityCompetitionService';
import { AxiosResponse } from "axios";
import { EducationalProgramsService } from "../../../../../services/EducationalProgramsService";
import { EducationProgramDtoResponse } from "../../../../../interfaces/EducationalPrograms/EducationProgramDtoResponse";
import { LearningAgreementApproveDto } from "../../../../../interfaces/LearningAgreementDto";

export default function useLearningAgreement(id: number) {
    const callbackLearningAgreementApprove = useCallback(() => AcademicMobilityCompetitionService.getLearningAgreementByApprovalById(id), [id])
    const { data: learningAgreementApprove, fetchData: fetchLearningAgreementApprove } = useFetchData<LearningAgreementApproveDto>(callbackLearningAgreementApprove, true);

    const callbackLearningAgreement = useCallback(() => {
        if (learningAgreementApprove?.learningAgreementId) {
            return AcademicMobilityCompetitionService.getLearningAgreement(learningAgreementApprove?.learningAgreementId)
        }
        return Promise.resolve({} as AxiosResponse<LearningAgreementDisciplinesDto>)
    }, [learningAgreementApprove?.learningAgreementId]);

    const callbackLearningAgreementApprovalSheet = useCallback(() => {
        if (learningAgreementApprove?.learningAgreementId) {
            return AcademicMobilityCompetitionService.getLearningAgreementApprovalSheet(learningAgreementApprove?.learningAgreementId)
        }
        return Promise.resolve({} as AxiosResponse<ApprovalSheetDto[]>)
    }, [learningAgreementApprove?.learningAgreementId])

    const { data: learningAgreement, fetchData: fetchLearningAgreements } = useFetchData<LearningAgreementDisciplinesDto>(callbackLearningAgreement, true);
    const { data: approvalSheet, fetchData: fetchApprovalSheet } = useFetchData<ApprovalSheetDto[]>(callbackLearningAgreementApprovalSheet, true);

    const callbackStudentCompetition = useCallback(() => {
        if (learningAgreement?.studentAcademicMobilityCompetitionId !== undefined) {
            return AcademicMobilityCompetitionService.getStudentCompetitionById(learningAgreement.studentAcademicMobilityCompetitionId);
        }
        return Promise.resolve({} as AxiosResponse<StudentAcademicMobilityCompetitionDto>);
    }, [learningAgreement]);

    const callbackEducationalProgram = useCallback(() => {
        if (learningAgreement?.student.educationProgramId !== undefined) {
            return EducationalProgramsService.getOneEducationalProgram(learningAgreement.student.educationProgramId);
        }
        return Promise.resolve({} as AxiosResponse<EducationProgramDtoResponse>);
    }, [learningAgreement]);

    const { data: studentCompetition } = useFetchData<StudentAcademicMobilityCompetitionDto>(callbackStudentCompetition, true);
    const { data: educationalProgram } = useFetchData<EducationProgramDtoResponse>(callbackEducationalProgram, true);

    const foreignDisciplinesData = learningAgreement?.foreignDisciplines.map((foreignDiscipline: foreignDisciplinesDto) => ({
        key: foreignDiscipline.id,
        code: foreignDiscipline.code,
        term: foreignDiscipline.term,
        form: foreignDiscipline.format,
        ects: foreignDiscipline.ectsCredits,
        name: foreignDiscipline.name,
        nameEn: foreignDiscipline.nameEn,
        nameRu: foreignDiscipline.nameRu,
        originalCredits: foreignDiscipline.originalCredits,
        source: "foreign"
    })) || [];

    const aituDisciplinesData = learningAgreement?.aituDisciplines.map((aituDiscipline: aituDisciplinesDto) => ({
        key: aituDiscipline.id,
        code: aituDiscipline.curriculum.discipline.code,
        term: aituDiscipline.curriculum.numberOfTrimester,
        form: aituDiscipline.format,
        ects: aituDiscipline.curriculum.discipline.volumeCredits,
        discipline: aituDiscipline.curriculum.discipline.titleEn,
        source: "aitu",
        academicYear: Number(learningAgreement.student.courseGrade) - aituDiscipline.curriculum.year + new Date().getFullYear(),
        curriculumId: aituDiscipline.curriculum.id
    })) || [];

    const recreditPlanData = learningAgreement?.recreditPlanList.map((recreditPlan: recreditPlanDto) => ({
        key: recreditPlan.id,
        foreignDiscipline: recreditPlan.foreignDiscipline.name,
        foreignDisciplineId: recreditPlan.foreignDiscipline.id,
        ects: recreditPlan.foreignDiscipline.ectsCredits,
        aituDiscipline: recreditPlan.aituDiscipline.curriculum.discipline.titleEn,
        aituDisciplineId: recreditPlan.aituDiscipline.id,
        ectsAitu: recreditPlan.aituDiscipline.curriculum.discipline.volumeCredits,
        source: "recredit"
    })) || [];


    const columnForeign = [
        {
            title: 'Code',
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',

        },
        {
            title: 'Name EN',
            dataIndex: 'nameEn',
            key: 'nameEn',

        },
        {
            title: 'Name RU',
            dataIndex: 'nameRu',
            key: 'nameRu',

        },
        {
            title: 'Term',
            dataIndex: 'term',
            key: 'term',
        },
        {
            title: 'Form',
            dataIndex: 'form',
            key: 'form',
        },
        {
            title: 'ECTS credit',
            dataIndex: 'ects',
            key: 'ects'
        },
        {
            title: 'Original Credit',
            dataIndex: 'originalCredits',
            key: 'originalCredits'
        },
    ];

    const columnAitu = [
        {
            title: 'Code',
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: 'Discipline',
            dataIndex: 'discipline',
            key: 'discipline',

        },
        {
            title: 'Term',
            dataIndex: 'term',
            key: 'term',
        },
        {
            title: 'Form',
            dataIndex: 'form',
            key: 'form',
        },
        {
            title: 'ECTS',
            dataIndex: 'ects',
            key: 'ects'
        },
    ];

    const columnRecredit = [
        {
            title: 'Foreign Discipline',
            dataIndex: 'foreignDiscipline',
            key: 'foreignDiscipline',
        },
        {
            title: 'ECTS',
            dataIndex: 'ects',
            key: 'ects',

        },
        {
            title: 'AITU Discipline',
            dataIndex: 'aituDiscipline',
            key: 'aituDiscipline',
        },
        {
            title: 'ECTS AITU',
            dataIndex: 'ectsAitu',
            key: 'ectsAitu',
        },
    ];

    return {
        learningAgreement,
        learningAgreementApprove,
        approvalSheet,
        studentCompetition,
        educationalProgram,
        foreignDisciplinesData,
        aituDisciplinesData,
        recreditPlanData,
        columnForeign,
        columnAitu,
        columnRecredit,
        fetchLearningAgreements,
        fetchApprovalSheet,
        fetchLearningAgreementApprove,
    }
}