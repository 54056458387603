
import { Typography, Table, Button, Pagination, Modal, Form, Input, Space, Popconfirm } from 'antd';
import PageWrapper from '../../../../ui/PageWrapper';
import { DeleteTwoTone, EditOutlined, EditTwoTone } from '@ant-design/icons';
import { AcademicMobilityCompetitionService } from '../../../../services/AcademicMobilityCompetitionService';
import { useEffect, useState } from 'react';
import { successNotification } from '../../../../helpers/successNotification';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
const { Title } = Typography;

interface Props {
    id: number
}
const LearningAgreementPage = ({ id }: Props) => {
    const [learningAgreement, setLearningAgreement] = useState<any[]>([]);
    const [shouldRerender, setShouldRerender] = useState(false);
    const history = useHistory();
    const [form] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editingLearningAgreement, setEditingLearningAgreement] = useState<any>(null);


    const loadLearningAgreement = () => {
        AcademicMobilityCompetitionService.getAllLearningAgreements(id)
            .then((response) => {
                setLearningAgreement(response.data);
            })
            .catch((error) => console.error(error))
    };

    useEffect(() => {
        loadLearningAgreement();
    }, [shouldRerender]);

    const handleEditAgreement = (record: any) => {
        setEditingLearningAgreement(record);
        setIsModalOpen(true);
        form.setFieldsValue({
            name: record.name,
        });
    };

    const updateAgreement = (data: any) => {
        AcademicMobilityCompetitionService.updateAgreement(editingLearningAgreement.key, data.name)
            .then(() => {
                successNotification("Learning agreement successfully updated!");
                loadLearningAgreement();
                setIsModalOpen(false);
                setEditingLearningAgreement(null);
            })
            .catch((error) => console.error(error));
    };
    const AddAgreement = (data: any) => {

        AcademicMobilityCompetitionService.addAgreement(id, data.name)
            .then(() => {
                successNotification("Learning Agreement successfully added!");
                setShouldRerender(!shouldRerender);
                setIsModalOpen(false);
            })
            .catch((error) => console.error(error));

    }
    const onDeleteLearningAgreement = (learningAgreementId: number) => {
        AcademicMobilityCompetitionService.deleteAgreement(learningAgreementId)
            .then(() => setShouldRerender(!shouldRerender))
            .catch((error) => console.error(error));
    };
    const sendForApproval = (learningAgreementId: number) => {
        AcademicMobilityCompetitionService.sendForApproval(learningAgreementId)
            .then(() => setShouldRerender(!shouldRerender))
            .catch((error) => console.error(error));
    };
    const columnForeign = [
        {
            title: 'Created date',
            dataIndex: 'createdAt',
            key: 'createdAt',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Action',
            dataIndex: 'actions',
            key: 'actions',
            width: 120,
            render: (text: any, record: any) => (
                <Space size="middle">
                    <Button onClick={() => history.push(`/learning-agreement/aitu-discipline/${record.key}`)}>
                        View
                    </Button>
                    <Button onClick={() => handleEditAgreement(record)}>
                        <EditTwoTone />
                    </Button>
                    {record.sendForApproval == true ? <></> : <><Popconfirm
                        placement="topLeft"
                        title={'Delete a learning agreement?'}
                        onConfirm={() => onDeleteLearningAgreement(record.key)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button>
                            <DeleteTwoTone twoToneColor="#ff0000" />
                        </Button>
                    </Popconfirm><Popconfirm
                        placement="topLeft"
                        title={'Are you sure you want to submit for approval??'}
                        onConfirm={() => sendForApproval(record.key)}
                        okText="Yes"
                        cancelText="No"
                    >
                            <Button >
                                Send for approval
                            </Button></Popconfirm></>}

                </Space>
            ),
        },

    ]

    const dataSource = learningAgreement?.map((learningAgreement) => {
        return {
            key: learningAgreement.id,
            name: learningAgreement.name,
            createdAt: moment(learningAgreement.createdAt).format('YYYY-MM-DD'),
            sendForApproval: learningAgreement.sendForApproval
        };
    })

    return (
        <PageWrapper>
            <section>
                <header >
                    <Title level={4} >
                        Learning agreements
                    </Title>
                    <Button onClick={() => setIsModalOpen(true)} type='primary' icon={<EditOutlined />} style={{ marginRight: 20, marginBottom: 20 }} >Add learning agreement</Button>
                    <Modal
                        open={isModalOpen}
                        title={editingLearningAgreement ? "Edit Learning agreement name" : "Add Learning agreement name"}
                        onCancel={() => {
                            form.resetFields();
                            setIsModalOpen(false);
                            setEditingLearningAgreement(undefined)
                        }}
                        onOk={() => form.submit()}
                    >
                        <Form
                            form={form}
                            onFinish={editingLearningAgreement ? updateAgreement : AddAgreement}
                            initialValues={editingLearningAgreement || {}}
                        >
                            <Form.Item
                                label="Name"
                                name="name"
                                rules={[{ required: true, message: 'Please input name!' }]}
                            >
                                <Input placeholder="Write name" />
                            </Form.Item>
                        </Form>
                    </Modal>
                </header>
                <Table
                    dataSource={dataSource}
                    columns={columnForeign}
                />
            </section>
        </PageWrapper>
    )
}

export default LearningAgreementPage;