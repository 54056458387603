import { Typography, Form, Select, MenuProps } from 'antd';
import React, { useEffect, useState } from 'react';
import { RuleObject } from 'rc-field-form/lib/interface';
import { StoreValue } from 'rc-field-form/lib/interface';
import { errorNotification } from '../../../../helpers/errorNotification';
import { successNotification } from '../../../../helpers/successNotification';
import { ForeignUniversitiesDtoResponse } from '../../../../interfaces/ForeignUniversitiesDtoResponse';
import { FileService } from '../../../../services/file/FileService';
import { AcademicMobilityCompetitionService } from '../../../../services/AcademicMobilityCompetitionService';
import { useHistory } from 'react-router-dom';

const { Title } = Typography;
const { Option } = Select;

interface Props {

    studentCompetitionId?: number | undefined;
}



const useCollationEditPage = ({ studentCompetitionId }: Props) => {

    const [form] = Form.useForm();
    const [universities, setUniversities] = useState<ForeignUniversitiesDtoResponse[]>([]);
    const history = useHistory();
    const [fileList, setFileList] = useState<any[]>([]);
    const [fileListEng, setFileListEng] = useState<any[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [collationStatement, setCollationStatement] = useState<any>([]);
    const [shouldRerender, setShouldRerender] = useState(false);

    const styles = { width: '800px', margin: '0 auto' };


    const getCollationStatement = () => {
        AcademicMobilityCompetitionService.getCollationStatement(studentCompetitionId)

            .then((response) => {
                setCollationStatement(response.data);
            })
            .catch((error) => console.error(error))
    };

    useEffect(() => {
        getCollationStatement()
    }, [shouldRerender]);


    const handleFormValuesChange = (changedValues: any) => {
        const [fieldName] = Object.keys(changedValues);
        const value = changedValues[fieldName];
        if (fieldName === 'universityId' && typeof value === 'string') {
            const filteredUniversities = universities.filter(university =>
                university.nameEn.toLowerCase().includes(value.toLowerCase())
            );
        }
    };

    const onFinish = () => {
        const formData = new FormData();

        // Добавляем оригинальный документ, если он есть
        if (fileList.length > 0) {
            formData.append('originalTranscript', fileList[0].originFileObj);
        }

        // Добавляем английский документ, если он есть
        if (fileListEng.length > 0) {
            formData.append('engTranscript', fileListEng[0].originFileObj);
        }

        // Проверяем, обновляем или создаем записи
        if (collationStatement.originalTranscript || collationStatement.engTranscript) {
            AcademicMobilityCompetitionService.updateFiles(collationStatement.id, formData)
                .then(() => successNotification('Данные успешно обновлены'))
                .catch((err) => errorNotification('Не удалось обновить данные', err.response?.status));
        } else {
            AcademicMobilityCompetitionService.createFiles(studentCompetitionId, formData)
                .then(() => successNotification('Данные успешно сохранены'))
                .catch((err) => errorNotification('Не удалось сохранить данные', err.response?.status));
        }
        setIsModalOpen(false);
        setShouldRerender(!shouldRerender)

    };

    const handleFileChange = ({ file, fileList }: any) => {
        if (file.type !== 'application/pdf') {
            errorNotification('Неверный формат файла', 'Допускаются только PDF файлы');
            return;
        }
        // Оставляем только последний загруженный файл
        setFileList([fileList[fileList.length - 1]]);
    };

    const handleAituFileChange = ({ file, fileList }: any) => {
        if (file.type !== 'application/pdf') {
            errorNotification('Неверный формат файла', 'Допускаются только PDF файлы');
            return;
        }
        // Оставляем только последний загруженный файл
        setFileListEng([fileList[fileList.length - 1]]);
    };

    const handleDownloadFile = (trascriptId: number) => {
        FileService.getFileById(trascriptId, 'Collation statement')
            .then(() => successNotification("Collation Statement successfully downloaded!"))
            .catch((error) => console.error(error));

    };


    const validateUpload = (_: RuleObject, value: StoreValue) => {
        if ((!fileList || fileList.length === 0) && !collationStatement.originalTranscript) {
            return Promise.reject(new Error('Please upload a file'));
        }
        return Promise.resolve();
    };
    const handleEdit = () => {
        setIsModalOpen(true);

    };
    const items: MenuProps['items'] = [];

    const hasOriginalTranscript = !!collationStatement.originalTranscript;
    const hasEngTranscript = !!collationStatement.EngTranscript;

    if (hasOriginalTranscript || hasEngTranscript) {
        items.push(
            {
                label: <Typography.Text>View</Typography.Text>,
                key: '0',
                onClick: () => history.push(`/collation-statement/${collationStatement.id}`)
            },
            {
                label: <Typography.Text>Edit</Typography.Text>,
                key: '1',
                onClick: () => handleEdit()
            }
        );
    } else {
        items.push(
            {
                label: <Typography.Text>Create</Typography.Text>,
                key: '2',
                onClick: () => handleEdit()
            }
        );
    }
    return {
        form, isModalOpen, handleEdit, validateUpload, handleFileChange, fileList,
        handleDownloadFile, onFinish,
        handleFormValuesChange, setIsModalOpen, handleAituFileChange, fileListEng, collationStatement, items
    }

};

export default useCollationEditPage;
