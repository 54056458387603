import React, { useEffect, useState } from 'react';
import { Button, Card, Table, Typography, Modal, Form, Select, DatePicker, InputNumber } from 'antd';
import PageWrapper from '../../../ui/PageWrapper';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import { DisciplinesService } from '../../../services/DisciplinesService';
import { AcademicDegreeDtoResponse } from '../../../interfaces/AcademicDegreeDtoResponse';
import { GraduationThesisChooseSettingsDtoResponse } from './GraduationThesisChooseSettingsDtoResponse';
import { StudentGraduationThesisChooseSettingsService } from './StudentGraduationThesisChooseSettingsService';



const StudentGraduationThesisChooseSettings = () => {
    const [chooseSettings, setChooseSettings] = useState<GraduationThesisChooseSettingsDtoResponse[]>([]);
    const [loading, setLoading] = useState(true);
    const [editingRecord, setEditingRecord] = useState<GraduationThesisChooseSettingsDtoResponse | null>(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();
    const [degrees, setDegrees] = useState<AcademicDegreeDtoResponse[]>([]);

    useEffect(() => {
        const fetchDegrees = async () => {
            try {
                const response = await DisciplinesService.getAcademicDegrees();
                setDegrees(response.data);
            } catch (error) {
                console.error('Error fetching academic degrees:', error);
            }
        };
        fetchDegrees();
    }, []);

    useEffect(() => {
        const fetchSettings = async () => {
            try {
                const data = await StudentGraduationThesisChooseSettingsService.getAllCourseSettings();
                setChooseSettings(data);
                setLoading(false);
            } catch (error) {
                console.error(error);
            }
        };
        fetchSettings();
    }, []);

    const handleModalOk = async () => {
        try {
            const values = await form.validateFields();
            const { academicDegree, ...rest } = values;
            const finalValues = {
                ...rest,
                deadlineStartAt: values.deadlineStartAt.format("YYYY-MM-DD HH:mm:ss"),
                deadlineEndAt: values.deadlineEndAt.format("YYYY-MM-DD HH:mm:ss"),
            };
            const defenseYear = values.defenseYear;

            if (editingRecord) {
                await StudentGraduationThesisChooseSettingsService.updateChooseSetting(editingRecord.id, finalValues, defenseYear);
            } else {
                await StudentGraduationThesisChooseSettingsService.createChooseSetting(academicDegree.id, finalValues, defenseYear);
            }

            setIsModalVisible(false);
            form.resetFields();
            setLoading(true);
            const data = await StudentGraduationThesisChooseSettingsService.getAllCourseSettings();
            setChooseSettings(data);
            setLoading(false);
        } catch (error) {
            console.error('Error saving data', error);
        }
    };


    const openModal = (record: GraduationThesisChooseSettingsDtoResponse | null = null) => {
        setEditingRecord(record);
        setIsModalVisible(true);
        if (record) {
            form.setFieldsValue({
                ...record,
                deadlineStartAt: moment(record.deadlineStartAt),
                deadlineEndAt: moment(record.deadlineEndAt),
                defenseYear: record.defenseYear,
            });
        } else {
            form.resetFields();
        }
    };

    const handleModalCancel = () => {
        setIsModalVisible(false);
        form.resetFields();
    };

    const handleDelete = async (id: number) => {
        try {
            await StudentGraduationThesisChooseSettingsService.deleteChooseSetting(id);
            setLoading(true);
            const data = await StudentGraduationThesisChooseSettingsService.getAllCourseSettings();
            setChooseSettings(data);
            setLoading(false);
        } catch (error) {
            console.error('Error during deletion', error);
        }
    };

    const columns = [
        {
            title: 'Defense Year',
            dataIndex: 'defenseYear',
            key: 'defenseYear',
        },
        // {
        //     title: 'id',
        //     dataIndex: 'id',
        //     key: 'id',
        // },
        // {
        //     title: 'Degree (KZ)',
        //     dataIndex: ['degree', 'titleKz'],
        //     key: 'degree',
        // },
        {
            title: 'Academic Degree',
            dataIndex: ['academicDegree', 'titleEn'],
            key: 'academicDegree',
        },


        // {
        //     title: 'Degree (RU)',
        //     dataIndex: ['degree', 'titleEn'],
        //     key: 'degree',
        // },
        {
            title: 'Deadline Start',
            dataIndex: 'deadlineStartAt',
            key: 'deadlineStartAt',
            align: 'center' as const,
            render: (text: string) => moment(text).format('DD.MM.YYYY HH:mm:ss'),
        },
        {
            title: 'Deadline End',
            dataIndex: 'deadlineEndAt',
            key: 'deadlineEndAt',
            align: 'center' as const,
            render: (text: string) => moment(text).format('DD.MM.YYYY HH:mm:ss'),
        },
        // {
        //     title: 'departmentId',
        //     dataIndex: 'departmentId',
        //     key: 'departmentId',
        // },

        {
            title: 'Actions',
            key: 'actions',
            align: 'center' as const,
            render: (record: GraduationThesisChooseSettingsDtoResponse) => (
                <>
                    <Button icon={<EditOutlined />} onClick={() => openModal(record)} style={{ marginRight: 20 }}> Edit</Button>
                    <Button
                        icon={<DeleteOutlined />}
                        danger
                        // style={{ marginLeft: 0 }}
                        onClick={() => {
                            Modal.confirm({
                                title: 'Are you sure you want to delete this setting?',
                                onOk: () => handleDelete(record.id),
                            });
                        }}
                    />
                </>
            ),
        },
    ];
    return (
        <PageWrapper>
            <Typography.Title level={1}>Student Graduation Thesis Choose Settings</Typography.Title>
            <Card>
                <Button type="primary" icon={<PlusOutlined />} onClick={() => openModal()}>Add Setting</Button>
                <Table
                    columns={columns}
                    dataSource={chooseSettings}
                    loading={loading}
                    rowKey="id"
                    style={{ marginTop: 30 }}
                />
            </Card>
            <Modal
                title={editingRecord ? 'Edit Suggestion' : 'Add Suggestion'}
                open={isModalVisible}
                onOk={handleModalOk}
                onCancel={handleModalCancel}
            >
                <Form form={form} layout="vertical">
                    <Form.Item
                        name={['academicDegree', 'id']}
                        label="Academic Degree"
                        rules={[{ required: true, message: 'Please select an academic degree!' }]}
                    >
                        <Select
                            placeholder="Select degree"
                            options={degrees.map(academicDegree => ({
                                value: academicDegree.id,
                                label: academicDegree.titleEn,
                            }))}
                            style={{ width: '100%' }}
                            disabled={!!editingRecord}
                        />
                    </Form.Item>

                    <Form.Item
                        name="defenseYear"
                        label="Defense Year"
                        rules={[{ required: true, message: 'Please input the defense year!' }]}
                    >
                        <InputNumber placeholder="Input defense year" min={2019} max={2100} style={{ width: '100%' }} />
                    </Form.Item>

                    <Form.Item
                        name="deadlineStartAt"
                        label="Deadline Start"
                        rules={[{ required: true, message: 'Please select the start deadline!' }]}
                    >
                        <DatePicker style={{ width: '100%' }} showTime={{ format: 'HH:mm:ss' }} format="YYYY-MM-DD HH:mm:ss" />
                    </Form.Item>
                    <Form.Item
                        name="deadlineEndAt"
                        label="Deadline End"
                        rules={[{ required: true, message: 'Please select the end deadline!' }]}
                    >
                        <DatePicker style={{ width: '100%' }} showTime={{ format: 'HH:mm:ss' }} format="YYYY-MM-DD HH:mm:ss" />
                    </Form.Item>
                </Form>
            </Modal>
        </PageWrapper>
    );
};

export default StudentGraduationThesisChooseSettings;
