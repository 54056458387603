import authAxios from "../../../common/authAxios";
import { errorNotification } from "../../../helpers/errorNotification";

export class DormApplicationService {
    private static baseUrl = '/astanait-dorm-module/api/v1/manager';

    static async getApplications(filters: {
        status?: number;
        search?: string;
        privilege?: number;
        gender?: number;
        applicationYear?: number;
    }, pageable: { page: number }) {
        try {
            const response = await authAxios.get(`${this.baseUrl}/applications`, {
                params: {
                    ...filters,
                    page: pageable.page,
                },
            });
            return response.data;
        } catch (error) {
            errorNotification(String(error));
            throw error;
        }
    }

    static updateApplicationStatus(id: number, statusId: number) {
        return authAxios.put(`${this.baseUrl}/application/${id}/status?statusId=${statusId}`);
    }

    static updateApplicationComment(id: number, comment: string) {
        return authAxios.put(`${this.baseUrl}/application/${id}/comment?comment=${comment}`);
    }

    static async getPrivileges() {
        try {
            const response = await authAxios.get(`${this.baseUrl}/privileges`);
            return response.data;
        } catch (error) {
            errorNotification(String(error));
            throw error;
        }
    }

    static async getYears() {
        try {
            const response = await authAxios.get(`${this.baseUrl}/years`);
            return response.data;
        } catch (error) {
            errorNotification(String(error));
            throw error;
        }
    }

    static async getSelectionSetting() {
        try {
            const response = await authAxios.get(`${this.baseUrl}/setting`);
            return response.data;
        } catch (error) {
            errorNotification(String(error));
            throw error;
        }
    }


    static async changeSelectionSetting(id: number, selectionSettingDto: {
        placesGirl: number;
        placeBoys: number;
        startDate: string;
        endDate: string;
    }) {
        try {
            const response = await authAxios.put(`${this.baseUrl}/setting/${id}`, selectionSettingDto);
            return response.data;
        } catch (error) {
            errorNotification(String(error));
            throw error;
        }
    }

    static async getContracts(pageable: { page: number }) {
        try {
            const response = await authAxios.get(`/astanait-dorm-module/api/v1/manager/contracts`,
                {
                    params: {
                        page: pageable.page,
                    }
                }
            );

            return response.data;
        } catch (error) {
            errorNotification(String(error));
            throw error;
        }
    }

    static changeContractStatus(id: number, approveDtoRequest: {
        approved: boolean;
        comment: string;
    }) {
        return authAxios.put(`/astanait-dorm-module/api/v1/manager/contract/${id}/status`, approveDtoRequest);
    }

    static downloadPaymentCheck(id: number) {
        return authAxios.get(`/astanait-dorm-module/api/v1/manager/contract/${id}/payment-check`, {
            responseType: 'blob',
        });
    }

    static createContract(userId: number) {
        return authAxios.post(`/astanait-dorm-module/api/v1/manager/contract/create?userId=${userId}`);
    }

}
