import authAxios from '../common/authAxios';
import { CommissionMembersDtoResponse } from '../interfaces/CommissionMembersDtoResponse';
import { DiplomaWorkDtoResponse } from '../interfaces/Diploma/response/DiplomaWorkDtoResponse';
import { ProtocolDtoResponse } from '../interfaces/ProtocolDtoResponse';
import { ReviewerDtoResponse } from '../interfaces/ReviewerDtoResponse';
import { downloadFile } from '../helpers/downloadFile';
import { DiplomaWorkFilesDtoResponse } from '../interfaces/Diploma/response/DiplomaWorkFilesDtoResponse';
import { DiplomaWorkDtoRequest } from '../interfaces/Diploma/request/DiplomaWorkDtoRequest';
import { DiplomaWorkFileTypesDtoResponse } from '../interfaces/Diploma/response/DiplomaWorkFileTypesDtoResponse';
import { GraduationThesisDto, StudentGraduationThesisChooseDto, StudentGraduationThesisChooseSettingDto } from '../interfaces/Student/StudentGraduationThesisChooseDto';
import { ApproveDtoRequest } from '../interfaces/ApproveDtoRequest';

export class DiplomaService {
  private static getDiplomaSupplementBase(userId: number | undefined, diplomaWorkId: number | undefined, url: string) {
    return downloadFile(url, 'GET', 'blob', `${userId}`, 'pdf');
  }

  static getDiplomaSupplementBothLanguages(userId: number | undefined, diplomaWorkId: number | undefined) {
    return this.getDiplomaSupplementBase(
      userId,
      diplomaWorkId,
      `/astanait-diploma-work-module/api/v1/diploma/pdf/diploma-supplement?user-id=${userId}&diploma-work-id=${diplomaWorkId}`
    );
  }

  static getDiplomaSupplementKz(userId: number | undefined, diplomaWorkId: number | undefined) {
    return this.getDiplomaSupplementBase(
      userId,
      diplomaWorkId,
      `/astanait-diploma-work-module/api/v1/diploma/pdf/diploma-supplement/kz?user-id=${userId}&diploma-work-id=${diplomaWorkId}`
    );
  }

  static getDiplomaSupplementRu(userId: number | undefined, diplomaWorkId: number | undefined) {
    return this.getDiplomaSupplementBase(
      userId,
      diplomaWorkId,
      `/astanait-diploma-work-module/api/v1/diploma/pdf/diploma-supplement/ru?user-id=${userId}&diploma-work-id=${diplomaWorkId}`
    );
  }
  static getDiplomaEn(userId: number | undefined, diplomaWorkId: number | undefined) {
    return this.getDiplomaSupplementBase(
      userId,
      diplomaWorkId,
      `/astanait-diploma-work-module/api/v1/diploma/pdf/diploma-supplement/en-supplement?user-id=${userId}&diploma-work-id=${diplomaWorkId}`
    );
  }
  static getDiplomaSupplementEn(userId: number | undefined, diplomaWorkId: number | undefined) {
    return this.getDiplomaSupplementBase(
      userId,
      diplomaWorkId,
      `/astanait-diploma-work-module/api/v1/diploma/pdf/diploma-supplement/en?user-id=${userId}&diploma-work-id=${diplomaWorkId}`
    );
  }
  static getDiploma(userId: number | undefined, diplomaWorkId: number | undefined) {
    return this.getDiplomaSupplementBase(
      userId,
      diplomaWorkId,
      `/astanait-diploma-work-module/api/v1/diploma/pdf/generate?user-id=${userId}`
    );
  }
  static getProtocolForm2(
    userId: number | undefined,
    diplomaWorkId: number | undefined,
    commissionNumber: number | undefined
  ) {
    return this.getDiplomaSupplementBase(
      userId,
      diplomaWorkId,
      `/astanait-diploma-work-module/api/v1/diploma/pdf/generate/protocol/form-2?user-id=${userId}&diploma-work-id=${diplomaWorkId}&commission-number=${commissionNumber}`
    );
  }
  static getProtocolForm1(
    userId: number | undefined,
    diplomaWorkId: number | undefined,
    commissionNumber: number | undefined
  ) {
    return this.getDiplomaSupplementBase(
      userId,
      diplomaWorkId,
      `/astanait-diploma-work-module/api/v1/diploma/pdf/generate/protocol/form-1?user-id=${userId}&diploma-work-id=${diplomaWorkId}&commission-number=${commissionNumber}`
    );
  }
  static getProtocolForm3(
    userId: number | undefined,
    diplomaWorkId: number | undefined,
    commissionNumber: number | undefined
  ) {
    return this.getDiplomaSupplementBase(
      userId,
      diplomaWorkId,
      `/astanait-diploma-work-module/api/v1/diploma/pdf/generate/protocol/form-3?user-id=${userId}&diploma-work-id=${diplomaWorkId}&commission-number=${commissionNumber}`
    );
  }

  // Надо будет дополнить
  static getDiplomaWorksByStudentId(studentId: number) {
    return authAxios.get<DiplomaWorkDtoResponse[]>(
      `/astanait-diploma-work-module/api/v1/diploma/diploma-work-info?student-id=${studentId}`
    );
  }
  static getReviewerByDiplomaWorkId(diplomaWorkId: number) {
    return authAxios.get<ReviewerDtoResponse>(
      `/astanait-diploma-work-module/api/v1/diploma/reviewer-info?diploma-work-id=${diplomaWorkId}`
    );
  }
  static getProtocolInfo(diplomaWorkId: number, studentId: number | undefined) {
    return authAxios.get<ReviewerDtoResponse>(
      `/astanait-diploma-work-module/api/v1/diploma/diploma-protocol-info?student-id=${studentId}&diploma-work-id=${diplomaWorkId}`
    );
  }

  static getCommissionMember(commissionNumber: number | undefined, educationProgramId: number | undefined) {
    return authAxios.get<CommissionMembersDtoResponse[]>(
      `/astanait-diploma-work-module/api/v1/attestation-commission/all-members?education-program-id=${educationProgramId}&commission-number=${commissionNumber}`
    );
  }
  static addProtocolData(ProtocolData: ProtocolDtoResponse) {
    return authAxios.put(`/astanait-diploma-work-module/api/v1/diploma/update-protocol-info`, ProtocolData);
  }

  static getDiplomaWorkFileByWorkId(workId: number | undefined) {
    return authAxios.get<DiplomaWorkFilesDtoResponse[]>(`/astanait-diploma-work-module/api/v1/diploma/diploma-work-file/${workId}`)
  }

  static getDiplomaWorkFileTypes() {
    return authAxios.get<DiplomaWorkFileTypesDtoResponse[]>(`/astanait-diploma-work-module/api/v1/diploma/diploma-work-file-types`)
  }

  static uploadFileForDiploma(diplomaWorkId: number | undefined, diplomaWorkFiles: DiplomaWorkDtoRequest[]) {
    const formData = new FormData();
    diplomaWorkFiles.forEach((diplomaWorkFile, index) => {
      diplomaWorkFile.id && formData.append(`diplomaWorkFiles[${index}].id`, diplomaWorkFile.id.toString());
      formData.append(`diplomaWorkFiles[${index}].file`, diplomaWorkFile.file);
      formData.append(`diplomaWorkFiles[${index}].diplomaWorkFileType`, diplomaWorkFile.diplomaWorkFileType.toString());
      formData.append(`diplomaWorkFiles[${index}].name`, diplomaWorkFile.name);
    });

    return authAxios.put(`/astanait-diploma-work-module/api/v1/diploma/upload/${diplomaWorkId}`, formData)
  }
  //Graduation thesis
  static getGraduationThesisForOffice(settingId: string) {
    return authAxios.get<GraduationThesisDto[]>(`/astanait-diploma-work-module/api/v1/graduation-thesis/office-principal?setting_id=${settingId}`);
  }

  static getGraduationThesisForTeacher() {
    return authAxios.get<StudentGraduationThesisChooseDto[]>(`/astanait-diploma-work-module/api/v1/student-graduation-thesis-choose/teacher-principal`);
  }

  static approveGraduationThesis(thesisId: number, approveDto: ApproveDtoRequest) {
    return authAxios.put(`/astanait-diploma-work-module/api/v1/graduation-thesis/${thesisId}/approve`, approveDto);
  }

  static approveGraduationThesisByTeacher(thesisId: number, approveDto: ApproveDtoRequest) {
    return authAxios.put(`/astanait-diploma-work-module/api/v1/student-graduation-thesis-choose/${thesisId}/approve`, approveDto);
  }

  //Graduation thesis student
  static getGraduationThesisByStudent() {
    return authAxios.get<StudentGraduationThesisChooseDto>(`/astanait-diploma-work-module/api/v1/student-graduation-thesis-choose/student-principal`, {
      headers: {
        showNotification: false
      }
    });
  }

  static selectGraduationThesis(thesisId: number) {
    return authAxios.post(`/astanait-diploma-work-module/api/v1/student-graduation-thesis-choose/thesis/${thesisId}`);
  }

  static updateGraduationThesis(id: number, thesisId: number) {
    return authAxios.put(`/astanait-diploma-work-module/api/v1/student-graduation-thesis-choose/${id}/thesis/${thesisId}`);
  }

  static getAvailableThesisForStudent() {
    return authAxios.get<GraduationThesisDto[]>(`/astanait-diploma-work-module/api/v1/graduation-thesis/available-themes-for-student`);
  }

  //Graduation thesis settings
  static getGraduationThesisSetting() {
    return authAxios.get<StudentGraduationThesisChooseSettingDto>(`/astanait-diploma-work-module/api/v1/student-graduation-thesis-choose-setting/by-student-principal-and-current-defense-year`);
  }
}
