import authAxios from "../../../common/authAxios";
import { errorNotification } from "../../../helpers/errorNotification";
import { successNotification } from "../../../helpers/successNotification";
import { TeacherGraduationThesisDtoRequest } from "./TeacherGraduationThesisDtoRequest";
// import { TeacherDiplomaWorkSuggestionDtoRequest } from "./TeacherDiplomaWorkSuggestionDtoRequest";

export class TeacherGraduationThesisService {
    private static baseUrl = '/astanait-diploma-work-module/api/v1/graduation-thesis';


    static async hasAccessToSave() {
        return authAxios.get(`${this.baseUrl}/has-access-to-save`)
            .then(response => response.data)
            .catch((error) => {
                console.error('Error:', error);
                return false;
            });
    }

    static async getByTeacherPrincipal() {
        return authAxios.get(`${this.baseUrl}/teacher-principal`)
            .then((response) => response.data)
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    static async getByTeacherPrincipalAndSettingId(settingId: number) {
        return authAxios.get(`${this.baseUrl}/teacher-principal-by-setting`, {
            params: { setting_id: settingId },
        })
        .then((response) => response.data)
        .catch((error) => {
            console.error('Error:', error);
        });
    }


    static async createSuggestion(data: TeacherGraduationThesisDtoRequest) {
        return authAxios.post(`${this.baseUrl}`, data)
            .then(() => successNotification('Suggestion created successfully'))
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    static async updateSuggestion(id: number, data: TeacherGraduationThesisDtoRequest) {
        return authAxios.put(`${this.baseUrl}/${id}`, data)
            .then(() => successNotification('Suggestion updated successfully'))
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    static async deleteSuggestion(id: number) {
        return authAxios.delete(`${this.baseUrl}/${id}`)
            .then(() => successNotification('Suggestion deleted successfully'))
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    static async getThesisTypesByDegreeId(degreeId: number) {
        return authAxios.get(`${this.baseUrl}/type/degree/${degreeId}`)
            .then((response) => response.data)
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    static async getEducationProgramsByDefenseYearAndDegree(defenseYear: number, academicDegreeId: number) {
        return authAxios.get('/astanait-office-module/api/v1/academic-department/education-program/get-all-education-programs-by-defense-year-and-academic-degree', {
            params: { defense_year: defenseYear, academic_degree_id: academicDegreeId },
        })
            .then(response => response.data)
            .catch(error => {
                console.error('Error:', error);
            });
    }

    static async getSettingByTeacherPrincipalAndCurrentDefenseYear() {
        return authAxios.get(`/astanait-diploma-work-module/api/v1/graduation-thesis-setting/by-teacher-principal-and-current-defense-year`)
            .then((response) => response.data)
            .catch((error) => {
                errorNotification('Error fetching graduation thesis setting', error.response?.status);
                console.error('Error:', error);
            });
    }

    static sendForApproval(thesisId: number) {
        return authAxios.put(`/astanait-diploma-work-module/api/v1/graduation-thesis/${thesisId}/send-for-approval`)
    }

    static async getDetailsByTeacherPrincipal() {
        return authAxios.get(`/astanait-diploma-work-module/api/v1/graduation-thesis-setting/details/teacher-principal`)
            .then((response) => response.data)
            .catch((error) => {
                errorNotification('Error fetching graduation thesis details', error.response?.status);
                console.error('Error:', error);
            });
    }
    
    static async getDetailsBySettingId(id: number) {
        return authAxios.get(`/astanait-diploma-work-module/api/v1/graduation-thesis-setting/details/${id}`)
        .then((response) => response.data)
        .catch((error) => {
            console.error('Error:', error);
            errorNotification("Failed to fetch detail by ID");
        });
    }
}
