import authAxios from "../common/authAxios";
import { AvailableAppointmentDatesResponse } from "../interfaces/deansOffice/AvailableAppointmentDatesResponse";
import { ReceptionAppointmentDtoResponse } from "../interfaces/deansOffice/ReceptionAppointmentDtoResponse";
import { ReceptionAppointmentDtoRequest } from "../interfaces/deansOffice/ReceptionAppointmentDtoRequest";
import { ReceptionPlacesDtoResponse } from "../interfaces/deansOffice/ReceptionPlacesDtoResponse";

export class ReceptionAppointmentService {
    static getPlaces() {
        return authAxios.get<any[]>(
            `/astanait-office-module/api/v1/office-reception/place`
        );
    }
    static getPlacesForOffice() {
        return authAxios.get<any[]>(
            `/astanait-office-module/api/v1/office-reception/place/office-principal`
        );
    }
    static getReferral(place_id: number | undefined) {
        return authAxios.get<any[]>(
            `/astanait-office-module/api/v1/office-reception/referral?place_id=${place_id}`
        );
    }
    static getReferralForOffice(place_id: number | undefined) {
        return authAxios.get<any[]>(
            `/astanait-office-module/api/v1//office-reception/referral/office-principal?place_id=${place_id}`
        );
    }
    static getAvailableDateTimes(referral_id: number | undefined, date: Date) {
        return authAxios.get<any[]>(
            `/astanait-office-module/api/v1/office-reception/referral/${referral_id}/available-appointment-date-times?date=${date}`
        );
    }
    static getAvailableDates(referral_id: number | undefined,) {
        return authAxios.get<AvailableAppointmentDatesResponse[]>(
            `/astanait-office-module/api/v1/office-reception/referral/${referral_id}/available-appointment-dates`
        );
    }
    static createReceptionAppointment(appointment: ReceptionAppointmentDtoRequest) {
        return authAxios.post(`/astanait-office-module/api/v1/office-reception/appointment`, appointment);
    }
    static getAllAppointments(referral_id: number | undefined, date: Date) {
        return authAxios.get<ReceptionAppointmentDtoResponse[]>(
            `/astanait-office-module/api/v1/office-reception/appointment/office-principal?localDate=${date}&referralId=${referral_id}`
        );
    }
    static getAppointmentsByStudent() {
        return authAxios.get<ReceptionAppointmentDtoResponse[]>(
            `/astanait-office-module/api/v1/office-reception/appointment/student-principal`
        );
    }
    static deleteAppointment(appointmentId: number) {
        return authAxios.delete(`/astanait-office-module/api/v1/office-reception/appointment/${appointmentId}`);
    }








}