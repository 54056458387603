import React, { useEffect, useState } from 'react';
import { Button, Card, Form, Input, Modal, Select, Table, Tabs, Tooltip, Typography } from 'antd';
import PageWrapper from '../../../ui/PageWrapper';
import { errorNotification } from '../../../helpers/errorNotification';
import { DormApplicationService } from './DormApplicationsService';
import { successNotification } from '../../../helpers/successNotification';
import { CheckCircleOutlined, CloseCircleOutlined, EyeOutlined, PlusOutlined, RollbackOutlined, SendOutlined } from '@ant-design/icons';


interface Privilege {
    id: number;
    privilegeDescription: string;
}



const DormApplications = () => {
    const [applications, setApplications] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 20,
        total: 0,
    });
    const [currentStatus, setCurrentStatus] = useState(1);
    const [currentApplication, setCurrentApplication] = useState<any | null>(null);
    const [filters, setFilters] = useState({
        applicationYear: undefined,
        gender: undefined,
        privilege: undefined,
        search: undefined,
    });
    const [privileges, setPrivileges] = useState<Privilege[]>([]);
    const [years, setYears] = useState([]);
    const [viewCommentModalVisible, setViewCommentModalVisible] = useState(false);
    const [currentComment, setCurrentComment] = useState<string | null>(null);


    const handleViewComment = (application: any, comment: string) => {
        setCurrentComment(comment);
        setViewCommentModalVisible(true);
        setCurrentApplication(application);
    };


    useEffect(() => {
        const fetchFiltersData = async () => {
            try {
                const [privilegesData, yearsData] = await Promise.all([
                    DormApplicationService.getPrivileges(),
                    DormApplicationService.getYears(),
                ]);
                setPrivileges(privilegesData);
                setYears(yearsData);
            } catch (error) {
                errorNotification('Failed to load filter data');
            }
        };

        fetchFiltersData();
    }, []);

    useEffect(() => {
        fetchApplications(pagination.current - 1, currentStatus);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination.current, currentStatus]);

    const fetchApplications = async (page: number, status: number) => {
        try {
            setLoading(true);
            const data = await DormApplicationService.getApplications({ ...filters, status }, { page });
            setApplications(data.content);
            setPagination({
                ...pagination,
                total: data.totalElements,
            });
        } catch (error) {
            errorNotification(String(error));
        } finally {
            setLoading(false);
        }
    };
    const handleFilterChange = (changedValues: any) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            ...changedValues,
        }));
    };

    useEffect(() => {
        fetchApplications(0, currentStatus);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);



    const renderFilters = () => (
        <Form
            layout="inline"
            initialValues={filters}
            onValuesChange={handleFilterChange}
        >
            <Form.Item name="applicationYear" label="Application year">
                <Select placeholder="Select year" allowClear>
                    {years.map((year) => (
                        <Select.Option key={year} value={year}>
                            {year}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item name="gender" label="Gender">
                <Select placeholder="Select gender" allowClear>
                    <Select.Option value={1}>Male</Select.Option>
                    <Select.Option value={2}>Female</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item name="privilege" label="Privilege">
                <Select placeholder="Select privilege" allowClear
                    style={{ maxWidth: '300px' }}
                    dropdownStyle={{ minWidth: '600px' }}
                >
                    {privileges.map((privilege) => (
                        <Select.Option key={privilege.id} value={privilege.id}>
                            {privilege.privilegeDescription}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item name="search" label="Full name">
                <Input placeholder="Enter name" />
            </Form.Item>
        </Form>
    );


    const handleTableChange = (pagination: any) => {
        setPagination({
            ...pagination,
            current: pagination.current,
        });
    };

    const handleTabChange = (key: string) => {
        const status = parseInt(key, 10);
        setCurrentStatus(status);
        setPagination({ ...pagination, current: 1 });
    };

    const handleAction = (application: any, action: string) => {
        DormApplicationService.updateApplicationStatus(application.id,
            getStatusId(action, currentStatus))
            .then(() => {
                successNotification('Operation successful');
                fetchApplications(pagination.current - 1, currentStatus);
            })
    };

    const handleModalOk = async () => {
        DormApplicationService.updateApplicationComment(currentApplication.id, currentComment || '')
            .then(() => {
                successNotification('Comment added successfully');
                setViewCommentModalVisible(false);
                fetchApplications(pagination.current - 1, currentStatus);
            })
    };

    const getStatusId = (action: string, status: number) => {
        if (status === 1) {
            return action === 'accept' ? 2 : 6;
        }
        if (status === 2) {
            return action === 'accept' ? 4 : action === 'return' ? 1 : 6;
        }
        if (status === 4) {
            return action === 'return' ? 2 : 6;
        }
        if (status === 6) {
            return action === 'return' ? 1 : 6;
        }
        return status;
    };

    const columns = [
        {
            title: 'Full Name',
            dataIndex: 'fullName',
            key: 'fullName',
            width: '15%',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            width: '15%',
        },
        {
            title: 'Phone Number',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
            width: '10%',
        },
        {
            title: 'Nonresident',
            dataIndex: 'isAstana',
            key: 'isAstana',
            render: (text: boolean) => (text ? 'No' : 'Yes'),
            width: '5%',
        },
        {
            title: 'Privelege',
            dataIndex: ['privelege', 'id'],
            key: 'privelege',
            width: '5%',
        },
        // {
        //     title: 'Status',
        //     dataIndex: ['status', 'statusDescription'],
        //     key: 'status',
        // },

        {
            title: 'Nonresident',
            dataIndex: 'isAstana',
            key: 'isAstana',
            render: (text: boolean) => (text ? 'No' : 'Yes'),
            width: '5%',
        },
        {
            title: 'Сomment',
            dataIndex: 'comment',
            key: 'comment',
            render: (text: string, record: any) => {
                return (
                    <Button
                        onClick={() => handleViewComment(record, record.comment)}
                        icon={record.comment ? <EyeOutlined /> : <PlusOutlined />}
                    >
                        {record.comment ? 'View comment' : 'Add comment'}
                    </Button>
                );
            },
            width: '20%',
        },
        {
            title: 'Actions',
            key: 'actions',
            width: '20%',
            render: (text: any, record: any) => {
                if (currentStatus === 1) {
                    return (
                        <Select
                            placeholder="Select the action"
                            style={{ width: "100%" }}
                            onSelect={(value) => handleAction(record, value as string)}
                        >
                            <Select.Option key={1} value="accept">
                                <SendOutlined /> Move to Second Stage
                            </Select.Option>
                            <Select.Option key={2} value="decline">
                                <CloseCircleOutlined /> Decline
                            </Select.Option>
                        </Select>
                    );
                }
                if (currentStatus === 2) {
                    return (
                        <Select
                            placeholder="Select the action"
                            style={{ width: "100%" }}
                            onSelect={(value) => handleAction(record, value as string)}
                        >
                            <Select.Option key={1} value="return">
                                <RollbackOutlined /> Return to First Stage
                            </Select.Option>
                            <Select.Option key={2} value="accept">
                                <CheckCircleOutlined /> Accept
                            </Select.Option>
                            <Select.Option key={3} value="decline">
                                <CloseCircleOutlined />  Decline
                            </Select.Option>
                        </Select>
                    );
                }
                if (currentStatus === 4) {
                    return (
                        <Select
                            placeholder="Select the action"
                            style={{ width: "100%" }}
                            onSelect={(value) => handleAction(record, value as string)}
                        >
                            <Select.Option key={1} value="return">
                                <RollbackOutlined /> Return to Second Stage
                            </Select.Option>
                            <Select.Option key={2} value="decline">
                                <CloseCircleOutlined /> Decline
                            </Select.Option>
                        </Select>
                    );
                }
                if (currentStatus === 6) {
                    return (
                        <Tooltip title="Return to First Stage">
                            <Button
                                type="primary"
                                onClick={() => handleAction(record, 'return')}
                            >
                                <RollbackOutlined /> Return to First Stage
                            </Button>
                        </Tooltip>
                    );
                }
                return null;
            },
        },
    ];

    const renderTable = () => (
        <Table
            dataSource={applications}
            columns={columns}
            loading={loading}
            pagination={{
                current: pagination.current,
                pageSize: pagination.pageSize,
                total: pagination.total,
                showSizeChanger: false,
            }}
            onChange={handleTableChange}
            rowKey="id"
        />
    );


    return (
        <PageWrapper>
            <Typography.Title level={1}>Dormitory Applications</Typography.Title>
            <Card>
                <Typography.Title level={3}>Search Filter</Typography.Title>
                {renderFilters()}
            </Card>
            <Tabs defaultActiveKey="1" onChange={handleTabChange}>
                <Tabs.TabPane key="1" tab="First stage">
                    {renderTable()}
                </Tabs.TabPane>
                <Tabs.TabPane key="2" tab="Second stage">
                    {renderTable()}
                </Tabs.TabPane>
                <Tabs.TabPane key="4" tab="Accepted applications">
                    {renderTable()}
                </Tabs.TabPane>
                <Tabs.TabPane key="6" tab="Declined applications">
                    {renderTable()}
                </Tabs.TabPane>
            </Tabs>
            <Modal
                title="Comment"
                open={viewCommentModalVisible}
                onCancel={() => setViewCommentModalVisible(false)}
                onOk={handleModalOk}
                destroyOnClose
            >
                <Input.TextArea rows={4} value={currentComment || ''} onChange={e => setCurrentComment(e.target.value)} maxLength={255} />
            </Modal>
        </PageWrapper>
    );
};

export default DormApplications;
