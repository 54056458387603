import { Tag } from "antd";

export const handleStatus = (status: boolean | undefined) => {
    switch (status) {
        case null:
            return <Tag color='yellow' > Waiting </Tag>;
        case true:
            return <Tag color='green' > Accepted </Tag>;
        case false:
            return <Tag color='red' > Rejected </Tag>;
    }
}
