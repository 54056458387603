import Page from '../../../../../ui/Page'
import { Descriptions, Form, Input, Select, Space, Table, Typography } from 'antd'
import useLearningAgreement from './useLearningAgreement';
import moment from 'moment';
import RowSpan from '../../../../../ui/RowSpan';
import { successNotification } from '../../../../../helpers/successNotification';
import { AcademicMobilityCompetitionService } from '../../../../../services/AcademicMobilityCompetitionService';
import BackButton from '../../../../../ui/BackButton';
import { handleStatus } from '../../../../../common/getStatusTag';
import CustomModalWithForm from '../../../../../ui/Modal/CustomModalForm';
import AppromentTable from '../../AppromentTable';

interface Props {
    id: number;
}

const renderTable = (columns: any, dataSource: any, title: string) => (
    <Space direction="vertical" style={{ width: '100%' }}>
        <Typography.Title level={5}>{title}</Typography.Title>
        <Table bordered columns={columns} dataSource={dataSource} />
    </Space>
);

const LearningAgreementDetailPage = ({ id }: Props) => {
    const {
        learningAgreement,
        learningAgreementApprove,
        educationalProgram,
        studentCompetition,
        foreignDisciplinesData,
        aituDisciplinesData,
        recreditPlanData,
        approvalSheet,
        columnForeign,
        columnAitu,
        columnRecredit,
        fetchLearningAgreements,
        fetchApprovalSheet,
        fetchLearningAgreementApprove,
    } = useLearningAgreement(id);

    const [form] = Form.useForm();

    const handleStatusChange = (values: any) => {
        AcademicMobilityCompetitionService.learningAgreementApprove(id, values)
            .then(() => {
                successNotification("Статус успешно изменен");
                fetchLearningAgreements();
                fetchApprovalSheet();
                fetchLearningAgreementApprove();
            });
    }

    return (
        <Page>
            <BackButton />
            {learningAgreementApprove?.approved === null && <RowSpan
                right={
                    <CustomModalWithForm
                        title='Approve / Reject'
                        btnTitle='Approve / Reject'
                        onOk={form.submit}
                        form={form}
                    >
                        <Form form={form} onFinish={handleStatusChange}>
                            <Form.Item name="approved" rules={[{ required: true }]}>
                                <Select placeholder="Select status">
                                    <Select.Option value="true">Подтвердить</Select.Option>
                                    <Select.Option value="false">Отклонить</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="comment"
                                dependencies={['approved']}
                                rules={[
                                    ({ getFieldValue }) => ({
                                        required: getFieldValue('approved') === 'false',
                                        message: 'Write a comment',
                                    }),
                                ]}
                            >
                                <Input.TextArea placeholder="Write a comment" style={{ height: '100px' }} />
                            </Form.Item>
                        </Form>
                    </CustomModalWithForm>
                }
            />}
            <Space direction='vertical' style={{ width: '100%', rowGap: '50px' }}>
                <Descriptions layout='horizontal' title="Information" column={1} bordered>
                    <Descriptions.Item label="Fullname">{learningAgreement?.student.fullNameKz}</Descriptions.Item>
                    <Descriptions.Item label="Course">{learningAgreement?.student.courseGrade}</Descriptions.Item>
                    <Descriptions.Item label="Group">{learningAgreement?.student.group.title}</Descriptions.Item>
                    <Descriptions.Item label="Educational Program">{educationalProgram?.titleEn}</Descriptions.Item>
                    <Descriptions.Item label="Mobility period">
                        <Space direction='vertical'>
                            <div>{'From: ' + moment(studentCompetition?.competition.studyStartAt).format('LL hh:mm')}</div>
                            <div>{'To: ' + moment(studentCompetition?.competition.studyEndAt).format('LL hh:mm')}</div>
                        </Space>
                    </Descriptions.Item>
                    <Descriptions.Item label="Mobility University">
                        {studentCompetition?.competition.university.nameEn}
                    </Descriptions.Item>
                    <Descriptions.Item label="Status">
                        {handleStatus(learningAgreementApprove?.approved)}
                    </Descriptions.Item>
                </Descriptions>
                {renderTable(columnForeign, foreignDisciplinesData, 'Foreign Disciplines')}
                {renderTable(columnAitu, aituDisciplinesData, 'AITU Disciplines')}
                {renderTable(columnRecredit, recreditPlanData, 'Recredit Plan')}

                <AppromentTable dataSource={approvalSheet} />
            </Space>
        </Page>
    )
}

export default LearningAgreementDetailPage