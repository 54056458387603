import React from 'react'
import Page from '../../../../../ui/Page'
import { Button, Form, Input, Modal, Select, Space, Table } from 'antd'
import useFetchData from '../../../../../hooks/useFetchData'
import { LearningAgreementApproveDto } from '../../../../../interfaces/LearningAgreementDto'
import { AcademicMobilityCompetitionService } from '../../../../../services/AcademicMobilityCompetitionService'
import { EyeOutlined } from '@ant-design/icons'
import useModal from '../../../../../ui/Modal/useModal'
import { successNotification } from '../../../../../helpers/successNotification'
import { useHistory } from 'react-router-dom'

const LearningAgreementPage = () => {
    const { data: learningAgreements, fetchData: fetchLearningAgreements } = useFetchData<LearningAgreementApproveDto[]>(AcademicMobilityCompetitionService.getLearningAgreementForApproval, true);
    const { handleCancel, isModalOpen, showModal } = useModal();
    const [currentAgreement, setCurrentAgreement] = React.useState<LearningAgreementApproveDto | null>(null);
    const [form] = Form.useForm();
    const history = useHistory();

    const columns = [
        {
            title: "№",
            dataIndex: "key",
            key: "key"
        },
        {
            title: "Fullname",
            dataIndex: "fullname",
            key: "fullname"
        },
        {
            title: "Group",
            dataIndex: "group",
            key: "group"
        },
        {
            title: "Course",
            dataIndex: "course",
            key: "course"
        },
        {
            title: "University",
            dataIndex: "hostUniversityName",
            key: "hostUniversityName"
        },
        {
            key: "action",
            render: (text: any, record: any) => (
                <Space size={"middle"}>
                    <Button onClick={() => handleOpenModal(record)}>Подтвердить/Отклонить</Button>
                    <Button type="primary" icon={<EyeOutlined />} onClick={() => history.push(`/learning-agreement/${record.id}`)}>Просмотреть</Button>
                </Space>
            )
        }
    ];

    const handleOpenModal = (record: any) => {
        setCurrentAgreement(record);
        showModal();
    }

    const handleStatusChange = (values: any) => {
        currentAgreement && AcademicMobilityCompetitionService.learningAgreementApprove(currentAgreement.id, values)
            .then(() => {
                successNotification("Статус успешно изменен");
                handleCancel();
                fetchLearningAgreements();
            });
    }

    return (
        <Page>
            <Table
                bordered
                columns={columns}
                dataSource={learningAgreements?.map((learningAgreement, i) => ({
                    key: i + 1,
                    id: learningAgreement.id,
                    fullname: learningAgreement.student.fullNameKz,
                    group: learningAgreement.student.group.title,
                    course: learningAgreement.student.courseGrade,
                    hostUniversityName: learningAgreement.hostUniversityName,
                    learningAgreementId: learningAgreement.learningAgreementId
                }))}
            />
            <Modal
                title="Accept/Reject"
                open={isModalOpen}
                onOk={form.submit}
                onCancel={handleCancel}
            >
                <Form form={form} onFinish={handleStatusChange}>
                    <Form.Item name="approved" rules={[{ required: true }]}>
                        <Select placeholder="Select status">
                            <Select.Option value="true">Подтвердить</Select.Option>
                            <Select.Option value="false">Отклонить</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="comment"
                        dependencies={['approved']}
                        rules={[
                            ({ getFieldValue }) => ({
                                required: getFieldValue('approved') === 'false',
                                message: 'Write a comment',
                            }),
                        ]}
                    >
                        <Input.TextArea placeholder="Write a comment" style={{ height: '100px' }} />
                    </Form.Item>
                </Form>
            </Modal>
        </Page>
    )
}

export default LearningAgreementPage