import { Input, Table } from 'antd'
import React from 'react'
import { ApprovalSheetDto } from '../../../interfaces/AcademicMobilityCompetitionDto';
import { handleStatus } from '../../../common/getStatusTag';
import CustomModal from '../../../ui/Modal';
import { EyeOutlined } from '@ant-design/icons';

interface Props {
    dataSource?: ApprovalSheetDto[];
}


const AppromentTable = ({ dataSource }: Props) => {
    const approvalSheetColumn = [
        {
            title: 'Approver',
            dataIndex: 'approver',
            key: 'approver',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status: boolean | undefined) => handleStatus(status)
        },
        {
            title: 'Comment',
            dataIndex: 'comment',
            key: 'comment',
            render: (comment: string | undefined) => {
                return comment && <CustomModal title="Comment" btnTitle={"See comment"} btnIcon={<EyeOutlined />} onOk={() => { }} >
                    <Input.TextArea value={comment} />
                </CustomModal>
            }
        }
    ]

    return (
        <Table
            bordered
            columns={approvalSheetColumn}
            dataSource={dataSource?.map((approval) => ({
                key: approval.id,
                status: approval.approved,
                approver: approval.approver?.fullNameKz || "Unknown",
                comment: approval.comment
            }))}
        />
    )
}

export default AppromentTable