import React, { useEffect, useState } from 'react';
import { Button, Card, Form, Input, InputNumber, Modal, Select, Table } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { DeleteOutlined, EditOutlined, LeftOutlined, PlusOutlined } from '@ant-design/icons';
import PageWrapper from '../../../ui/PageWrapper';
import { DisciplinesService } from '../../../services/DisciplinesService';
import { AcademicDegreeDtoResponse } from '../../../interfaces/AcademicDegreeDtoResponse';
import { TeacherDtoResponse } from '../../../interfaces/Teacher/TeacherDtoResponse';
import { GraduationThesisSettingsService } from './GraduationThesisSettingsService';
import { GraduationThesisSettingDetailDtoResponse } from './GraduationThesisSettingDetailDtoResponse';


const GraduationThesisSettingsDetails = () => {
    const { settingId, departmentId } = useParams<{ settingId: string, departmentId: string }>();
    const [teachersSettings, setTeachersSettings] = useState<GraduationThesisSettingDetailDtoResponse[]>([]);
    const [teachers, setTeachers] = useState<TeacherDtoResponse[]>([]);
    const [editingRecord, setEditingRecord] = useState<GraduationThesisSettingDetailDtoResponse | null>(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [degrees, setDegrees] = useState<AcademicDegreeDtoResponse[]>([]);
    const [loading, setLoading] = useState(true);
    const [searchText, setSearchText] = useState('');
    const [form] = Form.useForm();
    const history = useHistory();

    useEffect(() => {
        const fetchTeachers = async () => {
            try {
                const data = await GraduationThesisSettingsService.getDetailsBySettingId(Number(settingId));
                setTeachersSettings(data);
            } catch (error) {
                console.error('Error fetching teachers:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchTeachers();
    }, [settingId]);

    const handleModalCancel = () => {
        setIsModalVisible(false);
        form.resetFields();
    };

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setSearchText(value.toLowerCase());
    };

    useEffect(() => {
        const fetchDegrees = async () => {
            try {
                const response = await DisciplinesService.getAcademicDegrees();
                setDegrees(response.data);
            } catch (error) {
                console.error('Error fetching academic degrees:', error);
            }
        };
        fetchDegrees();
    }, []);

    useEffect(() => {
        const fetchTeachersByDepartment = async () => {
            if (departmentId) {
                const teacherData = await GraduationThesisSettingsService.getAllTeachersByDepartmentId(Number(departmentId));
                setTeachers(teacherData);
            }
        };
        fetchTeachersByDepartment();
    }, [departmentId]);

    const handleModalOk = async () => {
        try {
            const values = await form.validateFields();
            const finalValues = {
                ...values,
                degreeId: values.academicDegree.id,
                settingId: Number(settingId),
                teacherId: values.teacher.id,
            };
            delete finalValues.academicDegree;
            delete finalValues.teacher;

            if (editingRecord) {
                await GraduationThesisSettingsService.updateGraduationThesisSettingDetail(editingRecord.id, finalValues);
            } else {
                await GraduationThesisSettingsService.createGraduationThesisSettingDetail(Number(settingId), finalValues);
            }
            setIsModalVisible(false);
            form.resetFields();
            setLoading(true);
            const data = await GraduationThesisSettingsService.getDetailsBySettingId(Number(settingId));
            setTeachersSettings(data);
            setLoading(false);
        } catch (error) {
            console.error('Error saving data', error);
        }
    };

    const openModal = (record: GraduationThesisSettingDetailDtoResponse | null = null) => {
        setEditingRecord(record);
        setIsModalVisible(true);
        if (record) {
            form.setFieldsValue({
                ...record,
            });
        } else {
            form.resetFields();
        }
    };

    const handleDelete = async (id: number) => {
        try {
            await GraduationThesisSettingsService.deleteGraduationThesisSettingDetail(id);
            setLoading(true);
            const data = await GraduationThesisSettingsService.getDetailsBySettingId(Number(settingId));
            setTeachersSettings(data);
            setLoading(false);
        } catch (error) {
            console.error('Error during deletion', error);
        }
    };

    const columns = [
        {
            title: (
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '20px' }}>
                    <span>Teacher Full Name</span>
                    <Input
                        placeholder="Search by Full Name"
                        onChange={handleSearch}
                        style={{ width: '200px', float: 'right', }}
                    />
                </div>
            ),
            dataIndex: 'teacher',
            key: 'teacherFullName',
            width: 400,
            render: (teacher: TeacherDtoResponse) =>
                `${teacher.nameKz} ${teacher.surnameKz} ${teacher.patronymicKz}`,
        },
        {
            title: 'Degree',
            dataIndex: ['academicDegree', 'titleEn'],
            key: 'academicDegree',
            render: (degreeTitle: string) => <span>{degreeTitle}</span>,
        },
        {
            title: 'Limit',
            dataIndex: 'limit',
            key: 'limit',
        },
        {
            title: 'Defense Year',
            dataIndex: ['setting', 'defenseYear'],
            key: 'defenseYear',
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (record: GraduationThesisSettingDetailDtoResponse) => (
                <>
                    <Button icon={<EditOutlined />}
                        onClick={() => openModal(record)} style={{ marginRight: 20 }}
                    > Edit</Button>
                    {/* <Button icon={<EyeOutlined />} onClick={() => viewTeacherList(record.id)}>View</Button> */}
                    <Button
                        icon={<DeleteOutlined />}
                        danger
                        // style={{ marginLeft: 0 }}
                        onClick={() => {
                            Modal.confirm({
                                title: 'Are you sure you want to delete this setting?',
                                onOk: () => handleDelete(record.id),
                            });
                        }}
                    />
                </>
            ),
        },
    ];

    const filteredData = teachersSettings.filter((record) => {
        const fullName = `${record.teacher.nameKz} ${record.teacher.surnameKz} ${record.teacher.patronymicKz}`;
        return fullName.toLowerCase().includes(searchText);
    });

    return (
        <PageWrapper>
            <Button onClick={() => history.goBack()} style={{ marginBottom: 20 }}><LeftOutlined /> Back</Button>
            {/*  <Typography.Title level={2}>Teachers for settingId: {settingId}, departmentId: {departmentId}</Typography.Title> */}
            <Card>
                <Button type="primary" icon={<PlusOutlined />}
                    onClick={() => openModal()}
                >
                    Add Teacher
                </Button>
                <Button
                    type="default"
                    style={{ marginLeft: 15 }}
                    onClick={() => history.push(`/office-diploma-approve/${settingId}`)}
                >
                    Suggestions
                </Button>

                <Table columns={columns} dataSource={filteredData} loading={loading} rowKey="id" style={{ marginTop: 20 }} scroll={{ x: '500px' }} />
            </Card>

            <Modal
                title={editingRecord ? 'Edit Suggestion' : 'Add Suggestion'}
                visible={isModalVisible}
                onOk={handleModalOk}
                onCancel={handleModalCancel}
            >
                <Form form={form} layout="vertical">
                    <Form.Item
                        name={['teacher', 'id']}
                        label="Teacher"
                        rules={[{ required: true, message: 'Please select a teacher!' }]}
                    >
                        <Select
                            showSearch
                            placeholder="Select a teacher"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            options={teachers.map((teacher) => ({
                                value: teacher.id,
                                label: `${teacher.nameKz} ${teacher.surnameKz} ${teacher.patronymicKz}`,
                            }))}
                            style={{ width: '100%' }}
                        />
                    </Form.Item>

                    <Form.Item
                        name={['academicDegree', 'id']}
                        label="Academic Degree"
                        rules={[{ required: true, message: 'Please select an academic degree!' }]}
                    >
                        <Select
                            placeholder="Select degree"
                            options={degrees.map((academicDegree) => ({
                                value: academicDegree.id,
                                label: academicDegree.titleEn,
                            }))}
                            style={{ width: '100%' }}
                        />
                    </Form.Item>
                    <Form.Item
                        name="limit"
                        label="limit"
                        rules={[{ required: true, message: 'Please input the limit!' }]}
                    >
                        <InputNumber style={{ width: '100%' }} />
                    </Form.Item>
                </Form>
            </Modal>
        </PageWrapper>
    );
};

export default GraduationThesisSettingsDetails;
