import { FinancingSourceDto } from "./FinancingSourceDto";
import { ForeignUniversitiesDtoResponse } from "./ForeignUniversitiesDtoResponse";
import { StudentDtoResponse } from "./Student/StudentDtoResponse";

export enum AcademicMobilityCompetitionStatusEnum {
    DRAFT = 'draft',
    OPEN = 'open',
    CHECKING = 'checking',
    COMMISSION = 'commission',
    CLOSED = 'closed'
}

export interface AcademicMobilityCompetitionStatusDto {
    id: number;
    name: string;
    nameEn: string;
    nameRu: string;
    prefix: string;
}

export interface AcademicMobilityCompetitionDtoRequest {
    id: number;
    universityId: number;
    deadline: Date;
    studyStartAt: Date;
    studyEndAt: Date;
    quota: number;
    statusId: number;
    studyStartTerm: number;
    studyEndTerm: number;
    financingSourceId: number;
}

export interface AcademicMobilityCompetitionDtoResponse {
    id: number;
    university: ForeignUniversitiesDtoResponse;
    deadline: Date;
    studyStartAt: Date;
    studyEndAt: Date;
    quota: number;
    status: AcademicMobilityCompetitionStatusDto;
    studyStartTerm: number;
    studyEndTerm: number;
    financingSource: FinancingSourceDto;
    createdAt: Date;
    updatedAt: Date;
}

export interface StudentAcademicMobilityCompetitionInfoDtoRequest {
    value: string;
    infoTypeId: number;
}

export interface StudentAcademicMobilityCompetitionInfoTypeDto extends AcademicMobilityCompetitionStatusDto {
    dataType: string;
    required: boolean;
    orderNumber: number;
    studentField: boolean;
}

export interface StudentAcademicMobilityCompetitionDto {
    id: number;
    student: StudentDtoResponse;
    competition: AcademicMobilityCompetitionDtoResponse;
    status: AcademicMobilityCompetitionStatusDto;
    comment: string;
    createdAt: Date;
    updatedAt: Date;
}

export interface StudentAcademicMobilityCompetitionInfoDto {
    id: number;
    type: StudentAcademicMobilityCompetitionInfoTypeDto;
    value: string;
    createdAt: Date;
    updatedAt: Date;
}

export interface StudentCompetitionLearningAgreementInfoDto {
    id: number;
    studentAcademicMobilityCompetitionId: number;
}
export interface foreignDisciplinesDto {
    id: number;
    code: string;
    ectsCredits: number;
    format: string;
    name: string;
    nameEn: string;
    nameRu: string;
    term: string;
    originalCredits: number,
}
export interface aituDisciplinesDto {
    id: number;
    format: string;
    curriculum: curriculumDto;

}
export interface recreditPlanDto {
    id: number;
    foreignDiscipline: foreignDisciplinesDto;
    aituDiscipline: aituDisciplinesDto;

}

export interface curriculumDto {
    id: number;
    numberOfTrimester: number;
    discipline: DisciplineDto;
    year: number;
}
export interface DisciplineDto {
    id: number;
    code: string;
    volumeCredits: number;
    titleKz: string;
    titleEn: string;
    titleRu: string;
}
export interface LearningAgreementDisciplinesDto {
    id: number;
    approved: boolean;
    sendForApproval: boolean;
    studentAcademicMobilityCompetitionId: number;
    createdAt: Date;
    updatedAt: Date;
    currentStep: number;
    iter: number;
    name: string;
    foreignDisciplines: foreignDisciplinesDto[];
    aituDisciplines: aituDisciplinesDto[];
    recreditPlanList: recreditPlanDto[];
    student: StudentDtoResponse;
}

export interface ForeignDisciplinesDtoRequest {
    name: string,
    nameEn: string,
    nameRu: string,
    code: string,
    term: string,
    originalCredits: number,
    ectsCredits: number,
    format: string
}
export interface CollationStatementGradeDto {
    id: number,
    foreignDiscipline: foreignDisciplinesDto,
    originalGrade: string,
    aituGrade: string,
}
export interface ApprovalSheetDto {
    id: number,
    approved: boolean,
    approver: ApproverDtoResponse,
    student: StudentDtoResponse,
    comment: string
}
export interface ApproverDtoResponse {
    id: number,
    fullNameKz: string
}
