import { Button, Form, Space } from 'antd';
import { EditTwoTone } from '@ant-design/icons';
import { AcademicMobilityCompetitionService } from '../../../../services/AcademicMobilityCompetitionService';
import { useEffect, useState } from 'react';
import { successNotification } from '../../../../helpers/successNotification';
import { FileService } from '../../../../services/file/FileService';
import { ApprovalSheetDto, CollationStatementGradeDto } from '../../../../interfaces/AcademicMobilityCompetitionDto';

interface Props {
    id: number
}
const useCollationPage = ({ id }: Props) => {
    const [collationStatement, setCollationStatement] = useState<any>([]);
    const [collationStatementGrade, setCollationStatementGrade] = useState<CollationStatementGradeDto[]>([]);
    const [collationApprovalSheet, setCollationApprovalSheet] = useState<ApprovalSheetDto[]>([]);
    const [shouldRerender, setShouldRerender] = useState(false);
    const [form] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editingGrade, setEditingGrade] = useState<any>(null);


    const getCollationStatement = () => {
        AcademicMobilityCompetitionService.getCollationStatementById(id)
            .then((response) => {
                setCollationStatement(response.data);
            })
            .catch((error) => console.error(error))
    };
    const getCollationStatementGrade = (collationStatementId: number) => {

        AcademicMobilityCompetitionService.getCollationStatementGrade(collationStatementId)
            .then((response) => {
                setCollationStatementGrade(response.data);
            })
            .catch((error) => console.error(error))
    };

    const getCollationApprovalSheet = (collationStatementId: number) => {
        AcademicMobilityCompetitionService.getCollationApprovalSheet(collationStatementId)
            .then((response) => {
                console.log(response.data)
                setCollationApprovalSheet(response.data);
            })
    };
    useEffect(() => {
        getCollationStatement();
    }, [shouldRerender]);

    const downloadStatement = (transcriptId: number) => {
        FileService.getFileById(transcriptId, 'Collation statement')
            .then(() => successNotification("Collation Statement successfully downloaded!"))
    };

    const handleEditGrade = (record: any) => {
        setIsModalOpen(true);
        setEditingGrade(record);
        form.setFieldsValue({
            originalGrade: record.originalGrade,
        });
    };

    const updateOriginalGrade = (data: Record<string, string>) => {
        AcademicMobilityCompetitionService.updateOriginalGrade(editingGrade.key, encodeURIComponent(data.originalGrade))
            .then(() => {
                successNotification("Original Grade successfully updated!");
                setIsModalOpen(false);
                setEditingGrade(null);
                setShouldRerender(!shouldRerender)
            })
    };



    const column = [
        {
            title: 'Discipline',
            dataIndex: 'discipline',
            key: 'discipline',
        },
        {
            title: 'Original Credits',
            dataIndex: 'originalCredits',
            key: 'originalCredits',
        },
        {
            title: 'ECTS Credits',
            dataIndex: 'ectsCredits',
            key: 'ectsCredits',
        },
        {
            title: 'Original Grade',
            dataIndex: 'originalGrade',
            key: 'originalGrade',
            render: (text: any, record: any) => (
                <Space size="middle">
                    <span>{record.originalGrade}</span>
                    <Button onClick={() => handleEditGrade(record)}>
                        <EditTwoTone />
                    </Button>
                </Space>
            ),
        },
        {
            title: 'Aitu Grade',
            dataIndex: 'aituGrade',
            key: 'aituGrade',
        },

    ]


    const dataSource = collationStatementGrade?.map((collationStatementGrade) => {
        return {
            key: collationStatementGrade.id,
            discipline: collationStatementGrade.foreignDiscipline.nameEn,
            originalCredits: collationStatementGrade.foreignDiscipline.originalCredits,
            ectsCredits: collationStatementGrade.foreignDiscipline.ectsCredits,
            originalGrade: collationStatementGrade.originalGrade,
            aituGrade: collationStatementGrade.aituGrade
        };
    })


    return {
        collationStatement,
        downloadStatement,
        column,
        dataSource,
        getCollationStatement,
        getCollationStatementGrade,
        isModalOpen,
        setIsModalOpen,
        form,
        updateOriginalGrade,
        editingGrade,
        setEditingGrade,
        shouldRerender,
        getCollationApprovalSheet,
        collationApprovalSheet
    };
}

export default useCollationPage;