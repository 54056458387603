import React from 'react'
import { GraduationThesisDto, StudentGraduationThesisChooseDto } from '../../../../interfaces/Student/StudentGraduationThesisChooseDto';
import { Button, Descriptions, Form, Input, Modal, Space, Switch, Table } from 'antd';
import useFetchData from '../../../../hooks/useFetchData';
import { DiplomaService } from '../../../../services/DiplomaService';
import { CheckCircleOutlined, CloseCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { successNotification } from '../../../../helpers/successNotification';
import CustomModal from '../../../../ui/Modal';
import moment from 'moment';

interface GroupedByThesis {
    thesis: GraduationThesisDto;
    students: StudentGraduationThesisChooseDto[];
}


const useTeacherDiplomaApprove = () => {
    const {
        data: graduationThesis,
        isLoading: isGraduationThesisLoading,
        fetchData: fetchGraduationThesis,
    } = useFetchData<StudentGraduationThesisChooseDto[]>(
        DiplomaService.getGraduationThesisForTeacher,
        true
    );

    const [modal, contextHolder] = Modal.useModal();
    const [form] = Form.useForm();
    const [checked, setChecked] = React.useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = React.useState<number[]>([]);


    const groupedByThesis: GroupedByThesis[] = React.useMemo(() => {
        const groupedByThesis: GroupedByThesis[] = [];

        graduationThesis?.forEach((student) => {
            const thesis = groupedByThesis.find((group) => group.thesis.id === student.thesis.id);
            if (thesis) {
                thesis.students.push(student);
            } else {
                groupedByThesis.push({
                    thesis: student.thesis,
                    students: [student]
                });
            }
        });
        return groupedByThesis;
    }, [graduationThesis]);

    const columns = [
        {
            title: 'Title (EN)',
            dataIndex: 'titleEn',
            key: 'diplomaWorkTitleEn',
            render: (text: any, record: GroupedByThesis) => record.thesis.titleEn
        },
        {
            title: 'Title (KZ)',
            dataIndex: 'titleKz',
            key: 'diplomaWorkTitleKz',
            render: (text: any, record: GroupedByThesis) => record.thesis.titleKz
        },
        {
            title: 'Title (RU)',
            dataIndex: 'titleRu',
            key: 'diplomaWorkTitleRu',
            render: (text: any, record: GroupedByThesis) => record.thesis.titleRu
        },
        {
            title: 'Degree',
            dataIndex: ['type', 'academicDegree', 'titleEn'],
            key: 'degree',
            width: "5%",
            render: (text: any, record: GroupedByThesis) => record.thesis.type.academicDegree.titleEn,
            filters: [
                ...Array.from(
                    new Set(groupedByThesis.map((item) => item.thesis.type.academicDegree.titleEn))
                ).map((degree) => ({
                    text: degree,
                    value: degree,
                })),
            ],
            onFilter: (value: any, record: any) => record.thesis.type.academicDegree.titleEn === value,
        },
        {
            title: 'Defense Year',
            dataIndex: 'defenseYear',
            key: 'defenseYear',
            width: "5%",
            render: (text: any, record: GroupedByThesis) => record.thesis.defenseYear,
            filters: [
                ...Array.from(
                    new Set(groupedByThesis.map((item) => item.thesis.defenseYear))
                ).map((year) => ({
                    text: year,
                    value: year,
                })),
            ],
            onFilter: (value: any, record: any) => record.thesis.defenseYear === value,
        },
        {
            title: 'Number of students',
            dataIndex: 'studentsNumber',
            key: 'studentsNumber',
            render: (text: any, record: GroupedByThesis) => record.students.length
        }
    ];

    const handleApprove = (thesisId: number, status: boolean) => {
        return modal.confirm({
            title: status === true ? 'Approve the Student' : 'Reject the Student',
            content: (
                <Form
                    onFinish={(e) =>
                        DiplomaService.approveGraduationThesisByTeacher(thesisId, {
                            comment: e.comment,
                            approved: status,
                        })
                            .then(() => {
                                successNotification('Student has been approved');
                                Modal.destroyAll();
                                form.resetFields();
                                fetchGraduationThesis();
                            })
                    }
                    layout='vertical'
                    form={form}
                >
                    <Form.Item
                        name="comment"
                        label="Comment"
                        rules={[{ required: status === true ? false : true, message: 'Please input your comment!' }]}
                    >
                        <Input.TextArea size='small' />
                    </Form.Item>
                </Form >
            ),
            onOk() {
                form.submit();
            }
        });
    }

    const handleApproveAll = (status: boolean) => {
        return modal.confirm({
            title: status === true ? 'Approve the Student' : 'Reject the Student',
            content: (
                <Form
                    onFinish={(e) =>
                        Promise.all(selectedRowKeys.map((id) => {
                            return DiplomaService.approveGraduationThesisByTeacher(id, {
                                comment: e.comment,
                                approved: status,
                            });
                        }))
                            .then(() => {
                                successNotification('Student has been approved');
                                Modal.destroyAll();
                                form.resetFields();
                                fetchGraduationThesis();
                                setSelectedRowKeys([]);
                            })
                    }
                    layout='vertical'
                    form={form}
                >
                    <Form.Item
                        name="comment"
                        label="Comment"
                        rules={[{ required: status === true ? false : true, message: 'Please input your comment!' }]}
                    >
                        <Input.TextArea size='small' />
                    </Form.Item>
                </Form >
            ),
            onOk() {
                form.submit();
            }
        });
    }



    const expandedRowRender = (record: StudentGraduationThesisChooseDto[]) => {
        if (record.length === 0) return null;

        const expandedColumns = [
            {
                title: "Full Name",
                dataIndex: ["student", "fullName"],
                key: "fullName",
            },
            {
                title: "Group",
                dataIndex: ["student", "group", "title"],
                key: "group",
            },
            {
                title: 'Action',
                key: 'action',
                hidden: true,
                render: (text: any, record: StudentGraduationThesisChooseDto) => record.approved !== null ? (
                    <Space size="middle">
                        <CustomModal
                            title="Comment"
                            onOk={() => { }}
                            btnTitle="View"
                            btnIcon={<InfoCircleOutlined />}
                        >
                            <Descriptions column={1} bordered>
                                {record.comment && <Descriptions.Item label="Comment">{record.comment}</Descriptions.Item>}
                                <Descriptions.Item label={record.approved === true ? "Approved At" : "Rejected At"}>{moment(record.approvedAt).format('LL hh:mm')}</Descriptions.Item>
                            </Descriptions>
                        </CustomModal>
                    </Space>
                ) : (
                    <Space size="middle">
                        <Button
                            type="primary"
                            onClick={() => handleApprove(record.id, true)}
                            icon={<CheckCircleOutlined />}
                            disabled={checked}
                        >
                            Approve
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => handleApprove(record.id, false)}
                            icon={<CloseCircleOutlined />}
                            danger
                            disabled={checked}
                        >
                            Reject
                        </Button>
                    </Space>
                ),
                width: "5%",
            }
        ];

        return (
            <Space direction="vertical" size={"large"} style={{ width: "100%" }}>
                <Switch onChange={() => setChecked(!checked)} checked={checked} checkedChildren="Multiple approve" unCheckedChildren="Single approve" />
                {checked &&
                    <Space>
                        <Button
                            type="primary"
                            icon={<CheckCircleOutlined />}
                            onClick={() => {
                                handleApproveAll(true);
                            }}
                            disabled={selectedRowKeys.length === 0}
                        >
                            Approve all
                        </Button>
                        <Button
                            type="primary"
                            icon={<CloseCircleOutlined />}
                            onClick={() => {
                                handleApproveAll(false);
                            }}
                            danger
                            disabled={selectedRowKeys.length === 0}
                        >
                            Reject all
                        </Button>
                    </Space>
                }
                {checked
                    ?
                    <Table
                        columns={expandedColumns}
                        dataSource={record}
                        pagination={false}
                        rowKey="id"
                        rowSelection={{
                            type: 'checkbox',
                            selectedRowKeys: selectedRowKeys,
                            onChange: (selectedRowKeys, selectedRows) => {
                                setSelectedRowKeys(selectedRows.map((row) => row.id));
                            },
                            getCheckboxProps: (record) => ({
                                disabled: (record.approved !== null),
                            }),
                        }}
                    />
                    :
                    <Table
                        columns={expandedColumns}
                        dataSource={record}
                        pagination={false}
                        rowKey="id"
                    />
                }
            </Space>
        );
    }

    return { graduationThesis, groupedByThesis, expandedRowRender, columns, isGraduationThesisLoading, fetchGraduationThesis, contextHolder }
}

export default useTeacherDiplomaApprove