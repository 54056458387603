import React from 'react';
import { Button, Modal, FormInstance } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

interface ModalProps {
    children: React.ReactNode;
    onOk: () => void;
    btnTitle: string;
    btnType?: "primary" | "default" | "dashed" | "link" | "text";
    title: string;
    footer?: React.ReactNode;
    btnIcon?: React.ReactNode;
    style?: React.CSSProperties;
    form?: FormInstance<any>;
}

const CustomModalWithForm: React.FC<ModalProps> = ({ children, onOk, btnTitle, btnType, title, footer, btnIcon, style, form }) => {
    const [open, setOpen] = React.useState<boolean>(false);

    const handleOk = async () => {
        try {
            if (form) {
                await form.validateFields();
            }
            onOk();
            setOpen(false);
        } catch (error) {
            // Validation failed, do not close the modal
        }
    };

    return (
        <>
            <Button type={btnType ? btnType : "primary"} onClick={() => setOpen(true)} icon={btnIcon ? btnIcon : <PlusOutlined />}>{btnTitle}</Button>
            <Modal
                open={open}
                style={style}
                centered={true}
                onCancel={() => setOpen(false)}
                onOk={handleOk}
                destroyOnClose={true}
                title={title}
                footer={footer}
            >
                {children}
            </Modal>
        </>
    );
};

export default CustomModalWithForm;