import React, { useState } from 'react'
import Page from '../../../../../ui/Page'
import useFetchData from '../../../../../hooks/useFetchData'
import { AcademicMobilityCompetitionService } from '../../../../../services/AcademicMobilityCompetitionService'
import { StudentAcademicMobilityCompetitionDto, StudentAcademicMobilityCompetitionInfoDto } from '../../../../../interfaces/AcademicMobilityCompetitionDto'
import { Button, Descriptions, Dropdown, Form, Input, List, MenuProps, Modal, Select, Space, Typography, Upload } from 'antd'
import MobilityStatusTag from '../MobilityStatusTag'
import { getFullDate } from '../../../../../common/getFullDate'
import { DownloadOutlined, DownOutlined, EditOutlined, UploadOutlined } from '@ant-design/icons'
import MobilityDataType from '../MobilityDataType'
import { FileService } from '../../../../../services/file/FileService'
import { successNotification } from '../../../../../helpers/successNotification'
import RowSpan from '../../../../../ui/RowSpan'
import grantPermission from '../../../../../helpers/grantPermission'
import useModal from '../../../../../ui/Modal/useModal'
import { isDeadlinePassed } from '../../../../../helpers/isDeadlinePassed'
import { useHistory } from 'react-router-dom';
import useCollationPage from '../../CollationPage/useCollationPage'
import { errorNotification } from '../../../../../helpers/errorNotification'
import useCollationEditPage from '../../CollationPage/useCollationEditPage'

interface Props {
    id: number
}

const handleRender = (item: StudentAcademicMobilityCompetitionInfoDto) => {
    switch (item.type.dataType) {
        case 'string':
            return (
                <Typography.Text>{item.value ?? "No data"}</Typography.Text>
            );
        case 'date':
            return (
                <Typography.Text>{getFullDate(item.value) ?? "No data"}</Typography.Text>
            );
        case 'decimal':
            return (
                <Typography.Text>{item.value ?? "No data"}</Typography.Text>
            );
        case 'file':
            return null;
        case "object":
            return (
                <Typography.Text>{JSON.parse(item.value)?.name ?? "No data"}</Typography.Text>
            );
        case "select_int_5":
            return (
                <Typography.Text>{item.value ?? "No data"}</Typography.Text>
            );
        default:
            return (
                <Typography.Text type={"secondary"}>{"No data"}</Typography.Text>
            );
    }
}

const StudentAcademicMobilityCompetitionDetailPage = ({ id }: Props) => {
    const history = useHistory();
    const isStudent = grantPermission('STUDENTS', 'baseRole');

    const callbackCompetitionInfo = React.useCallback(() => AcademicMobilityCompetitionService.getStudentCompetitionInfoById(id), [id]);
    const callbackStudentCompetition = React.useCallback(() => AcademicMobilityCompetitionService.getStudentCompetitionById(id), [id]);
    const callbackStatuses = React.useCallback(() => AcademicMobilityCompetitionService.getAvailableStatuses(id), [id]);
    const callbackDisciplines = React.useCallback(() => AcademicMobilityCompetitionService.getStudentCompetition(id), [id]);

    const { data: info, fetchData: fetchInfo } = useFetchData<StudentAcademicMobilityCompetitionInfoDto[]>(callbackCompetitionInfo, true);
    const { data: studentCompetition, fetchData: fetchCompetition } = useFetchData<StudentAcademicMobilityCompetitionDto>(callbackStudentCompetition, true);
    const { data: statuses, fetchData: fetchStatuses } = useFetchData(callbackStatuses, true);
    const { data: disciplines, fetchData: fetchDisciplines } = useFetchData(callbackDisciplines, true);
    //Modal for sign
    const { isModalOpen: isOpenModalSignModal, showModal: showModalSignModal, handleCancel: handleCancelSignModal } = useModal();

    // Modal for details
    const [open, setOpen] = React.useState(false);
    const [currentModal, setCurrentModal] = React.useState<StudentAcademicMobilityCompetitionInfoDto>();
    const [createForm] = Form.useForm();
    const [statusForm] = Form.useForm();

    const { form, isModalOpen, validateUpload, handleFileChange, fileList, fileListEng,
        handleDownloadFile, onFinish, handleFormValuesChange, handleEdit, setIsModalOpen, handleAituFileChange, items,
        collationStatement } = useCollationEditPage({ studentCompetitionId: id });

    const openModal = (record: StudentAcademicMobilityCompetitionInfoDto) => {
        setCurrentModal(record);
        createForm.setFieldsValue({ [record.type.id]: record.value })
        setOpen(true);
    }
    const closeModal = () => {
        setOpen(false);
        createForm.resetFields();
        setCurrentModal(undefined);
    }

    const handleStatus = (data: any) => {
        AcademicMobilityCompetitionService.updateStudentCompetitionStatus(id, data)
            .then(() => {
                fetchInfo();
                successNotification("Status successfully updated!");
            })
            .finally(() => {
                fetchCompetition();
                fetchInfo();
                fetchStatuses();
                handleCancelSignModal();
                fetchDisciplines();
            });
    }

    const handleEditBtn = (studentCompetition: StudentAcademicMobilityCompetitionDto, item: StudentAcademicMobilityCompetitionInfoDto) => {
        if (isStudent && item.type.studentField) {
            if ((studentCompetition?.status?.prefix === 'created' && !isDeadlinePassed(studentCompetition.competition.deadline, true)) || studentCompetition?.status?.prefix === 'revision') {
                return <Button icon={<EditOutlined />} onClick={() => openModal(item)} />
            }
        }
        else {
            if (studentCompetition?.status?.prefix === 'checking' && !item.type.studentField) {
                return <Button icon={<EditOutlined />} onClick={() => openModal(item)} />;
            }
        }

    }


    return (
        <Page title={`${studentCompetition?.student.fullNameEn}`} subtitle={`competition to "${studentCompetition?.competition.university.nameEn}"`}>
            <RowSpan
                right={
                    <>
                        <Dropdown menu={{ items }} trigger={['click']}>
                            <Button onClick={(e) => e.preventDefault()}>
                                <Space>
                                    Collation Statement
                                    <DownOutlined />
                                </Space>
                            </Button>
                        </Dropdown>
                        <Modal
                            open={isModalOpen}
                            title={collationStatement.originalTranscript || collationStatement.EngTranscript ? "Update Collation Statement" : "Create Collation Statement"}
                            onCancel={() => {
                                form.resetFields();
                                setIsModalOpen(false);
                            }}
                            onOk={() => form.submit()}
                        >
                            <Form
                                layout="vertical"
                                size="large"
                                form={form}
                                // style={styles}
                                onFinish={onFinish}
                                onValuesChange={handleFormValuesChange}
                            >
                                <Form.Item
                                    name="fileOriginal"
                                    label="Upload File"
                                    valuePropName="fileList"
                                    rules={[{ validator: validateUpload }]}
                                    getValueFromEvent={(e: any) => {
                                        if (Array.isArray(e)) {
                                            return e;
                                        }
                                        return e && e.fileList;
                                    }}
                                >
                                    <Upload
                                        name="fileOriginal"
                                        beforeUpload={() => false}
                                        onChange={handleFileChange}
                                        fileList={fileList}
                                        accept=".pdf"
                                    >
                                        <Button icon={<UploadOutlined />}>Загрузить файл</Button>
                                    </Upload>
                                    {collationStatement.originalTranscript && (
                                        <div>
                                            <Button type="primary" onClick={() => handleDownloadFile(collationStatement.originalTranscript)}>Скачать текущий файл</Button>
                                        </div>
                                    )}
                                </Form.Item>
                                <Form.Item
                                    name="fileEng"
                                    label="Upload File"
                                    valuePropName="fileListEng"
                                    rules={[{ validator: validateUpload }]}
                                    getValueFromEvent={(e: any) => {
                                        if (Array.isArray(e)) {
                                            return e;
                                        }
                                        return e && e.fileListEng;
                                    }}
                                >
                                    <Upload
                                        name="fileEng"
                                        beforeUpload={() => false}
                                        onChange={handleAituFileChange}
                                        fileList={fileListEng}
                                        accept=".pdf"
                                    >
                                        <Button icon={<UploadOutlined />}>Загрузить файл</Button>
                                    </Upload>
                                    {collationStatement.engTranscript && (
                                        <div>
                                            <Button type="primary" onClick={() => handleDownloadFile(collationStatement.engTranscript)}>Скачать текущий файл</Button>
                                        </div>
                                    )}
                                </Form.Item>
                            </Form>
                        </Modal>
                        <Button onClick={() => history.push(`/learning-agreement/student-competition/${id}`)} type='primary' icon={<EditOutlined />} style={{ marginRight: 20, marginLeft: 20 }}>Learning agreement</Button>


                        <Button onClick={showModalSignModal} hidden={statuses && statuses?.length <= 0} type='primary' icon={<EditOutlined />}>Change status</Button>
                        <Modal open={isOpenModalSignModal} title={"Change status"} onCancel={(handleCancelSignModal)} onOk={() => statusForm.submit()}>
                            <Form form={statusForm} onFinish={handleStatus}>
                                <Form.Item
                                    label="Status"
                                    name="statusId"
                                    rules={[{ required: true, message: 'Please choose status!' }]}>
                                    <Select placeholder="Choose status">
                                        {statuses?.map((status) => (
                                            <Select.Option key={status.id} value={status.id}>{`${status.nameEn}`}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label="Comment"
                                    name="comment"
                                >
                                    <Input.TextArea />
                                </Form.Item>
                            </Form>
                        </Modal>
                    </>
                }
            />
            <Descriptions title="General info" style={{ marginTop: 20 }}>
                <Descriptions.Item label="Application Status">
                    {studentCompetition?.status ? <MobilityStatusTag status={studentCompetition?.status} /> : 'N/A'}
                </Descriptions.Item>
                <Descriptions.Item label="Competition Status">
                    {studentCompetition?.competition?.status ? <MobilityStatusTag status={studentCompetition?.competition?.status} /> : 'N/A'}
                </Descriptions.Item>
                <Descriptions.Item label="Created date">{getFullDate(studentCompetition?.createdAt?.toString() || '')}</Descriptions.Item>
                <Descriptions.Item label="Deadline">{getFullDate(studentCompetition?.competition.deadline?.toString() || '')}</Descriptions.Item>
                <Descriptions.Item label="Study period">{
                    `${getFullDate(studentCompetition?.competition?.studyStartAt?.toString() || '')} / ${getFullDate(studentCompetition?.competition?.studyEndAt?.toString() || '')}`
                }</Descriptions.Item>
                <Descriptions.Item label="Quota">{studentCompetition?.competition?.quota}</Descriptions.Item>
                <Descriptions.Item label="Financing source">{studentCompetition?.competition?.financingSource.nameEn}</Descriptions.Item>
                <Descriptions.Item label="Study terms">{
                    studentCompetition?.competition?.studyStartTerm &&
                    studentCompetition?.competition?.studyEndTerm &&
                    `From term ${studentCompetition?.competition?.studyStartTerm} until term ${studentCompetition?.competition?.studyEndTerm}`}
                </Descriptions.Item>
                <Descriptions.Item label="Comment">{studentCompetition?.comment}</Descriptions.Item>
            </Descriptions>

            <List
                grid={{
                    gutter: 16,
                    xs: 1,
                    sm: 2,
                    md: 2,
                    lg: 3,
                    xl: 3,
                    xxl: 3,
                }}
                bordered
                header={<Typography.Title level={5}>Details</Typography.Title>}
                itemLayout='vertical'
                dataSource={
                    isStudent
                        ?
                        info?.filter((info) => info.type.studentField === true).sort((a, b) => a.type.orderNumber - b.type.orderNumber)
                        :
                        info?.sort((a, b) => a.type.orderNumber - b.type.orderNumber)
                }
                renderItem={(item) => (
                    <List.Item key={item.id} style={{ border: '1px solid rgba(0, 0, 0, 0.05)' }}>
                        <Space style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}>
                            <Space direction='vertical' size={"small"}>
                                <Typography.Text strong>{`${item.type.nameEn}`}</Typography.Text>
                                {handleRender(item)}
                            </Space>
                            <Space>
                                {item.type.dataType === 'file' && item.value && (
                                    <Button type="primary" icon={<DownloadOutlined />} onClick={() => {
                                        FileService.getFileById(Number(item.value), `${item.type.nameEn}`)
                                            .then((res) => successNotification("File successfully downloaded!"));
                                    }} />
                                )}
                                {studentCompetition && handleEditBtn(studentCompetition, item)}
                            </Space>
                        </Space>
                    </List.Item>
                )}
            />

            <Modal
                title="Enroll"
                open={open}
                okButtonProps={{ autoFocus: true, htmlType: 'submit' }}
                onCancel={closeModal}
                destroyOnClose
                modalRender={(dom) => (
                    <Form
                        layout="vertical"
                        form={createForm}
                        name="createForm"
                        onFinish={(values) => {
                            AcademicMobilityCompetitionService.updateStudentCompetitionInfo(Number(currentModal?.id), values[currentModal?.type.id || 0])
                                .then(() => {
                                    fetchInfo();
                                    successNotification("Data successfully updated!");
                                })
                                .finally(closeModal);
                        }}
                    >
                        {dom}
                    </Form>
                )}
            >
                {currentModal && <MobilityDataType key={currentModal.id} type={currentModal.type} form={createForm} />}
            </Modal>
        </Page>
    )
}

export default StudentAcademicMobilityCompetitionDetailPage