import React, { useEffect } from 'react';
import PageWrapper from '../../../../ui/PageWrapper';
import { Typography, Space, Select, Table } from 'antd';
import SelectOptionsFilter from '../SelectOptionsFilter';
import useReceptionAppointmentList from './useReceptionAppointmentList';

const { Title } = Typography;

const ReceptionAppointmentList = () => {
    const {
        selectedFiltersOption,
        handleSelect,
        selectedDate,
        availableDate,
        appointmentData,
        selectedPlace,
        referrals,
        places
    } = useReceptionAppointmentList();

    // Проверка на одно значение в places
    useEffect(() => {
        if (places.length === 1) {
            const singlePlace = places[0].value;
            handleSelect.handleSelectPlace(singlePlace);
        }
        if (referrals.length === 1) {
            const singlereferrals = referrals[0].value;
            handleSelect.handleSelectOption(singlereferrals);
        }
    }, [places, referrals]);

    const columns: any[] = [
        {
            title: 'Time',
            dataIndex: 'time',
            key: 'time',
            width: '10%',
        },
        {
            title: 'Student',
            dataIndex: 'student',
            key: 'student',
        },
        {
            title: 'Group',
            dataIndex: 'group',
            key: 'group',
        },
        {
            title: 'Student Question',
            dataIndex: 'studentQuestion',
            key: 'studentQuestion',
        },
    ];

    const dataSource = appointmentData.map(data => {
        const date = new Date(data.appointmentDatetime);
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const formattedTime = `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}`;

        return {
            time: formattedTime,
            student: data.student.nameEn + " " + data.student.surnameEn,
            group: data.student.group.title,
            studentQuestion: data.studentQuestion
        };
    });

    return (
        <PageWrapper>
            <section>
                <header>
                    <Title level={3}>
                        Appointments at the Reception
                    </Title>
                </header>
                <Space direction='vertical' style={{ width: "100%" }}>
                    <Space size={20} direction="vertical" style={{ marginBottom: '20px', width: "100%" }}>
                        <Select
                            placeholder="Место посещения"
                            value={selectedPlace}
                            onSelect={(value) => handleSelect.handleSelectPlace(value)}
                            options={places}
                            style={{ width: 400 }}
                        ></Select>
                        <Select
                            placeholder="Цель посещения"
                            value={selectedFiltersOption}
                            onSelect={(value) => handleSelect.handleSelectOption(value)}
                            style={{ width: 400 }}
                            options={referrals}
                        ></Select>
                        <div>
                            <Select
                                placeholder="Дата"
                                value={selectedDate}
                                onSelect={(value) => handleSelect.handleSelectDate(value)}
                                options={availableDate}
                                style={{ width: 400 }}
                            ></Select>
                        </div>
                        {selectedDate && (
                            <>
                                <Table
                                    columns={columns}
                                    dataSource={dataSource} />
                            </>
                        )}
                    </Space>
                </Space>
            </section>
        </PageWrapper>
    );
};

export default ReceptionAppointmentList;
