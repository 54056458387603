import React from 'react'
import Page from '../../../ui/Page'
import { SyllabusService } from '../../../services/SyllabusService';
import { SyllabusDataDto } from '../../../interfaces/Syllabus/SyllabusDataDto';
import useFetchData from '../../../hooks/useFetchData';
import { Descriptions, Divider, List, Space, Spin, Table, Tabs, Typography } from 'antd';
import { CustomData } from '../../../interfaces/Syllabus/SyllabusDataDto';
import useDictionary from '../../../hooks/useDictionary';

interface Props {
    id: number;
}

const NoData = () => <Typography.Text type="secondary">Нет данных</Typography.Text>;


const SyllabusDetailPage = ({ id }: Props) => {
    const callbackSyllabusDetail = React.useCallback(() => SyllabusService.getSyllabusDataById(id), [id]);
    const { data: syllabusData, isLoading } = useFetchData<SyllabusDataDto>(callbackSyllabusDetail, true);
    const { dictionary } = useDictionary({});

    const secondSectionItems = [
        {
            key: '1',
            label: `Course Description`,
            children: (
                <Space direction={'vertical'} size={'small'}>
                    <Typography.Text>{syllabusData?.secondSection?.courseDescription?.value || <NoData />}</Typography.Text>
                </Space>
            )
        },
        {
            key: '2',
            label: `Course Goal`,
            children: (
                <Space direction={'vertical'} size={'small'}>
                    <Typography.Text>{syllabusData?.secondSection?.courseGoal?.value || <NoData />}</Typography.Text>
                </Space>
            )
        },
        {
            key: '3',
            label: `Course Objectives`,
            children: (
                <Space direction={'vertical'} size={'small'}>
                    <List
                        bordered
                        dataSource={syllabusData?.secondSection.courseObjectives}
                        renderItem={(item) => (
                            <List.Item>
                                <Typography.Text>{item.value}</Typography.Text>
                            </List.Item>
                        )}
                    />
                </Space>
            )
        },
        {
            key: '4',
            label: `Skills and Competences`,
            children: (
                <Space direction={'vertical'} size={'small'}>
                    <List
                        bordered
                        dataSource={syllabusData?.secondSection.skillsAndCompetences}
                        renderItem={(item) => (
                            <List.Item>
                                <Typography.Text>{item.value}</Typography.Text>
                            </List.Item>
                        )}
                    />
                </Space>
            )
        },
        {
            key: '5',
            label: `Learning Outcomes`,
            children: (
                <Space direction={'vertical'} size={'small'}>
                    <List
                        bordered
                        dataSource={syllabusData?.secondSection.learningOutcomes}
                        renderItem={(item) => (
                            <List.Item>
                                <Typography.Text>{item.value}</Typography.Text>
                            </List.Item>
                        )}
                    />
                </Space>
            )
        },
        {
            key: '6',
            label: `Teaching Methods`,
            children: (
                <Space direction={'vertical'} size={'small'}>
                    <Typography.Text>{syllabusData?.secondSection?.teachingMethods?.value ?? <NoData />}</Typography.Text>
                </Space>
            )
        },
        {
            key: '7',
            label: `Assessment Methods`,
            children: (
                <Space direction={'vertical'} size={'small'}>
                    <Typography.Text>{syllabusData?.secondSection?.assessmentMethods?.value ?? <NoData />}</Typography.Text>
                </Space>
            )
        },
        {
            key: '8',
            label: `Reading List`,
            children: (
                <Space direction={'vertical'} size={'small'}>
                    <Space direction={'vertical'} size={'large'}>
                        <Typography.Text>Basic literature</Typography.Text>
                        <List
                            bordered
                            size='large'
                            dataSource={syllabusData?.secondSection?.readingList?.linkedData?.filter(item => item.prefix.prefix === 'basic_literature') || []}
                            renderItem={(item) => (
                                <List.Item>
                                    <Typography.Text>{item.value}</Typography.Text>
                                </List.Item>
                            )}
                        />
                    </Space>
                    <Divider />
                    <Space direction={'vertical'} size={'large'}>
                        <Typography.Text>Supplementary literature</Typography.Text>
                        <List
                            bordered
                            size='large'
                            dataSource={syllabusData?.secondSection?.readingList?.linkedData?.filter(item => item.prefix.prefix === 'supplementary_literature') || []}
                            renderItem={(item) => (
                                <List.Item>
                                    <Typography.Text>{item.value}</Typography.Text>
                                </List.Item>
                            )}
                        />
                    </Space>
                </Space>
            )
        },
        {
            key: '9',
            label: `Resources`,
            children: (
                <Space direction={'vertical'} size={'small'}>
                    <List
                        bordered
                        dataSource={syllabusData?.secondSection.resources}
                        renderItem={(item) => (
                            <List.Item>
                                <Typography.Text>{item.value}</Typography.Text>
                            </List.Item>
                        )}
                    />
                </Space>
            )
        },
        {
            key: '10',
            label: `Course Policy`,
            children: (
                <Space direction={'vertical'} size={'small'}>
                    <Typography.Text>{syllabusData?.secondSection.coursePolicy || <NoData />}</Typography.Text>
                </Space>
            )
        }
    ];

    const thirdSectionItems = [
        {
            key: '1',
            label: `Abbreviations`,
            children: (
                <Space direction={'vertical'} size={'small'}>
                    {/* <List
                        bordered
                        dataSource={syllabusData?.thirdSection.abbreviations}
                        renderItem={(item) => (
                            <List.Item>
                                <Typography.Text>{item.value}</Typography.Text>
                            </List.Item>
                        )}
                    /> */}
                    <NoData />
                </Space>
            )
        },
        {
            key: '2',
            label: `Lesson Plans`,
            children: (
                <Table
                    bordered
                    dataSource={syllabusData?.thirdSection.lessonPlans.sort((a, b) => a.order - b.order)}
                    pagination={false}
                    columns={[
                        {
                            title: 'Week',
                            width: "50px",
                            dataIndex: 'value',
                            key: 'value'
                        },
                        {
                            title: 'Course Topic',
                            dataIndex: 'linkedData',
                            key: 'linkedData',
                            render(text: string, record: CustomData, index: number) {
                                return record.linkedData?.sort((a, b) => a.order - b.order).map((item, i) => (
                                    <List
                                        header={<Typography.Text>{item.value}</Typography.Text>}
                                        size='large'
                                        dataSource={item.linkedData?.sort((a, b) => a.order - b.order)}
                                        renderItem={(item) => (
                                            <List.Item>
                                                <Typography.Text>{item.value}</Typography.Text>
                                            </List.Item>
                                        )}
                                    />
                                ))
                            }
                        }
                    ]}
                />
            )
        },
        {
            key: '3',
            label: `Assignments for SIS`,
            children: (
                <Table
                    bordered
                    dataSource={syllabusData?.thirdSection.assignmentsForSIS.sort((a, b) => a.order - b.order)}
                    pagination={false}
                    columns={[
                        {
                            title: "№",
                            dataIndex: "key",
                            width: "50px",
                            key: "key",
                            render(text: string, record: CustomData, index: number) {
                                return index + 1;
                            },
                        },
                        {
                            title: 'SIS Name',
                            dataIndex: 'sis_name',
                            key: 'sis_name',
                            render(text: string, record: CustomData, index: number) {
                                return <List
                                    dataSource={record.linkedData?.filter((item) => item.prefix.prefix === 'sis_name').sort((a, b) => a.order - b.order) || []}
                                    renderItem={(item) => (
                                        <List.Item>
                                            <Typography.Text>{item.value}</Typography.Text>
                                        </List.Item>
                                    )}
                                />
                            }
                        },
                        {
                            title: 'SIS Submission form',
                            dataIndex: 'sis_submission_form',
                            key: 'sis_submission_form',
                            render(text: string, record: CustomData, index: number) {
                                return <List
                                    dataSource={record.linkedData?.filter((item) => item.prefix.prefix === 'sis_submission_form').sort((a, b) => a.order - b.order) || []}
                                    renderItem={(item) => (
                                        <List.Item>
                                            <Typography.Text>{item.value}</Typography.Text>
                                        </List.Item>
                                    )}
                                />
                            }
                        },
                        {
                            title: 'SIS Link',
                            dataIndex: 'sis_link',
                            key: 'sis_link',
                            render(text: string, record: CustomData, index: number) {
                                return <List
                                    dataSource={record.linkedData?.filter((item) => item.prefix.prefix === 'sis_link').sort((a, b) => a.order - b.order) || []}
                                    renderItem={(item) => (
                                        <List.Item>
                                            <Typography.Text>{item.value}</Typography.Text>
                                        </List.Item>
                                    )}
                                />
                            }
                        }
                    ]}
                />
            )
        }
    ];

    const fourthSectionItems = [
        {
            key: '1',
            label: `Grade criteria and scale`,
            children: (
                <Table
                    bordered
                    dataSource={syllabusData?.fourthSection.gradeCriteria.sort((a, b) => a.order - b.order)}
                    pagination={false}
                    columns={[
                        {
                            title: "Grade",
                            dataIndex: "grade",
                            key: "grade",
                            width: "80px",
                            render(text: string, record: CustomData, index: number) {
                                return record.value;
                            },
                        },
                        {
                            title: 'Criteria',
                            dataIndex: 'criteria',
                            key: 'criteria',
                            render(text: string, record: CustomData, index: number) {
                                return (
                                    <List
                                        dataSource={record.linkedData?.sort((a, b) => a.order - b.order)}
                                        renderItem={(item) => (
                                            <List.Item>
                                                <Typography.Text>{item.value}</Typography.Text>
                                            </List.Item>
                                        )}
                                    />
                                )
                            }
                        }
                    ]}
                />
            )
        },
        {
            key: '2',
            label: `Student performance evaluation system`,
            children: (
                <Table
                    bordered
                    dataSource={syllabusData?.fourthSection.studentPerformanceEvaluationSystem?.linkedData?.sort((a, b) => a.order - b.order)}
                    pagination={false}
                    columns={[
                        {
                            "title": "Period",
                            "dataIndex": "value",
                            "key": "value",
                        },
                        {
                            "title": "Assignments",
                            "dataIndex": "linkedData",
                            "key": "linkedData",
                            render(text: string, record: CustomData, index: number) {
                                return record.linkedData?.sort((a, b) => a.order - b.order).map((item, i) => (
                                    <List
                                        dataSource={item.linkedData?.filter((weekData) => weekData.prefix.prefix === 'spes_assignments_deadline').sort((a, b) => a.order - b.order) || []}
                                        renderItem={(weekData) => (
                                            <List.Item>
                                                {weekData.value ?
                                                    <Typography.Text>{`${weekData.value} - ${item.value}`}</Typography.Text>
                                                    : <Typography.Text>{`${item.value}`}</Typography.Text>
                                                }
                                            </List.Item>
                                        )}
                                    />
                                ))
                            }
                        },
                        {
                            "title": "Weight",
                            "dataIndex": "linkedData",
                            "key": "linkedData",
                            render(text: string, record: CustomData, index: number) {
                                return record.linkedData?.sort((a, b) => a.order - b.order).map((item, i) => (
                                    <List
                                        dataSource={item.linkedData?.filter((weekData) => weekData.prefix.prefix === 'spes_assignments_weight').sort((a, b) => a.order - b.order) || []}
                                        renderItem={(weekData) => (
                                            <List.Item>
                                                {weekData.value}
                                            </List.Item>
                                        )}
                                    />
                                ))
                            }
                        }
                    ]}
                />
            )
        },
    ];


    const sectionItems = [
        {
            key: '1',
            label: `General information`,
            children: (
                <Space direction={'vertical'} size={'small'}>
                    <Descriptions title={"General information"}>
                        <Descriptions.Item label="Course Title">{`${syllabusData?.syllabus?.discipline?.titleEn}` || <NoData />}</Descriptions.Item>
                        <Descriptions.Item label="Deparment">{`${syllabusData?.syllabus.discipline.department.titleEn}` || <NoData />}</Descriptions.Item>
                        <Descriptions.Item label="Degree Cycle (Level)/ Major">{`${syllabusData?.syllabus.discipline.academicDegree.titleEn}`}</Descriptions.Item>
                        <Descriptions.Item label="Year">{`${syllabusData?.syllabus.academicYear}`}</Descriptions.Item>
                        <Descriptions.Item label="Number of Credits">{syllabusData?.syllabus.discipline.volumeCredits || <NoData />}</Descriptions.Item>
                        <Descriptions.Item label="Language of Delivery">{dictionary.languageList.find(v => v.id === syllabusData?.syllabus.lang)?.value}</Descriptions.Item>
                    </Descriptions>
                </Space>
            )
        },
        {
            key: '2',
            label: `Goals, objectives and learning outcomes of the course`,
            children: <Tabs tabPosition={'left'} items={secondSectionItems} />
        },
        {
            key: '3',
            label: `Course Content`,
            children: <Tabs tabPosition={'left'} items={thirdSectionItems} />
        },
        {
            key: '4',
            label: `Student performance evaluation system for the course`,
            children: <Tabs tabPosition={'left'} items={fourthSectionItems} />
        }
    ];

    return (
        <Page
            title={"Syllabus of the course"}
        >
            {isLoading ?
                <Spin />
                :
                <Tabs
                    defaultActiveKey="1"
                    tabPosition={'top'}
                    items={sectionItems}
                />
            }
        </Page>
    )
}

export default SyllabusDetailPage