import { useCallback, useState } from "react";
import { AcademicMobilityCompetitionService } from "../../../../../services/AcademicMobilityCompetitionService";
import useFetchData from "../../../../../hooks/useFetchData";
import { CollationStatementApproveDto, StudentCollationStatementDto } from "../../../../../interfaces/LearningAgreementDto";
import { AxiosResponse } from "axios";
import { ApprovalSheetDto, CollationStatementGradeDto, StudentAcademicMobilityCompetitionDto } from "../../../../../interfaces/AcademicMobilityCompetitionDto";
import { EducationalProgramsService } from "../../../../../services/EducationalProgramsService";
import { EducationProgramDtoResponse } from "../../../../../interfaces/EducationalPrograms/EducationProgramDtoResponse";
import { FileService } from "../../../../../services/file/FileService";
import { successNotification } from "../../../../../helpers/successNotification";
import { Button, Form, Space, Typography } from "antd";
import { EditOutlined } from "@ant-design/icons";
import useModal from "../../../../../ui/Modal/useModal";

const useCollationStatement = (id: number) => {
    const callbackCollationStatementApprove = useCallback(() => AcademicMobilityCompetitionService.getCollationStatementByApprovalById(id), [id])
    const { data: collationStatementApprove, fetchData: fetchCollationStatementApprove } = useFetchData<CollationStatementApproveDto>(callbackCollationStatementApprove, true);

    const callbackCollationStatementApprovalSheet = useCallback(() => {
        if (collationStatementApprove?.statementId) {
            return AcademicMobilityCompetitionService.getCollationApprovalSheet(collationStatementApprove?.statementId)
        }
        return Promise.resolve({} as AxiosResponse<ApprovalSheetDto[]>)
    }, [collationStatementApprove?.statementId]);

    const callbackCollationStatement = useCallback(() => {
        if (collationStatementApprove?.statementId) {
            return AcademicMobilityCompetitionService.getCollationStatementById(collationStatementApprove?.statementId)
        }
        return Promise.resolve({} as AxiosResponse<StudentCollationStatementDto>)
    }, [collationStatementApprove?.statementId]);

    const { data: approvalSheet, fetchData: fetchApprovalSheet } = useFetchData<ApprovalSheetDto[]>(callbackCollationStatementApprovalSheet, true);
    const { data: collationStatement, fetchData: fetchCollationStatement } = useFetchData<StudentCollationStatementDto>(callbackCollationStatement, true);

    const callbackStudentCompetition = useCallback(() => {
        if (collationStatement?.studentAcademicMobilityCompetitionId !== undefined) {
            return AcademicMobilityCompetitionService.getStudentCompetitionById(collationStatement.studentAcademicMobilityCompetitionId);
        }
        return Promise.resolve({} as AxiosResponse<StudentAcademicMobilityCompetitionDto>);
    }, [collationStatement]);

    const callbackCollationStatementGrades = useCallback(() => {
        if (collationStatementApprove?.statementId) {
            return AcademicMobilityCompetitionService.getCollationStatementGrade(collationStatementApprove.statementId);
        }
        return Promise.resolve({} as AxiosResponse<CollationStatementGradeDto[]>);
    }, [collationStatementApprove]);

    const callbackEducationalProgram = useCallback(() => {
        if (collationStatementApprove?.student.educationProgramId !== undefined) {
            return EducationalProgramsService.getOneEducationalProgram(collationStatementApprove.student.educationProgramId);
        }
        return Promise.resolve({} as AxiosResponse<EducationProgramDtoResponse>);
    }, [collationStatementApprove]);

    const { data: studentCompetition } = useFetchData<StudentAcademicMobilityCompetitionDto>(callbackStudentCompetition, true);
    const { data: educationalProgram } = useFetchData<EducationProgramDtoResponse>(callbackEducationalProgram, true);
    const { data: collationStatementGrade } = useFetchData<CollationStatementGradeDto[]>(callbackCollationStatementGrades, true);

    const { showModal, isModalOpen, handleCancel } = useModal();
    const [aituGradeId, setGradeId] = useState<number>(0);
    const [form] = Form.useForm();


    const handleShowModal = (record: any) => {
        showModal();
        if (record)
            setGradeId(record.id);
        form.setFieldsValue({ aituGrade: record.aituGrade });
    }

    const handleCancelModal = () => {
        setGradeId(0);
        form.resetFields();
        handleCancel();
    }

    const column = [
        {
            title: 'Discipline',
            dataIndex: 'discipline',
            key: 'discipline',
        },
        {
            title: 'Original Credits',
            dataIndex: 'originalCredits',
            key: 'originalCredits',
        },
        {
            title: 'ECTS Credits',
            dataIndex: 'ectsCredits',
            key: 'ectsCredits',
        },
        {
            title: 'Original Grade',
            dataIndex: 'originalGrade',
            key: 'originalGrade',
        },
        {
            title: 'Aitu Grade',
            dataIndex: 'aituGrade',
            key: 'aituGrade',
            render: (text: string, record: CollationStatementGradeDto) => {
                return collationStatementApprove?.approver.position?.prefix === "STUDY_DEPARTMENT_DIRECTOR" ? (
                    <Space>
                        <Typography.Text>{text}</Typography.Text>
                        {collationStatementApprove?.approved === null && <Button type="primary" icon={<EditOutlined />} onClick={() => handleShowModal(record)} />}
                    </Space>
                ) : text;
            }
        },
    ];

    const dataSource = collationStatementGrade?.map((collationStatementGrade) => {
        return {
            key: collationStatementGrade.id,
            id: collationStatementGrade.id,
            discipline: collationStatementGrade.foreignDiscipline.nameEn,
            originalCredits: collationStatementGrade.foreignDiscipline.originalCredits,
            ectsCredits: collationStatementGrade.foreignDiscipline.ectsCredits,
            originalGrade: collationStatementGrade.originalGrade,
            aituGrade: collationStatementGrade.aituGrade
        };
    });

    const downloadStatement = (transcriptId: number) => {
        FileService.getFileById(transcriptId, 'Collation statement')
            .then(() => successNotification("Collation Statement successfully downloaded!"))
    };


    return {
        collationStatement,
        collationStatementApprove,
        approvalSheet,
        studentCompetition,
        educationalProgram,
        fetchCollationStatement,
        fetchCollationStatementApprove,
        fetchApprovalSheet,
        column,
        dataSource,
        downloadStatement,
        modal: {
            showModal,
            isModalOpen,
            handleCancelModal,
            aituGradeId,
            form,
        },
    };
};

export default useCollationStatement;
