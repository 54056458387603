import { AreaChartOutlined, BarChartOutlined, BookFilled, CarryOutFilled, CheckSquareFilled, TableOutlined, FileDoneOutlined, FilePdfFilled, FileTextFilled, HomeFilled, IdcardOutlined, InfoCircleOutlined, LineChartOutlined, LogoutOutlined, MedicineBoxFilled, OrderedListOutlined, PaperClipOutlined, ProfileOutlined, QuestionCircleFilled, SnippetsFilled, SolutionOutlined, StarOutlined, TeamOutlined, UnorderedListOutlined, UserOutlined, FileOutlined, ContainerOutlined, PercentageOutlined, SnippetsOutlined, SelectOutlined, InteractionOutlined, FileSearchOutlined, BellOutlined, FormOutlined, CopyOutlined, DribbbleOutlined, CheckCircleOutlined, HomeOutlined, BookOutlined } from '@ant-design/icons';
import { MenuProps, Switch, Badge, Tooltip, Typography } from 'antd';
import React from 'react'
import { Link } from 'react-router-dom';
import grantPermission from '../../../../helpers/grantPermission';
import { useTheme } from '../../../../themes/useTheme';
import UserService from '../../../../services/userService';
import useApplications from '../../../pages/ApplicationPage/useApplications';
import reactNodeToString from '../../../../helpers/reactNodeToString';

type MenuItem = Required<MenuProps>['items'][number];

const AuthoritedRoutes = (requestedRole: string): MenuItem[] => {

    const [darkMode, setDarkMode] = useTheme();
    const { data } = useApplications({ fetchApplicationCount: true });

    function menuItem(
        label: React.ReactNode,
        key?: React.Key | null,
        icon?: React.ReactNode,
        children?: MenuItem[],
        type?: 'group',
    ): MenuItem {
        return {
            key,
            icon,
            children,
            label: (
                <Tooltip title={reactNodeToString(label)} placement="right">
                    {label}
                </Tooltip>
            ),
            type,
        } as MenuItem;
    }

    const authenticatedContent = (role: string | string[], type: string, roleList?: string[]) => {
        if (Array.isArray(role)) {
            let isValid = false;
            for (let i = 0; i < role.length; i++) {
                if (grantPermission(role[i], type)) {
                    isValid = true;
                    break;
                }
            }
            return isValid;
        }
        else {
            return grantPermission(role, type)
        };
    };

    // Показывается по дефолту
    const defaultItems: MenuItem[] = [
        menuItem(<Link to="/">Dashboard</Link>, "/", <HomeFilled />),
        // menuItem(<Link to="/profile">Profile</Link>, "/profile", <UserOutlined title="Profile" />),
        menuItem(<Link to="/teachers">Academic staff</Link>, "/teachers", <UserOutlined />),
        menuItem(<div onClick={UserService.logOut}>Log out</div>, "log-out", <LogoutOutlined />),
        menuItem(<Switch checked={darkMode} onChange={setDarkMode} checkedChildren="Dark" unCheckedChildren="Light" />, "dark-mode"),
    ];

    const deansSubmenu: MenuItem[] = [];

    // Под-меню для оффиса
    const studentsSubmenu: MenuItem[] = [
        // menuItem(<Link to="/create-student">Create new student</Link>, "/create-student", <PlusSquareOutlined />),
        menuItem(<Link to="/aсademic_transcript">Transcripts</Link>, "/aсademic_transcript", <SnippetsFilled />),
        menuItem(<Link to="/students-details">Details</Link>, "/students-details", <IdcardOutlined />),
        // menuItem(<Link to="/students-ep-choice">EP choice</Link>, "/students-ep-choice", <CarryOutFilled />),
        menuItem(<Link to="/student-debt">Debts</Link>, "/student-debt", <BookFilled />),
        // menuItem(<Link to="/student-retires">Retires</Link>, "/student-retires", <ExceptionOutlined />),
        // menuItem(<Link to="/student-benefits">Benefits</Link>, "/student-benefits", <MedicineBoxFilled />),
        menuItem(<Link to="/statistics">Statistics</Link>, "/statistics", <LineChartOutlined />),
        menuItem(<Link to="/academic_rating">Rating</Link>, "/academic_rating", <StarOutlined />),
        menuItem(<Link to="/study-discount">Study discount</Link>, "/study-discount", <PercentageOutlined />),
        menuItem(<Link to="/students-attendance">Attendance</Link>, "/students-attendance", <BarChartOutlined />),
        menuItem(<Link to="/students-grades">Grades</Link>, "/students-grades", <FileDoneOutlined />),
    ];
    const applicationsSubmenu: MenuItem[] = [
        menuItem(<Link to="/applications">All applications</Link>, "/applications", <FileOutlined />),
        menuItem(<Link to="/applications-for-sign">For sign {Number(data.applicationCount) > 0 && (<Badge count={data.applicationCount} color='#00000040' style={{ marginLeft: 20 }} />)}</Link>, "/applications-for-sign", <FormOutlined />),

    ];
    // ДЛЯ ОФФИСА
    const officeItems: MenuItem[] = [
        menuItem(<Link to="/">Dashboard</Link>, "/", <HomeFilled />),
        menuItem(<Link to="/teachers">Academic staff</Link>, "/teachers", <UserOutlined />),
        menuItem("Students", "Students", <TeamOutlined />, studentsSubmenu),
        menuItem(<>Applications {Number(data.applicationCount) > 0 && (<Badge count={data.applicationCount} color='#00000040' style={{ marginLeft: 7 }} />)}</>, "/applications", <ContainerOutlined />, applicationsSubmenu),
        menuItem(<Link to="/educational-programs">Educational Programs</Link>, "/educational-programs", <OrderedListOutlined />),
        menuItem("Learning agreements", "Learning agreements", <CheckCircleOutlined />, [
            menuItem(<Link to="/learning-agreements">Learning agreements</Link>, "/learning-agreements", <UnorderedListOutlined />),
            menuItem(<Link to="/collation-statements">Collation statement</Link>, "/collation-statements", <UnorderedListOutlined />),
        ]),


        // menuItem(<Link to="/dorm-applications">Dormitory Applications</Link>, "/dorm-applications", <HomeOutlined />),
        menuItem("Dormitory", "Dormitory", <BookFilled />, [
            menuItem(<Link to="/dorm-applications">Applications</Link>, "/dorm-applications"),
            menuItem(<Link to="/dorm-applications/settings">Settings</Link>, "/dorm-applications/settings"),
            menuItem(<Link to="/dorm-contracts">Contracts</Link>, "/dorm-contracts"),
        ]),
        menuItem(<Link to="/reception-appointment-list">Reception</Link>, "/reception-appointment-list", <SnippetsFilled />),
        // menuItem(<Link to="/syllabus">Syllabus</Link>, "/syllabus", <BookFilled />),
        // menuItem(<Link to="/notification-create">Notification</Link>, "/notification-create", <BellOutlined />),
        menuItem("Syllabus", "Syllabus", <BookFilled />, [
            // menuItem(<Link to="/syllabus/groups-and-streams">By Groups and Streams</Link>, "/syllabus/groups-and-streams", <BookFilled />),
            // menuItem(<Link to="/syllabus/study-streams">By study streams</Link>, "/syllabus/study-streams", <BookFilled />),
            menuItem(<Link to="/syllabus">All</Link>, "/syllabus", <BookFilled />),
            menuItem(<Link to="/syllabus-draft">Drafts</Link>, "/syllabus-draft", <SnippetsOutlined />),
        ]),

        // menuItem(<Link to="/expected-learning-outcomes">Learning Outcomes</Link>, "/expected-learning-outcomes", <OrderedListOutlined />),
        // menuItem(<Link to="/academic-stream">Academic stream</Link>, "/academic-stream", <ClusterOutlined />),
        // menuItem(<Link to="/bachelor-contingent">Learning Outcomes</Link>, "/bachelor-contingent", <ClusterOutlined />),
        // menuItem("ESUVO", "ESUVO", <TeamOutlined />, [
        //     menuItem(<Link to="/esuvo-disciplines">Disciplines</Link>, "/esuvo-disciplines", <SnippetsFilled />),
        //     menuItem(<Link to="/esuvo-programs">Edu Programs</Link>, "/esuvo-programs", <CarryOutFilled />),
        //     menuItem(<Link to="/esuvo-curriculums">Curriculums</Link>, "/esuvo-curriculums", <BookFil23 258 000led />),
        // ]),
    ].filter((item): item is MenuItem => item !== undefined);

    // ДЛЯ СТУДЕНТОВ
    const studentItems: MenuItem[] = [
        menuItem(<Link to="/">Dashboard</Link>, "/", <HomeFilled />),
        menuItem(<Link to="/profile">Profile</Link>, "/profile", <UserOutlined title="Profile" />),
        menuItem(<Link to="/teachers">Academic staff</Link>, "/teachers", <UserOutlined />),
        menuItem(<Link to="/transcript">Transcript</Link>, "/transcript", <SnippetsFilled />),
        menuItem(<Link to="/reception">Reception</Link>, "/reception", <SnippetsFilled />),
        menuItem("Deans Office", "Deans Office", <TeamOutlined />, deansSubmenu),
        menuItem(<Link to="/course-enrollment">Course Enrollment</Link>, "/course-enrollment", <FileTextFilled />),
        // menuItem(<Link to="/services">Services</Link>, "/services", <SolutionOutlined />),
        menuItem(<Link to="/profile/curriculum">Curriculum</Link>, "/profile/curriculum", <SolutionOutlined />),
        menuItem(<Link to="/psychologist-appointment-page">Psychologist Appointment</Link>, "/psychologist-appointment-page", <OrderedListOutlined />),
        // menuItem(<Link to="/disciplines">Disciplines</Link>, "/disciplines", <OrderedListOutlined />),
        menuItem(<Link to="/approve-curriculum">Approve Curriculum</Link>, "/approve-curriculum", <CheckSquareFilled />),
        menuItem(<Link to="/personal-information">Confirm Personal Info</Link>, "/personal-information", <InfoCircleOutlined />),
        // menuItem(<Link to="/student-debt">Student Debts</Link>, "/student-debt", <SnippetsFilled />),
        menuItem(<Link to="/iros-page">iROS</Link>, "/iros-page", <AreaChartOutlined />),
        menuItem(<Link to="/ssci-page">SSCI</Link>, "/ssci-page", <TeamOutlined />),
        menuItem(<Link to="/physical-education-course-enrollment">Physical Education</Link>, "/physical-education-course-enrollment", <DribbbleOutlined />),
        menuItem(<Link to="/fx-exam-application">FX Exam Application</Link>, "/fx-exam-application", <FileOutlined />),
        menuItem(<Link to="/retake-application">Retake Application</Link>, "/retake-application", <FileOutlined />),
        // menuItem(<Link to="/applications">Applications</Link>, "/applications", <ContainerOutlined />),
        menuItem(<Link to="/applications">Applications</Link>, "/applications", <ContainerOutlined />),
        menuItem("Academic Mobility", "Academic Mobility", <InteractionOutlined />, [
            menuItem(<Link to="/academic-mobility/competitions">Competitions</Link>, "/academic-mobility-competition", <BookFilled />),
            menuItem(<Link to="/academic-mobility/assigned">Enrolled</Link>, "/academic-mobility-assigned", <BookFilled />),
        ]),
        menuItem("Diploma", "Diploma", <ContainerOutlined />, [
            menuItem(<Link to="/diploma/selection">Select</Link>, "/diploma/selection", <SolutionOutlined />),
        ]),
        menuItem(<div onClick={UserService.logOut}>Log out</div>, "log-out", <LogoutOutlined />),
        menuItem(<Switch checked={darkMode} onChange={setDarkMode} checkedChildren="Dark" unCheckedChildren="Light" />, "dark-mode"),
    ].filter(Boolean);


    // ДЛЯ УЧИТЕЛЕЙ
    const teacherItems: MenuItem[] = [
        menuItem(<Link to="/">Dashboard</Link>, "/", <HomeFilled />),
        menuItem(<Link to="/profile">Profile</Link>, "/profile", <UserOutlined title="Profile" />),
        menuItem(<Link to="/teachers">Academic staff</Link>, "/teachers", <UserOutlined />),
        menuItem(<Link to="/vedomost">Statement</Link>, "/vedomost", <BookFilled />),
        // menuItem(<Link to="/kpi">Kpi Application</Link>, "/kpi", <BarChartOutlined />),
        menuItem(<Link to="/iros">IROS</Link>, "/iros", <ProfileOutlined />),
        menuItem(<Link to="/fx-exam-students">Fx Exam Students</Link>, "/fx-exam-students", <FileOutlined />),
        menuItem("Graduation Thesis", "Graduation Thesis", <BookOutlined />, [
            menuItem(<Link to="/graduation-thesis/suggestions">Suggestions</Link>, "/graduation-thesis/suggestions",
                // <BookOutlined />
            ),
            menuItem(<Link to="/graduation-thesis/students">Students</Link>, "/graduation-thesis/students",
            ),
        ]),

        menuItem(<div onClick={UserService.logOut}>Log out</div>, "log-out", <LogoutOutlined />),
        menuItem(<Switch checked={darkMode} onChange={setDarkMode} checkedChildren="Dark" unCheckedChildren="Light" />, "dark-mode"),
    ].filter(Boolean);


    //ПОД РОЛИ ОФФИСА
    if (requestedRole === "OFFICE") {
        if (authenticatedContent('DEAN', 'addRole')) {
            deansSubmenu.push(

                // menuItem(<Link to="/disease-list">Disease List</Link>, "/disease-list", <MedicineBoxFilled />),
                menuItem(<Link to="/certificate-list">Certificate List</Link>, "/certificate-list", <FilePdfFilled />),
            );
            officeItems.push(menuItem("Deans Office", "Deans Office", <TeamOutlined />, deansSubmenu),)
        }
        if (authenticatedContent('OFFICE_REGISTRAR', 'addRole')) {
            officeItems.push(
                menuItem("Statements", "Statements", <BookFilled />, [
                    menuItem(<Link to="/vedomost">By Filter</Link>, "/vedomost", <UnorderedListOutlined />),
                    menuItem(<Link to="/vedomost/status">By status</Link>, "/vedomost/status", <InfoCircleOutlined />),
                ]),
                menuItem(<Link to="/approved-fx-exam-applications">FX Exam applications</Link>, "/approved-fx-exam-applications", <FileOutlined />),
                menuItem(<Link to="/summer-retake-applications">Retake applications</Link>, "/summer-retake-applications", <FileOutlined />),
                menuItem("Physical Education", "Physical Education", <DribbbleOutlined />, [
                    menuItem(<Link to="/physical-education-course-seetings">Settings</Link>, "/physical-education-course-seetings", <DribbbleOutlined />),
                    menuItem(<Link to="/physical-education-course-types">Types</Link>, "/physical-education-course-types", <DribbbleOutlined />),
                ]),

                // menuItem(<Link to="/surveys">Surveys</Link>, "/surveys", <FilePdfFilled />),
            );
            studentsSubmenu.splice(6, 0, menuItem(<Link to="/students-curriculum">Approve Curriculum</Link>, "/students-curriculum", <CheckSquareFilled />));
        }
        if (authenticatedContent('ACCOUNTANT', 'addRole')) {
            officeItems.push(
                menuItem(<Link to="/fx-exam-applications-list">FX Exam Application</Link>, "/fx-exam-applications-list", <FileOutlined />),
                menuItem(<Link to="/retake-applications-list">Retake applications</Link>, "/retake-applications-list", <FileOutlined />),
            );
        }
        if (authenticatedContent('TECHNICAL_SECRETARY', 'addRole')) {
            officeItems.push(
                menuItem(<Link to="/final-attestation-protocol">Final Attestation Protocol</Link>, "/final-attestation-protocol", <PaperClipOutlined />)
            );
        }
        if (authenticatedContent('QUESTIONAIRE_SECRETARY', 'addRole')) {
            officeItems.push(
                menuItem(<Link to="/surveys">Questionnaires</Link>, "/surveys", <QuestionCircleFilled />),
            );
        }
        if (authenticatedContent('SEW_DEPARTMENT', 'addRole')) {
            studentsSubmenu.push(
                menuItem("SSCI", "SSCI", <TeamOutlined />, [
                    menuItem(<Link to="/ssci-applications">Applications</Link>, "/ssci-applications", <AreaChartOutlined />),
                    menuItem(<Link to="/ssci-events">Events</Link>, "/ssci-events", <AreaChartOutlined />),
                ]),
            );
        }
        if (authenticatedContent(['OFFICE_REGISTRAR', 'ACADEMIC_DEPARTMENT'], 'addRole')) {
            officeItems.push(
                menuItem(<Link to="/disciplines">Disciplines</Link>, "/disciplines", <OrderedListOutlined />),
                menuItem(<Link to="/diploma-supplement">Diploma Supplement</Link>, "/diploma-supplement", <PaperClipOutlined />),
            );
        }
        if (authenticatedContent(['DIPLOMA_CATALOG'], 'addRole')) {
            officeItems.push(
                menuItem(<Link to="/catalog-diploma">Catalog of Diploma works</Link>, "/catalog-diploma", <FileSearchOutlined />),
            );
        }
        if (authenticatedContent(['OFFICE_REGISTRAR', 'ACADEMIC_DEPARTMENT', 'STUDENT_DEPARTMENT', 'DEAN'], 'addRole')) {
            officeItems.push(
                menuItem(<Link to="/notification">Notification</Link>, "/notification", <BellOutlined />),
            );
        }
        if (authenticatedContent(['OFFICE_REGISTRAR', 'ACADEMIC_DEPARTMENT', 'STUDENT_DEPARTMENT', 'TECHNICAL_SECRETARY'], 'addRole')) {
            officeItems.push(
                menuItem(<Link to="/reports">Reports</Link>, "/reports", <FileOutlined />),
            );
        }
        if (authenticatedContent('CCE_DEPARTMENT', 'addRole')) {
            officeItems.push(
                menuItem(<Link to="/certificate-registration">Certificate registration</Link>, "/certificate-registration", <FileDoneOutlined />),
            );
        }
        if (authenticatedContent('STUDENT_DEPARTMENT', 'addRole')) {
            officeItems.push(
                menuItem(<Link to="/order-list">Orders</Link>, "/order-list", <FileDoneOutlined />),
            );
            applicationsSubmenu.splice(6, 0, menuItem(<Link to="/applications-for-order">For order</Link>, "/applications-for-order", <SnippetsOutlined />));
        }
        if (authenticatedContent('PSYCHOLOGIST', 'addRole')) {
            officeItems.push(
                menuItem(<Link to="/appointment-list">Appointment List</Link>, "/appointment-list", <FileDoneOutlined />),
            );
        }
        if (authenticatedContent('HR', 'addRole')) {
            officeItems.push(
                // menuItem(<Link to="/kpi">Kpi Applications</Link>, "/kpi", <BarChartOutlined />),
                // menuItem(<Link to="/orders">Orders</Link>, "/orders", <FileDoneOutlined />),
            );
        }
        if (authenticatedContent('IR_DEPARTMENT', 'addRole')) {
            officeItems.push(
                menuItem("IR Department", "IR Department", <BookFilled />, [
                    menuItem(<Link to="/academic-mobility">Academic Mobility</Link>, "/academic-mobility", <InteractionOutlined />, [
                        menuItem(<Link to="/academic-mobility/competitions">Competitions</Link>, "/academic-mobility-competition", <BookFilled />),
                    ]),
                    menuItem(<Link to="/foreign-universities">Foreign Universities</Link>, "/foreign-universities", <BookFilled />),
                    menuItem(<Link to="/agreement">Agreement</Link>, "/agreement", <SnippetsOutlined />),
                    menuItem(<Link to="/agreement-type">Agreement Type</Link>, "/agreement-type", <SelectOutlined />),
                ]),
            )
        }
        if (authenticatedContent('DSI_DEPARTMENT', 'addRole')) {
            studentsSubmenu.push(
                menuItem("IROS", "IROS", <TeamOutlined />, [
                    menuItem(<Link to="/students-iros">Applications</Link>, "/students-iros", <AreaChartOutlined />),
                    menuItem(<Link to="/iros-indicators">Indicators</Link>, "/iros-indicators", <AreaChartOutlined />),
                ]),

            );
            applicationsSubmenu.splice(6, 0, menuItem(<Link to="/applications-for-dorm">For dorm</Link>, "/applications-for-dorm", <CopyOutlined />));
        }
        if (authenticatedContent(['STUDY_DEPARTMENT_DIRECTOR', 'ACADEMIC_DEPARTMENT', 'STUDY_DEPARTMENT'], 'addRole')) {
            const studyDepartmentSubmenu: MenuItem[] = [
                menuItem(<Link to="/graduation-thesis/settings">Settings</Link>, "/graduation-thesis/settings"),
                // menuItem(<Link to="/graduation-thesis/list">List</Link>, "/graduation-thesis/list"),
            ];

            if (authenticatedContent('ACADEMIC_DEPARTMENT', 'addRole')) {
                studyDepartmentSubmenu.push(
                    menuItem(<Link to="/graduation-thesis/choose-settings">Student Choose Settings</Link>, "/graduation-thesis/choose-settings"),
                );
            }
            // [
            //     menuItem(<Link to="/graduation-thesis/settings">Settings</Link>, "/graduation-thesis/settings",
            //         // <BookOutlined />
            //     ),
            //     // menuItem(<Link to="/diploma-work/choose-settings">Student Choose Settings</Link>, "/diploma-work/choose-settings",),
            //     // menuItem(<Link to="/graduation-thesis/choose-settings">Student Choose Settings</Link>, "/graduation-thesis/choose-settings",),
            //     menuItem(<Link to="/graduation-thesis/list">List</Link>, "/graduation-thesis/list",
            //     ),
            // ]

            officeItems.push(menuItem("Graduation Thesis", "Graduation Thesis", <BookOutlined />, studyDepartmentSubmenu));


        }

        officeItems.push(
            menuItem(<div onClick={UserService.logOut}>Log out</div>, "log-out", <LogoutOutlined />),
            menuItem(<Switch checked={darkMode} onChange={setDarkMode} checkedChildren="Dark" unCheckedChildren="Light" />, "dark-mode"),
        )
    }
    else if (requestedRole === "STUDENTS") {
        deansSubmenu.push(
            // menuItem(<Link to="/create-student">Create new student</Link>, "/create-student", <PlusSquareOutlined />),

            menuItem(<Link to="/certificate">Certificate</Link>, "/certificate", <FilePdfFilled />),
            // menuItem(<Link to="/disease">Disease</Link>, "/disease", <MedicineBoxFilled />),
        )
    }



    switch (requestedRole) {
        case "OFFICE":
            return officeItems;
        case "STUDENTS":
            return studentItems;
        case "TEACHERS":
            return teacherItems;
        default:
            return defaultItems;
    }
}

export { AuthoritedRoutes };
