import React from 'react'
import Page from '../../../../../ui/Page';
import BackButton from '../../../../../ui/BackButton';
import { Button, Descriptions, Form, Input, InputNumber, Modal, Select, Space, Table, Typography } from 'antd';
import { handleStatus } from '../../../../../common/getStatusTag';
import RowSpan from '../../../../../ui/RowSpan';
import moment from 'moment';
import { AcademicMobilityCompetitionService } from '../../../../../services/AcademicMobilityCompetitionService';
import { successNotification } from '../../../../../helpers/successNotification';
import CustomModalWithForm from '../../../../../ui/Modal/CustomModalForm';
import { DownloadOutlined } from '@ant-design/icons';
import useCollationStatement from './useCollationStatement';
import AppromentTable from '../../AppromentTable';

interface Props {
    id: number;
}

const renderTable = (columns: any, dataSource: any, title: string) => (
    <Space direction="vertical" style={{ width: '100%' }}>
        <Typography.Title level={5}>{title}</Typography.Title>
        <Table bordered columns={columns} dataSource={dataSource} />
    </Space>
);

const CollationStatementDetailPage = ({ id }: Props) => {
    const {
        collationStatement,
        collationStatementApprove,
        studentCompetition,
        educationalProgram,
        fetchCollationStatement,
        fetchCollationStatementApprove,
        fetchApprovalSheet,
        column,
        dataSource,
        approvalSheet,
        downloadStatement,
        modal,
    } = useCollationStatement(id);


    const handleStatusChange = (values: any) => {
        AcademicMobilityCompetitionService.collationStatementApprove(id, values)
            .then(() => {
                successNotification("Статус успешно изменен");
                fetchCollationStatement();
                fetchApprovalSheet();
                fetchCollationStatementApprove();
            });
    }

    const handleUpdateGrade = (values: any) => {
        AcademicMobilityCompetitionService.updateAituGrade(modal.aituGradeId, values.aituGrade)
            .then(() => {
                successNotification("Оценка успешно изменена");
                fetchCollationStatement();
                fetchCollationStatementApprove();
                modal.handleCancelModal();
            });
    }

    return (
        <Page>
            <BackButton />
            {collationStatementApprove?.approved === null && <RowSpan
                right={
                    <CustomModalWithForm
                        title='Approve / Reject'
                        btnTitle='Approve / Reject'
                        onOk={modal.form.submit}
                        form={modal.form}
                    >
                        <Form form={modal.form} onFinish={handleStatusChange}>
                            <Form.Item name="approved" rules={[{ required: true }]}>
                                <Select placeholder="Select status">
                                    <Select.Option value="true">Подтвердить</Select.Option>
                                    <Select.Option value="false">Отклонить</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="comment"
                                dependencies={['approved']}
                                rules={[
                                    ({ getFieldValue }) => ({
                                        required: getFieldValue('approved') === 'false',
                                        message: 'Write a comment',
                                    }),
                                ]}
                            >
                                <Input.TextArea placeholder="Write a comment" style={{ height: '100px' }} />
                            </Form.Item>
                        </Form>
                    </CustomModalWithForm>
                }
            />}
            <Space direction='vertical' style={{ width: '100%', rowGap: '20px' }}>
                <Descriptions layout='horizontal' title="Information" column={1} bordered>
                    <Descriptions.Item label="Fullname">{collationStatementApprove?.student.fullNameKz}</Descriptions.Item>
                    <Descriptions.Item label="Course">{collationStatementApprove?.student.courseGrade}</Descriptions.Item>
                    <Descriptions.Item label="Group">{collationStatementApprove?.student.group.title}</Descriptions.Item>
                    <Descriptions.Item label="Educational Program">{educationalProgram?.titleEn}</Descriptions.Item>
                    <Descriptions.Item label="Mobility period">
                        <Space direction='vertical'>
                            <div>{'From: ' + moment(studentCompetition?.competition.studyStartAt).format('LL hh:mm')}</div>
                            <div>{'To: ' + moment(studentCompetition?.competition.studyEndAt).format('LL hh:mm')}</div>
                        </Space>
                    </Descriptions.Item>
                    <Descriptions.Item label="Mobility University">
                        {studentCompetition?.competition.university.nameEn}
                    </Descriptions.Item>
                    <Descriptions.Item label="Status">
                        {handleStatus(collationStatement?.approved)}
                    </Descriptions.Item>
                </Descriptions>
                <Space>
                    <Button icon={<DownloadOutlined />} onClick={() => collationStatement?.originalTranscript && downloadStatement(collationStatement.originalTranscript)} type='primary'>Download original transcript</Button>
                    <Button icon={<DownloadOutlined />} onClick={() => collationStatement?.engTranscript && downloadStatement(collationStatement.engTranscript)} type='primary' >Download english transcript</Button>
                </Space>

                {renderTable(column, dataSource, 'Collation statement')}
                <AppromentTable dataSource={approvalSheet} />

            </Space>
            <Modal
                title="Grade AITU"
                open={modal.isModalOpen}
                onCancel={() => modal.handleCancelModal}
                onOk={modal.form.submit}
                okText="Save"
            >
                <Form form={modal.form} onFinish={handleUpdateGrade}>
                    <Form.Item name="aituGrade" rules={[{ required: true }]}>
                        <InputNumber style={{ width: "100%" }} />
                    </Form.Item>
                </Form>
            </Modal>
        </Page>
    )
}

export default CollationStatementDetailPage